.form {
    .form-inputs,
    &.form-inputs {
        display: grid;
        gap: 0.5rem;
    }
    .form-input {
        &.disabled {
            opacity: 0.35;
            cursor: not-allowed;
            .label,
            .input,
            .error {
                pointer-events: none;
            }
        }

        &.text-input,
        &.number-input,
        &.textarea-input,
        &.select-input,
        &.combo-select-input,
        &.button-select-input,
        &.toggle-switch-input,
        &.toggle-checkbox-input {
            display: grid;
            gap: 3px;
            .label {
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: var(--textColor01);
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 3px;

                .tooltip-icon {
                    height: fit-content;
                    font-size: 15px;
                }
            }
            .error {
                color: $colorDanger;
                font-size: 14px;
                display: none;
            }

            &.error {
                .input {
                    border-color: $colorDanger;
                    opacity: 1;
                }
                .error {
                    display: block;
                }
            }
            .icon {
                color: var(--textColor01);
                height: 35px !important;
                font-size: 35px;

                .emoji-mart-emoji {
                    height: 35px !important;
                    display: flex;
                    align-items: center;

                    img {
                        padding: 3px;
                        height: auto !important;
                    }
                }
            }
            .icon-right {
                position: absolute;
                z-index: 10 !important;
                top: 30px;
                right: 7px;

                ~ .input {
                    padding-right: 45px;
                }
            }
            .icon-left {
                position: absolute;
                z-index: 10 !important;
                top: 7px;
                left: 7px;
                ~ .label {
                    top: 30px;
                }
                ~ .input {
                    padding-left: 45px;
                }
            }
        }
        &.text-input,
        &.number-input,
        &.textarea-input {
            .input {
                padding: 10px 13px;
                border-radius: 7px;
                background-color: transparent;
                border: 2px solid var(--textColor01);
                color: var(--textColor01);
                opacity: 0.6;
                outline: none;
                transition: 0.2s;
                z-index: 1 !important;
                &:hover {
                    opacity: 0.8;
                }

                &:focus {
                    opacity: 1;
                    border-color: $colorPrimary;
                }
            }

            &.dark {
                .input {
                    border-color: white;
                    color: white;
                    &:focus {
                        border-color: $colorPrimary;
                    }
                }
                .icon {
                    color: white;
                }
            }
        }
        &.toggle-checkbox-input {
            margin-top: 3px;
        }
        &.number-input {
            &.disabled {
                .arrows {
                    pointer-events: none;
                }
            }
            .input {
                width: 100%;
            }
            .arrows {
                color: var(--textColor01);
                font-size: 35px;
                cursor: pointer;
                display: flex;
                position: absolute;
                z-index: 10 !important;
                top: 30px;
                right: 7px;
                justify-content: center;
                align-items: center;

                ~ .input {
                    padding-right: 80px;
                }

                .left-arrow,
                .right-arrow {
                    opacity: 0.5;
                    transition: 0.5s;
                    &:hover {
                        color: $colorPrimary;
                        opacity: 1;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        &.textarea-input {
            .input {
                min-height: 100px;
                resize: vertical;
                &::-webkit-scrollbar-corner,
                &::-webkit-resizer {
                    height: 20px;
                    width: 20px;
                    border-radius: 5px 0px 2px 0px;
                    background-color: var(--textColor01);
                    transition: 0.2s;
                }
                &::-webkit-scrollbar {
                    width: 10px !important;
                }
                &:focus {
                    &::-webkit-resizer {
                        background-color: $colorPrimary;
                    }
                }
            }
            &.small .input {
                min-height: 48px;
                height: 48px;
            }
            &.error .input {
                &::-webkit-resizer {
                    background-color: $colorDanger;
                }
            }
        }

        &.button-select-input {
            .input {
                display: flex;
                flex-wrap: wrap;

                button {
                    .button-icon,
                    .button-text {
                        padding: 1px 7px;
                    }
                    .right {
                        padding-left: 0 !important;
                    }
                    .left {
                        padding-right: 0 !important;
                    }
                }
            }
        }

        &.color-picker {
            .main {
                width: 100%;
                background-color: var(--backgroundColor01);
                border-radius: 7px;
                display: flex;
                align-items: center;
                gap: 15px;
                justify-content: center;

                .color-box {
                    border-radius: 7px;
                }
                .color-hex {
                    border-radius: 7px;
                    padding: 5px 20px;
                    margin-right: 3px;
                    background-color: var(--backgroundColor02);
                }
            }
        }

        &.premium {
            .input {
                opacity: 1;
                animation: premium-border 2s infinite alternate;

                @keyframes premium-border {
                    0% {
                        color: $colorOrange01;
                        border-color: $colorOrange01;
                    }
                    100% {
                        color: #ffd04f;
                        border-color: #ffd04f;
                    }
                }
            }
        }
    }
}

.text-form {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    height: 40px;

    &.shake {
        .form-input {
            border-color: #ed4245 !important;
        }
    }

    .icon {
        color: var(--textColor01);
        height: 40px;
        font-size: 35px;
    }
    .icon-left {
        ~ .form-input {
            left: 40px;
            width: calc(100% - 40px);
        }

        ~ .form-label {
            left: calc(1rem + 40px);
        }
    }

    .form-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        font-family: inherit;
        font-size: 18px;
        background: none;
        border: 2px solid var(--backgroundColor04);
        padding: 10px;
        outline: none;
        color: var(--textColor01);
        transition: $transition02;

        &.premium {
            border-color: $buttonColorGold !important;
        }

        &.plane {
            color: $colorWhite;
            border: none;
            background-color: $backgroundColor03;
            border-radius: 4px;
            &:focus {
                box-shadow: none;
            }
        }

        &.lock {
            pointer-events: none;
            opacity: 0.7;
        }

        &:hover {
            border-color: $colorPrimary;
        }

        &:focus {
            border-color: rgb(255, 244, 142);
            box-shadow: 0px 0px 3px rgb(255, 241, 115);
        }

        &:focus ~ .form-label,
        &:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
            top: -0.75rem;
            font-size: 14px;
            background-color: var(--backgroundColor03);
            padding: 0 10px;
            opacity: 1;
            line-height: 14px;
        }
    }

    .form-label {
        position: absolute;
        left: 1rem;
        top: 0.5rem;
        color: var(--textColor01);
        cursor: text;
        transition: $transition02;
        opacity: 0.5;
    }

    &.bg-2 {
        .form-label {
            background-color: var(--backgroundColor02) !important;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.textarea-form {
    height: auto;
    .form-input {
        position: initial;
        min-height: 100px;
        padding: 4px 10px;
    }
    &.small .form-input {
        height: 40px;
        min-height: 40px;
    }
}

.select-box-form {
    width: 100%;
}

.select-menu-form {
    position: relative;
    width: 100%;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &.bg-2 {
        .form-label {
            // background-color: #ed4245 !important;
            background-color: var(--backgroundColor02) !important;
        }
    }

    .form-label {
        position: absolute;
        left: 1rem;
        color: var(--textColor01);
        top: -0.75rem;
        font-size: 14px;
        background-color: var(--backgroundColor03);
        padding: 0 10px;
        opacity: 1;
        line-height: 14px;
    }
}

.react-datetime-picker {
    width: 100%;
    transition: $transition02;

    .react-date-picker__calendar--open,
    .react-date-picker__calendar--closed {
        position: absolute !important;
    }

    .react-datetime-picker__calendar-button,
    .react-datetime-picker__clear-button {
        transition: $transition01;
    }
    .react-datetime-picker__calendar-button:hover {
        color: $colorPrimary;
    }
    .react-datetime-picker__clear-button:hover {
        color: var(--textColorRed01);
    }
    .react-datetime-picker__wrapper {
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        min-height: 48px;
        border-radius: 7px;
        font-family: inherit;
        font-size: 18px;
        background: none;
        border: 2px solid var(--backgroundColor04);
        color: var(--textColor01);
        transition: $transition02;
        opacity: 0.6;

        &:hover {
            border-color: var(--textColor01);
            opacity: 0.8;
        }

        .react-datetime-picker__inputGroup {
            margin-left: 10px;
            align-items: center;
            display: flex;

            .react-datetime-picker__inputGroup__input {
                border-radius: 8px;
            }
            .react-datetime-picker__inputGroup__amPm,
            .react-datetime-picker__inputGroup__month {
                width: fit-content;
                -webkit-appearance: none;
                border: 1px solid rgba(170, 170, 170, 0);
                option {
                    background-color: var(--backgroundColor03);
                }
            }
        }
    }

    .react-calendar {
        background-color: var(--backgroundColor02) !important;
        border: 2px solid var(--backgroundColor04);
        border-radius: 8px;
        margin: 0.5rem;
        color: var(--textColor01);
        transition: $transition02;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .react-calendar__navigation {
            margin: 5px;
            .react-calendar__navigation__label,
            .react-calendar__navigation__arrow {
                border-radius: 8px;
                margin: 0 2px;
                padding: 3px;
                font-family: "Archivo Black", sans-serif;
                &:disabled,
                &:focus,
                &:hover {
                    background-color: var(--backgroundColor01) !important;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                }
            }
            .react-calendar__navigation__arrow {
                font-size: 25px;
            }
        }

        .react-calendar__viewContainer {
            margin: 5px;
            .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
                font-family: "Archivo Black", sans-serif;
                text-decoration: none;
            }
            .react-calendar__year-view__months .react-calendar__year-view__months__month,
            .react-calendar__decade-view__years .react-calendar__decade-view__years__year,
            .react-calendar__century-view__decades .react-calendar__century-view__decades__decade,
            .react-calendar__month-view__days .react-calendar__month-view__days__day {
                border-radius: 8px;
                &:focus,
                &:hover {
                    background-color: var(--backgroundColor01) !important;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                }
            }

            .react-calendar__month-view__days__day--neighboringMonth {
                color: var(--backgroundColor05);
            }
            .react-calendar__month-view__days__day--weekend {
                color: var(--textColorRed01);
            }
            .react-calendar__tile--now {
                background-color: $colorSuccess !important;
            }
            .react-calendar__tile--hasActive,
            .react-calendar__tile--active {
                background-color: $buttonColorBlurple !important;
            }
        }
    }
}
