$colorPrimary: #8c82ce;
$colorSecondary: #ec8484;
$colorSuccess: #43b581;
$colorDanger: #f04747;

$colorPurple01: #ceb9ff;
$colorBlue01: #8c92e0;
$colorOrange01: #fa8e31;
$colorYellow01: #ffec81;
$colorYellow02: #fce03a;
$colorBlack: #000000;
$colorWhite: #ffffff;

$backgroundColor01: #171c26;
$backgroundColor02: #141820;
$backgroundColor03: #1a202b;

/*
$backgroundColor01: #111112;
$backgroundColor02: #121218;
$backgroundColor03: #0f0f11;
--backgroundColor01: #15151b;
--backgroundColor02: #111112;
--backgroundColor03: #0f0f11;
*/

$textColor01: #ffffff;

$buttonColorPrimary: #776aca;
$buttonColorSecondary: #4f545c;
$buttonColorSuccess: #3ba55d;
$buttonColorDanger: #f04747;
$buttonColorBlurple: #5865f2;
$buttonColorGold: #df9d23;

$transition01: 0.1s;
$transition02: 0.3s;
$transition03: 0.5s;
$transition04: 0.7s;
$transition05: 0.9ms;

body {
    --discordButtonPrimary: #5865f2;
    --discordButtonSecondary: #4f545c;
    --discordButtonSuccess: #43b581;
    --discordButtonDanger: #f04747;
    --discordButtonPrimaryHover: #4752c4;
    --discordButtonSecondaryHover: #686d73;
    --discordButtonSuccessHover: #318861;
    --discordButtonDangerHover: #a12d2f;
}

body.dark-theme {
    --backgroundColor01: #1d232f;
    --backgroundColor02: #171c26;
    --backgroundColor03: #141820;
    --backgroundColor04: #dadada;
    --backgroundColor05: #364157;
    --backgroundColor06: #1418207c;
    --tableBackgroundColor01: #141820;

    --textColor01: #ffffff;
    --textColorFaded01: #ffffff88;
    --textColor02: #000000;
    --textCodeBackground: #0c0e13;
    --textError01: #ffb3b3;
    --textColorRed01: #ff6060;

    --guildCardIconBackground: transparent;

    --discordBackground: #36393f;
    --discordBackgroundHover: rgb(50, 53, 59);
    --discordEmbedBackground: rgb(47, 49, 54);
    --discordEmbedSidebarBackground: rgb(32, 34, 37);
    --discordEmbedLink: rgb(0, 176, 244);
    --discordEmbedTitle: rgb(255, 255, 255);
    --discordEmbedDescription: rgb(220, 221, 222);
    --discordTextMuted: rgb(163, 164, 162);
    --discordTextCode: rgb(185, 187, 190);
    --discordBlockquoteBar: rgb(79, 84, 92);
    --discordSelectMenuBackground: #202225;
    --discordSelectMenuBorder: #040405;
    --discordSelectMenuHover: #3c3f45;
    --discordMentionText: rgb(222, 224, 252);
    --discordMentionBackground: rgba(88, 101, 242, 0.3);
    --discordMentionTextHover: rgb(255, 255, 255);
    --discordMentionBackgroundHover: rgb(88, 101, 242);
    --linkColor: rgb(152, 140, 255);
}

body.light-theme {
    --backgroundColor01: #dadada;
    --backgroundColor02: #f1f1f1;
    --backgroundColor03: #eeeeee;
    --backgroundColor04: #1d232f;
    --backgroundColor05: #bdbdbd;
    --backgroundColor06: #eeeeee79;
    --tableBackgroundColor01: #e4e4e4;

    --textColor01: #000000;
    --textColorFaded01: #00000081;
    --textColor02: #ffffff;
    --textCodeBackground: #bdbdbd;
    --textError01: #ff7a7a;
    --textColorRed01: #ff4646;

    --guildCardIconBackground: #171c26;

    --discordBackground: rgb(255, 255, 255);
    --discordBackgroundHover: rgb(250, 250, 250);
    --discordEmbedBackground: rgb(242, 243, 245);
    --discordEmbedSidebarBackground: rgb(227, 229, 232);
    --discordEmbedLink: rgb(0, 103, 224);
    --discordEmbedTitle: rgb(6, 6, 7);
    --discordEmbedDescription: rgb(46, 51, 56);
    --discordTextMuted: #5e6772;
    --discordTextCode: rgb(79, 86, 96);
    --discordBlockquoteBar: rgb(199, 204, 209);
    --discordSelectMenuBackground: #e3e5e8;
    --discordSelectMenuBorder: #b9bbbe;
    --discordSelectMenuHover: #dcdfe3;
    --discordMentionText: rgb(88, 101, 242);
    --discordMentionBackground: rgba(88, 101, 242, 0.15);
    --discordMentionTextHover: rgb(255, 255, 255);
    --discordMentionBackgroundHover: rgb(88, 101, 242);
    --linkColor: rgb(98, 81, 253);

    --discordButtonSecondary: #747f8d;
}

:root {
    --toastify-color-light: #f1f1f1 !important;
    --toastify-color-dark: #141820 !important;
}

em-emoji-picker {
    --border-radius: 8px;
    --category-icon-size: 24px;
    --color-border-over: rgba(0, 0, 0, 0.1);
    --color-border: rgba(0, 0, 0, 0.05);
    --font-family: "Archivo Black", sans-serif;
    --font-size: 16px;
    --rgb-accent: 140, 130, 206;
    --rgb-background: 23, 28, 38;
    --rgb-color: 255, 255, 255;
    --rgb-input: 20, 24, 32;
    --shadow: 5px 5px 15px -8px black;

    min-height: 400px;
    max-height: 800px;
    position: absolute;
    right: -35px;
}
