.page-body {
    min-height: 100vh;

    display: grid;
    grid: auto / 1fr;

    overflow-x: hidden !important;
}

.content-area {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: $transition03;
}

.sidebar {
    z-index: 101;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    transition: $transition03;
    background-color: $backgroundColor01;

    ~ .content-area {
        left: 300px;
        top: 60px;
        width: calc(100% - 300px);
    }

    ~ .navbar {
        .logo {
            opacity: 0;
        }
    }

    ~ .save-bar,
    ~ .content-area .greeter-variables {
        width: calc(100% - 300px);
    }

    ~ .footer {
        left: 300px;
        width: calc(100% - 300px);
        border-radius: 0 20px 0 0;
    }

    &.closed {
        width: 88px;

        .right-header {
            left: 88px;
            width: calc(100vw - 88px);
        }

        .logo01 {
            display: none !important;
        }

        .logo02 {
            display: block !important;
        }

        .sidebar-text {
            opacity: 0;
        }

        header {
            .sidebar-toggle {
                transform: translateY(-50%);
            }
        }

        .menu {
            .menu-text {
                pointer-events: none;
            }
            &.closed .menu-links {
                max-height: 100%;
            }
        }

        ~ .content-area {
            left: 88px;
            width: calc(100% - 88px);
        }

        ~ .save-bar,
        ~ .content-area .greeter-variables {
            width: calc(100% - 88px);
        }

        ~ .footer {
            left: 88px;
            width: calc(100% - 88px);
        }
    }

    .sidebar-text {
        font-weight: 500;
        transition: $transition01;
        white-space: nowrap;
    }

    .image-text {
        padding: 5px 0;
        img {
            height: 40px;
        }

        .logo01 {
            display: block;
        }

        .logo02 {
            display: none;
        }
    }

    .right-header {
        position: absolute;
        left: 300px;
        width: calc(100vw - 300px);
        height: 60px;
        background-color: $backgroundColor01;
        transition: $transition03;

        &.dashboard {
            display: flex;
            justify-content: right;
            padding-right: 120px;
        }
    }

    .left-header {
        position: relative;
        padding: 5px 14px;

        .image {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $transition02;
            overflow-x: hidden;
        }

        .sidebar-toggle {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            right: -25px;
            height: 25px;
            width: 25px;
            transform: translateY(-50%) rotate(180deg);
            background-color: darken($colorPrimary, 7%);
            color: $colorWhite;
            border-radius: 25%;
            padding: 5px;
            transition: $transition02;

            &:hover {
                cursor: pointer;
                background-color: $colorPrimary;
                right: -30px;
                height: 35px;
                width: 35px;
            }
        }
    }

    .menu-links {
        overflow: hidden;
        transition: $transition01;
    }

    .menu.closed {
        .menu-text .nav-icon {
            transform: rotate(180deg) !important;
        }

        .menu-links {
            max-height: 0px;
        }
    }

    .menu-bar {
        overflow-y: auto;
        max-height: calc(100vh - 70px);
        padding: 5px 14px;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .menu-text {
        margin-top: 10px;
        margin-bottom: -5px;
        margin-left: 1.5rem;
        color: $colorWhite;
        font-size: 0.9rem;
        font-family: "Bungee", sans-serif;
        justify-content: space-between;
        overflow: hidden;

        &:hover {
            cursor: pointer;
            color: $colorYellow01;

            .nav-icon {
                color: $colorYellow01;
            }
        }
    }

    li {
        height: 40px;
        margin-top: 10px;
        list-style: none;
        display: flex;
        align-items: center;
        overflow-x: hidden;
        position: relative;

        .nav-button {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            text-decoration: none;
            border-radius: 10px;
            transition: $transition02;

            span {
                pointer-events: none;
            }

            &.active {
                background-color: darken($colorPrimary, 10%);
                pointer-events: none;
            }

            &:hover {
                background-color: transparentize(darken($colorPrimary, 10%), 0.5);
                cursor: pointer;
            }
        }

        .nav-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 60px;

            font-size: 20px;
            transition: $transition02;
        }

        .tags {
            position: absolute;
            right: 0;
            display: flex;

            .tag {
                display: none;
                margin-left: 0;
                padding: 3px 5px;
                border-radius: 5px;
            }

            .active {
                background-color: $colorSuccess;
            }

            .updated {
                display: inherit;
                background-color: $colorPrimary;
            }

            .new {
                display: inherit;
                text-align: center;
                font-family: "Bungee", sans-serif;
                background-color: $colorPrimary;
            }
        }

        &.nav-link {
            &.all-active,
            &.active {
                .nav-icon {
                    color: lighten($colorSuccess, 15%);
                }

                .tags {
                    .active {
                        display: block;
                    }
                }
            }
            .nav-text {
                font-size: 0.9rem;
            }
        }

        .nav-text,
        .nav-icon {
            color: $colorWhite;
        }
    }

    @media screen and (max-width: 1020px) {
        width: 60%;

        ~ .save-bar,
        ~ .content-area .greeter-variables {
            width: calc(100% - 88px);
            display: none;
        }

        ~ .content-area {
            left: 88px;
            top: 60px;
            width: calc(100% - 88px);
        }

        ~ .footer {
            left: 88px;
            width: calc(100% - 88px);
        }

        .cover-button {
            position: absolute;
            top: 0;
            left: 60vw;
            width: calc(100vw - 60vw);
            height: 100vh;
        }

        .right-header.dashboard {
            display: none;
        }

        &.closed {
            .cover-button {
                display: none;
            }
            .right-header.dashboard {
                display: flex;
            }
            ~ .save-bar,
            ~ .content-area .greeter-variables {
                display: block;
            }
        }
    }

    @media screen and (max-width: 720px) {
        .right-header.dashboard {
            padding-right: 80px;
        }
    }

    @media screen and (max-width: 620px) {
        width: 100%;

        ~ .save-bar,
        ~ .content-area .greeter-variables {
            width: 100%;
            padding: 1rem 0.5rem;
        }

        ~ .content-area {
            left: 0;
            top: 60px;
            width: 100%;
        }

        ~ .footer {
            left: 0 !important;
            width: 100% !important;
            border-radius: 20px 20px 0 0;
        }

        .sidebar-toggle {
            width: 40px !important;
            height: 40px !important;
            transform: translateX(-250%) translateY(-50%) rotate(180deg) !important;
        }

        .right-header.dashboard {
            justify-content: center;
            padding: 0 60px 0 50px;

            .search {
                display: none;
            }
        }

        &.closed {
            left: -88px;

            ~ .save-bar,
            ~ .content-area .greeter-variables {
                width: 100%;
            }

            ~ .content-area {
                left: 0;
                top: 60px;
                width: 100%;
            }

            .right-header {
                left: 88px;
                width: 100vw;
            }
            .sidebar-toggle {
                transform: translateX(50%) translateY(-50%) !important;
            }
        }
    }
}

.global-navbar {
    position: relative;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 5px 10%;
    background-color: $backgroundColor01;

    ~ .content-area {
        top: 60px;
        height: calc(100% - 60px);
        margin-bottom: 60px;
    }

    li,
    a,
    button {
        font-weight: 500;
        font-size: 15px;
        color: $colorWhite;
        text-decoration: none;
    }

    .logo {
        width: auto;
        height: 40px;
        cursor: pointer;

        &.full {
            display: block;
        }

        &.icon {
            display: none;
        }
    }

    .nav-links {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: $transition02;

        li {
            display: inline-block;
            padding: 5px 20px;
            border-radius: 4px;
            border: 2px solid $backgroundColor01;
            cursor: pointer;
            transition: $transition02;

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }

            &.premium {
                box-shadow: 0 0 10px transparentize($colorYellow01, 0.5);
                border-color: $colorYellow01;
                color: $colorYellow01;
                &:hover {
                    border-color: $colorYellow01;
                    box-shadow: 0 0 10px transparentize($colorYellow01, 0.1);
                }
            }

            &:hover {
                border-color: $colorPurple01;
            }

            &.active {
                pointer-events: none;
                border-color: $colorSuccess;

                &.premium {
                    box-shadow: 0 0 10px transparentize($colorSuccess, 0.5);
                    color: $colorWhite;
                }
            }
        }
    }

    .nav-close {
        backdrop-filter: blur(3px);
        position: absolute;
        top: 100%;
        left: 0;
        height: calc(100vh - 100%);
        width: 40vw;
        display: none;
        transition: $transition02;
    }

    .menu-button {
        position: absolute;
        left: 30px;
        color: $colorWhite;
        font-size: 2.5rem;
        display: none;

        .opened {
            display: none;
        }
    }

    @media screen and (max-width: 1140px) {
        justify-content: left;

        .menu-button {
            display: block;

            &.opened {
                & ~ nav {
                    .nav-links {
                        right: 0;
                    }
                    .nav-close {
                        display: block;
                    }
                }

                .closed {
                    display: none;
                }

                .opened {
                    display: block;
                }
            }
        }

        .logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .nav-links {
            position: absolute;
            background-color: $backgroundColor01;
            top: 100%;
            right: -60vw;
            height: calc(100vh - 100%);
            width: 60vw;
            flex-direction: column;
            padding: 30px;

            li {
                width: 100%;
                border-color: lighten($backgroundColor01, 10%);
            }
        }

        .nav-close {
            display: none;
        }
    }
    @media screen and (max-width: 580px) {
        justify-content: left;

        .logo {
            &.full {
                display: none;
            }

            &.icon {
                display: block;
            }
        }

        .nav-links {
            right: -100vw;
            width: 100vw;
        }

        .nav-close {
            display: none !important;
        }
    }
}

.footer {
    position: relative;
    width: 100%;
    background-color: $backgroundColor01;
    box-shadow: 0 15px 70px -8px transparentize($colorBlack, 0.75);
    border-radius: 20px 20px 0 0;
    transition: $transition03;
    margin-top: auto;

    .footer-container {
        max-width: 1540px;
        padding: 30px 40px;
        margin: auto;
    }

    .content-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .profil {
            .logo-area {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;

                img {
                    width: 40px;
                    height: 40px;
                }

                .logo-name {
                    font-size: 1.35rem;
                    font-family: "Bungee", sans-serif;
                    margin: 0 1rem;
                    color: $colorWhite;
                }

                .theme-toggle {
                    opacity: 0;
                    position: absolute;
                }

                .theme-toggle:checked + .theme-toggle-label .ball {
                    transform: translateX(24px);
                }

                .theme-toggle-label {
                    position: relative;
                    display: flex;
                    width: 50px;
                    height: 26px;
                    background-color: $colorBlack;
                    border-radius: 25px;
                    padding: 5px;
                    align-items: center;
                    justify-content: space-between;

                    .moon {
                        color: $colorYellow01;
                    }

                    .sun {
                        color: $colorOrange01;
                    }

                    .ball {
                        position: absolute;
                        background-color: $colorWhite;
                        width: 22px;
                        height: 22px;
                        border-radius: 25px;
                        top: 2px;
                        left: 2px;
                        transition: $transition02;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .description-area {
                max-width: 320px;
                margin-bottom: 1.25rem;

                p {
                    color: transparentize($colorWhite, 0.5);
                    font-weight: 400;
                    line-height: 2rem;
                }
            }

            .social-media {
                a {
                    color: transparentize($colorWhite, 0.25);
                    margin-right: 22px;
                    font-size: 24px;
                    transition: $transition02;

                    &:hover {
                        color: $colorPurple01;
                    }
                }
            }
        }

        .service-area {
            display: flex;

            .service-header {
                margin-right: 40px;
                list-style: none;

                .service-name {
                    color: $colorWhite;
                    font-family: "Archivo Black", sans-serif;
                }

                li {
                    margin-bottom: 15px;

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                    a {
                        color: transparentize($colorWhite, 0.25);
                        padding-bottom: 1px;
                        text-decoration: none;
                        transition: $transition02;
                        font-weight: 400;

                        &:hover {
                            color: $colorPurple01;
                        }
                    }
                }
            }
        }
    }

    hr {
        height: 1px;
        border: none;
        background-color: transparentize($colorWhite, 0.7);
        margin: 0 2rem;
        margin-bottom: 1rem;
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.9rem;

        .copyright {
            color: transparentize($colorWhite, 0.25);
            display: flex;
            align-items: center;

            span {
                margin-left: 8px;
                letter-spacing: 0.1rem;
            }
        }

        .tou {
            a {
                color: transparentize($colorWhite, 0.25);
                margin-left: 1.5rem;
                text-decoration: none;
                transition: $transition02;
                font-weight: 400;

                &:hover {
                    color: $colorPurple01;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .content-footer {
            flex-wrap: wrap;
            justify-content: left;
        }

        .service-area {
            flex-wrap: wrap;
            justify-content: left;
        }

        .service-header {
            margin-bottom: 10px;
            margin-left: 0;
            margin-right: 40px;
        }

        .social-media {
            margin-bottom: 1rem;
        }

        .footer-bottom {
            flex-wrap: wrap;
            justify-content: center;

            .copyright {
                margin-bottom: 15px;
            }
        }
    }
}

.save-bar {
    z-index: 102;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem 2rem;
    transition: $transition03;

    .bar {
        display: flex;
        transition: $transition03;
        height: 60px;
        width: 100%;
        border-radius: 10px;
        border: 2px solid var(--backgroundColor01);
        background-color: transparentize($backgroundColor02, 0.1);
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;

        .save-bar-text {
            font-size: 1.2rem;
            color: $colorWhite;
            font-weight: 400;
        }

        button {
            position: relative;
            img {
                position: absolute;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                height: 100%;
                display: none;
            }

            &.loading-button {
                pointer-events: none;
                background-color: $buttonColorSecondary !important;

                img {
                    display: block;
                }

                span {
                    opacity: 0;
                }
            }
        }

        @media screen and (max-width: 570px) {
            justify-content: center;
            .save-bar-text {
                display: none;
            }
        }
    }

    &.closed {
        bottom: -100px;

        ~ .content-area .greeter-variables {
            bottom: 0;
            &.closed {
                bottom: -100px;
            }
        }

        button {
            pointer-events: none;
        }
    }
}

.loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: var(--backgroundColor01);
    &.active {
        display: flex;
    }

    &.dim {
        background-color: rgba(0, 0, 0, 0.25);
    }

    img {
        width: 150px;
        height: auto;
        animation: float 6s ease-in-out infinite;

        @keyframes float {
            0% {
                transform: translateY(30px);
            }

            50% {
                transform: translateY(-30px);
            }

            100% {
                transform: translateY(30px);
            }
        }
    }
}

.scroll-section {
    &.full-center {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparentize($colorBlack, 0.85);
    backdrop-filter: blur(10px);
    height: 100vh;
    width: 100vw;
    z-index: 105;

    .close-full {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .close {
        position: fixed;
        display: flex;
        top: 5px;
        right: 13px;
        font-size: 2rem;
        width: auto;
        height: auto;
        background-color: $buttonColorDanger;
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 3px;
        color: $colorWhite;
        transition: $transition02;
        cursor: pointer;
        z-index: 1;

        &:hover {
            transform: scale(115%) rotateZ(90deg);
        }
    }

    .popup-container {
        position: absolute;
        width: 100vw;
        height: 100vh;
        display: grid;
        place-items: center;
        padding: 15px;
        animation: fade_in $transition02 ease-in;
        overflow-y: auto;

        .popup-element {
            position: relative;
            max-width: 800px;
            width: 100%;

            .popup-data {
                position: relative;
                padding: 32px;
                width: 100%;
                border-radius: 10px;
                box-shadow: 5px 10px 25px transparentize($colorBlack, 0.3);
            }
        }
    }
}

.screen-cover {
    z-index: 105;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    animation: fade_out $transition03 ease-in;
    pointer-events: none;
    background-color: var(--backgroundColor01);
}

.pagination {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: flex;
    justify-content: center;
    ul {
        display: flex;
        background-color: var(--backgroundColor01);
        color: var(--textColor01);
        padding: 5px;
        border-radius: 15px;
    }

    li {
        list-style: none;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        transition: all $transition02 ease;
        font-family: "Bungee", sans-serif;

        &.numb {
            height: 33px;
            width: 33px;
            border-radius: 10px;
            margin: 0 3px;
        }

        &.dots {
            font-size: 20px;
            cursor: default;
        }

        &.button {
            padding: 0 20px;
            display: inline-flex;
            .button-text,
            .button-icon {
                display: inline-flex;
                align-items: center;
            }
            margin: 0 3px;
        }

        &.prev {
            border-radius: 10px 5px 5px 10px;
        }

        &.next {
            border-radius: 5px 10px 10px 5px;
        }

        &.active,
        &.button:hover,
        &.numb:hover {
            background-color: $colorPrimary;
        }
    }
}

.tooltip {
    color: $colorWhite;
    background-color: black;
    padding: 8px 12px;
    border-radius: 10px;
    font-size: 0.95em;
    max-width: 450px;
    margin: 0.5rem 2rem;

    code {
        font-size: 1.1em;
        color: $colorPurple01;
        background-color: transparent !important;
    }
}

.tab-bar {
    .slider-input {
        display: none;
    }

    nav {
        position: relative;
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: var(--backgroundColor03);
        border-radius: 10px;

        label {
            display: inline-flex;
            justify-content: center;
            height: 100%;
            width: 100%;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
            color: var(--textColor01);
            font-size: 17px;
            border-radius: 10px;
            transition: $transition02;

            .button-text {
                margin-right: 1.5rem;
                z-index: 1;
            }
            .button-icon {
                display: inline-flex;
                padding: 12px 10px;
                font-size: 1.5rem;
                z-index: 1;
            }

            &:hover {
                background-color: darken($colorPrimary, 5%);
                color: $colorWhite;
            }
        }

        .slider {
            z-index: 0;
            position: absolute;
            height: 100%;
            width: 11.1111111%;
            background-color: $colorPrimary;
            border-radius: 10px;
            bottom: 0;
            left: 0;
            transition: $transition02;
        }

        @media screen and (max-width: 1325px) {
            label {
                .button-text {
                    margin-right: 0;
                }
                .button-icon {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 1025px) {
            label {
                .button-text {
                    display: none;
                }
                .button-icon {
                    display: inline-flex;
                }
            }
        }
    }

    #command-actions:checked ~ nav label.command-actions {
        color: $colorWhite;
    }
    #command-fun:checked ~ nav label.command-fun {
        color: $colorWhite;
    }
    #command-music:checked ~ nav label.command-music {
        color: $colorWhite;
    }
    #command-moderation:checked ~ nav label.command-moderation {
        color: $colorWhite;
    }
    #command-profile:checked ~ nav label.command-profile {
        color: $colorWhite;
    }
    #command-economy:checked ~ nav label.command-economy {
        color: $colorWhite;
    }
    #command-utility:checked ~ nav label.command-utility {
        color: $colorWhite;
    }
    #command-config:checked ~ nav label.command-config {
        color: $colorWhite;
    }
    #command-bot:checked ~ nav label.command-bot {
        color: $colorWhite;
    }
    #command-actions:checked ~ nav .slider {
        left: 0%;
    }
    #command-fun:checked ~ nav .slider {
        left: 11.1111111%;
    }
    #command-music:checked ~ nav .slider {
        left: calc(11.1111111% * 2);
    }
    #command-moderation:checked ~ nav .slider {
        left: calc(11.1111111% * 3);
    }
    #command-profile:checked ~ nav .slider {
        left: calc(11.1111111% * 4);
    }
    #command-economy:checked ~ nav .slider {
        left: calc(11.1111111% * 5);
    }
    #command-utility:checked ~ nav .slider {
        left: calc(11.1111111% * 6);
    }
    #command-config:checked ~ nav .slider {
        left: calc(11.1111111% * 7);
    }
    #command-bot:checked ~ nav .slider {
        left: calc(11.1111111% * 8);
    }
}

.command-counter {
    font-family: "Bungee", sans-serif;
    font-size: 2rem;
    line-height: 55px;
    width: 120px;
    margin: 0 auto;
    background-color: var(--backgroundColor03);
    border-radius: 10px;
    text-align: center;
    color: $colorPrimary;
}

.tag {
    margin: 5px;
    display: inline-flex;
    align-items: center;
    padding: 10px 10px;
    border-radius: 15px;
    background-color: var(--backgroundColor01);
    cursor: default;

    .name {
        margin-bottom: 2px;
        margin-left: 5px;
        font-size: 1.15rem;
    }
    .icon {
        display: inline-flex;
        font-size: 1.6rem;
        .yes {
            border-radius: 10px;
            display: none;
            background-color: $colorSuccess;
            color: $colorWhite;
        }
        .no {
            border-radius: 10px;
            background-color: $colorDanger;
            color: $colorWhite;
        }
    }

    &.active {
        .icon {
            .yes {
                display: block;
            }
            .no {
                display: none;
            }
        }
    }
}

.dashboard-multi-add-list {
    .list-bar {
        display: flex;
        justify-content: right;
        padding: 1rem;
        border-radius: 8px;
        background-color: var(--backgroundColor02);

        &.between {
            justify-content: space-between;
        }
    }

    .list {
        display: grid;
        gap: 10px;
        margin-top: 30px;
        .card {
            display: flex;
            flex-direction: column;
            gap: 7px;

            span {
                text-overflow: ellipsis;
            }

            .main {
                padding: 0.8rem 1rem;
                border-radius: 8px;
                background-color: var(--backgroundColor02);

                @media screen and (max-width: 720px) {
                    flex-direction: column;
                    align-items: baseline;
                }
            }

            // .content {
            //     max-height: 0;
            //     padding: 0 1rem;
            //     border-radius: 15px;
            //     background-color: var(--backgroundColor02);

            //     overflow-y: hidden;
            //     visibility: hidden;
            //     transition: $transition03;
            // }

            // &.active > .content {
            //     overflow-y: auto;
            //     max-height: 1500px;
            //     padding: 0.8rem 1rem;
            //     visibility: visible;
            //     margin-bottom: 5px;
            // }
        }
    }
}

.profile-menu {
    z-index: 101;
    position: fixed;
    top: 25px;
    right: 30px;

    .action {
        display: block;
        width: 58px;
        height: 58px;
        background-color: $backgroundColor01;
        border: 3px solid $backgroundColor01;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: $transition02;

        img {
            width: 100%;
            height: 100%;
        }

        &:hover {
            border-radius: 10px;
        }
    }

    .menu {
        width: 310px;
        padding: 20px;
        background-color: $backgroundColor01;
        border-radius: 10px;
        position: absolute;
        top: 75px;
        right: 0;
        transition: $transition02;
        transform: translateY(15px);
        opacity: 0;
        visibility: hidden;

        &::before {
            content: "";
            width: 16px;
            height: 16px;
            background-color: $backgroundColor01;
            border-top-left-radius: 3px;
            position: absolute;
            top: -8px;
            right: 19px;
            transform: rotate(45deg);
        }

        .profile {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            img {
                width: 56px;
                height: 56px;
                border-radius: 10px;
                user-select: none;
            }

            .info {
                margin-left: 15px;
                overflow: hidden;

                h1 {
                    color: $colorWhite;
                    font-size: 18px;
                    font-weight: 400;
                    font-family: "Archivo Black", sans-serif;
                }

                p {
                    color: transparentize($colorBlue01, 0.7);
                    font-size: 14px;
                    font-weight: 400;
                    font-family: "Bungee", sans-serif;
                    font-weight: 100;
                }
            }
        }

        .divider {
            background-color: transparentize($colorBlue01, 0.7);
        }

        ul {
            margin-top: 30px;
            li {
                list-style: none;
                padding: 2.5px 0;

                button {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    color: $colorWhite;
                    gap: 10px;
                    padding: 10px;
                    font-size: 15px;
                    border-radius: 8px;
                    transition: $transition02;

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    &.active {
                        color: rgb(159, 142, 255);
                        pointer-events: none;
                    }

                    .icon {
                        font-size: 1.3rem;
                    }

                    &:hover {
                        background-color: transparentize(darken($colorPrimary, 10%), 0.5);

                        &.logout {
                            background-color: transparentize($buttonColorDanger, 0.9);
                        }
                    }
                }
            }
        }
    }

    &.active > .menu {
        transform: translateY(0px);
        opacity: 1;
        visibility: visible;
    }

    @media screen and (max-width: 720px) {
        top: 0px;
        right: 0px;
    }
}

.bg-object-container {
    .bg-object {
        position: absolute;
        box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.4);
    }
}

.card-input {
    padding: 10px;

    .card-number,
    .card-expiry,
    .card-cvc {
        align-items: center;
        padding: 10px;
        background-color: $backgroundColor03;
        border-radius: 4px;

        .icon {
            color: #fff;
            font-size: 1.4rem;
        }
    }
}

.base-table {
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
    background-color: var(--backgroundColor02);
    color: var(--textColor01);
    width: 100%;

    border-radius: 8px;

    thead {
        box-shadow: 0 0 20px transparentize($colorBlack, 0.7);
        th {
            padding: 1rem 2rem;
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            font-size: 1rem;
            font-family: "Bungee", sans-serif;
        }
    }

    tbody {
        td {
            padding: 1rem 2rem;
        }

        tr:nth-child(even) {
            background-color: var(--tableBackgroundColor01);
        }
    }

    .status {
        border-radius: 4px;
        padding: 0.3rem 1rem;
        text-align: center;
        color: $colorWhite;

        &-success {
            background-color: transparentize($colorSuccess, 0.2);
        }
        &-danger {
            background-color: transparentize($colorDanger, 0.2);
        }
        &-pending {
            background-color: transparentize($colorOrange01, 0.2);
        }
    }

    &.premium-table {
        max-width: calc(100vw - 2rem);
        tr {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 2fr 1fr 1fr;
        }
        thead tr {
            :nth-child(2) {
                .icon {
                    display: none;
                }
            }
            :nth-child(3) {
                .icon {
                    display: none;
                }
            }

            @media screen and (max-width: 580px) {
                :nth-child(2) {
                    .icon {
                        display: inline;
                    }
                    .value {
                        display: none;
                    }
                }
                :nth-child(3) {
                    .icon {
                        display: inline;
                    }
                    .value {
                        display: none;
                    }
                }
            }
        }
        th,
        td {
            @media screen and (max-width: 580px) {
                padding: 1rem 10px;
            }
        }
    }

    .premium-status {
        font-size: 1.5rem;

        .icon {
            width: 100%;
        }

        &-danger {
            color: $colorDanger;
        }
        &-success {
            color: $colorSuccess;
        }
    }
}

.incrementor {
    display: flex;
    align-items: center;
    color: var(--textColor01);

    &.md {
        button {
            width: 40px;
            height: 40px;
        }
        input {
            width: 60px;
            height: 40px;
        }
    }

    input {
        width: 40px;
        height: 30px;
        outline: none;
        background-color: var(--backgroundColor05);
    }

    button {
        display: grid;
        place-items: center;
        width: 30px;
        height: 30px;
        background-color: var(--backgroundColor02);
        font-size: 1.2rem;
        cursor: pointer;

        &.left {
            border-radius: 8px 0 0 8px;
        }
        &.right {
            border-radius: 0 8px 8px 0;
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .icon {
            pointer-events: none;
        }
    }

    &.bg-1 {
        button {
            background-color: var(--backgroundColor01);
        }
    }
    &.bg-2 {
        button {
            background-color: var(--backgroundColor02);
        }
    }
    &.bg-3 {
        button {
            background-color: var(--backgroundColor03);
        }
    }
}

.time-picker {
    display: inline-flex;
    gap: 5px;
    .box {
        width: 30px;
        background-color: var(--backgroundColor05);
        border-radius: 7px;
        .top,
        .bottom {
            display: flex;
            justify-content: center;
            color: var(--textColor01);
            font-size: 20px;
            background-color: var(--backgroundColor05);
            border-radius: 7px;
            transition: $transition02;
            cursor: pointer;
        }
        .top:hover {
            transform: translateY(-3px);
        }
        .bottom:hover {
            transform: translateY(3px);
        }
        .middle {
            display: flex;
            justify-content: center;
            color: var(--textColor01);
            .input {
                width: 30px;
                height: 25px;
                border-radius: 4px;
                text-align: center;
                outline: none;
                border: none;
                background-color: rgba(0, 0, 0, 0.2);
                &::placeholder {
                    color: var(--textColor01);
                    opacity: 0.5;
                }
            }
        }
    }
}

.profile-premium-card {
    .card {
        .title {
            @media screen and (max-width: 900px) {
                flex-direction: column;
                gap: 10px;
                .text {
                    text-align: center !important;
                }
            }
        }
    }
    .details {
        background-color: var(--backgroundColor02);
    }
}

.server-leaderboard {
    .server-icon {
        width: 180px;
        height: 180px;
        border-radius: 10px;
    }

    .server-name {
        font-size: 45px;
    }

    .leaderboard-list {
        display: grid;
        grid-auto-flow: row;
        gap: 0.5rem;
        width: 100%;
        max-width: 1200px;
        padding: 1rem;
        border-radius: 8px;

        .leaderboard-list-item {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0.5rem;
            border-radius: 8px;

            .right-info,
            .left-info {
                display: grid;
                grid-auto-flow: column;
                gap: 0.5rem;
                align-items: center;
            }

            .leaderboard-position,
            .leaderboard-level,
            .leaderboard-avatar {
                display: grid;
                place-items: center;
                width: 50px !important;
                height: 50px !important;
                border-radius: 8px;
            }

            .leaderboard-name,
            .leaderboard-xp {
                font-size: 20px;

                &.manager {
                    font-size: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 760px) {
        .server-name {
            font-size: 35px;
        }
        .leaderboard-list {
            width: auto;
        }
        .leaderboard-name {
            display: none;
        }

        &.manager {
            .leaderboard-name {
                display: block;
            }
        }
    }
}

//#region Keyframes
@keyframes fade_out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade_in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
//#endregion

.embed-preview {
    background: #36393e;
    display: flex;
    box-orient: vertical;
    flex-direction: column;
}

.embed-wrapper {
    display: flex;
    max-width: 520px;
}

.embed-side-colored {
    width: 4px;
    border-radius: 3px 0 0 3px;
}

.embed {
    border-radius: 0 3px 3px 0;
    background: rgba(46, 48, 54, 0.3);
    border-color: rgba(46, 48, 54, 0.6);
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;

    .card-block {
        padding: 0;
        display: flex;
        margin-bottom: 10px;
    }

    a {
        color: #0096cf;
    }

    img.embed-thumb {
        max-height: 80px;
        max-width: 80px;
        border-radius: 3px;
        flex-shrink: 0;
        width: auto;
        object-fit: contain;
        margin-left: 20px;
    }

    .embed-footer {
        font-size: 12px;
        span {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .embed-inner {
        .embed-title {
            color: #fff;
        }

        .embed-author {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }

        img.embed-author-icon {
            margin-right: 9px;
            width: 20px;
            height: 20px;
            object-fit: contain;
            border-radius: 50%;
        }

        .embed-author-name {
            display: inline-block;
            font-weight: 600;
            font-size: 14px;
            color: #fff !important;
        }
    }

    .fields {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        box-lines: miltiple;
        margin-top: -10px;

        .field {
            flex: 0;
            box-flex: 1;
            padding-top: 10px;
            max-width: 506px;
            min-width: 100%;
        }

        &.inline {
            box-flex: 1;
            flex: 1;
            min-width: 150px;
            flex-basis: auto;
        }

        .field-name {
            color: #fff;
            font-size: 14px;
            margin-bottom: 4px;
            font-weight: 600;
        }

        .field-value {
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
            font-weight: 500;
            line-height: 1.1em;
            white-space: pre-wrap;
            margin-top: 6px;
            word-wrap: break-word;
        }
    }
}

.discord-message {
    padding: 0.125rem 16px 0.15rem 4.5rem;
    min-height: 3rem;
    font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-radius: 4px;

    &:hover {
        background-color: var(--discordBackgroundHover);
    }

    .header-wrapper {
        position: relative;
        margin-left: -4.5rem;
        padding-left: 4.5rem;

        img.avatar {
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            height: 2.5rem;
            width: 2.5rem;
            position: absolute;
            left: 0px;
            top: 0.125rem;
            margin: 0px 1rem;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;

            &:hover {
                box-shadow: rgb(0 0 0 / 16%) 0px 4px 4px;
            }
        }

        .username {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.375rem;
            color: var(--discordEmbedTitle);
            display: inline;
            vertical-align: baseline;
            position: relative;
            overflow: hidden;
            flex-shrink: 0;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .bot-tag {
            height: 1rem;
            padding: 0 0.275rem;
            margin-top: 0.075em;
            border-radius: 0.1875rem;
            margin-left: 0.25rem;
            position: relative;
            top: 0.22rem;
            background: rgb(88, 101, 242);
            color: white;
            font-size: 0.625rem;
            text-transform: uppercase;
            vertical-align: top;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            flex-shrink: 0;
            text-indent: 0;
            outline: 0;

            .bot-tag-verified {
                width: 0.9375rem;
                height: 0.9375rem;
                margin-left: -0.25rem;
                display: inline-block;
                overflow: hidden;
            }

            .bot-text {
                line-height: 0.9375rem;
                position: relative;
                font-weight: 500;
                vertical-align: top;
            }
        }

        .time {
            color: rgb(129, 135, 145);
            font-size: 0.75rem;
            line-height: 1.375rem;
            vertical-align: baseline;
            margin-left: 0.25rem;
            display: inline-block;
            height: 1.25rem;
            cursor: default;
            pointer-events: none;
            font-weight: 500;
        }
    }

    .message-content {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        line-height: 1.375;
        color: var(--discordEmbedDescription);
    }

    .components-wrapper {
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        display: flex;
        align-self: start;
        justify-self: start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;

        .components {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .img-attachment {
        display: block;
        max-width: 400px;
        max-height: 300px;
        border-radius: 3px;
        cursor: pointer;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
    }

    .lock-link {
        cursor: pointer;
        color: var(--discordEmbedLink);
        &:hover {
            text-decoration: underline;
        }
    }
}

.discord-embed {
    display: grid;
    unicode-bidi: plaintext;
    text-align: left;
    background: var(--discordEmbedBackground);
    border-radius: 4px;
    border-left: 4px solid var(--discordEmbedSidebarBackground);
    font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: fit-content;
    margin-bottom: 0.25rem;

    a:hover {
        text-decoration: underline;
    }

    .embed-wrapper {
        padding: 0.5rem 1rem 1rem 0.75rem;
        display: inline-grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
    }

    .discord-markdown {
        &.inline-code,
        &.code-block {
            background: var(--discordEmbedSidebarBackground);
        }
    }

    .author {
        min-width: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        grid-column: 1 / 2;
        margin: 8px 0px 0px;

        img.icon {
            height: 24px;
            width: 24px;
            margin: 0px 8px 0px 0px;
            object-fit: contain;
            border-radius: 50%;
        }

        .name {
            font-size: 0.875rem;
            font-weight: 500;
            color: var(--discordEmbedTitle);
            white-space: pre-wrap;
            display: inline-block;
        }
    }

    a.title-wrapper {
        color: var(--discordEmbedLink);
    }
    .title-wrapper {
        min-width: 0px;
        display: inline-block;
        margin: 8px 0px 0px;
        grid-column: 1 / 2;
        color: var(--discordEmbedTitle);

        .title {
            white-space: pre-wrap;
            overflow-wrap: break-word;
            line-height: 1.375;
            font-size: 1rem;
            font-weight: 600 !important;
        }
    }

    .description-wrapper {
        min-width: 0px;
        margin: 8px 0px 0px;
        grid-column: 1 / 2;

        .description {
            overflow-wrap: break-word;
            font-size: 0.875rem;
            color: var(--discordEmbedDescription);
            line-height: 1.125rem;
            white-space: pre-line;
        }
    }

    .fields {
        min-width: 0px;
        margin: 8px 0px 0px;
        display: grid;
        grid-column: 1 / 2;
        gap: 8px;

        .field {
            min-width: 0px;
            font-size: 0.875rem;
            line-height: 1.125rem;
            grid-column: 1 / 13;

            &.inline-01 {
                grid-column: 1 / 7;
            }
            &.inline-02 {
                grid-column: 1 / 5;
            }

            .name-wrapper {
                min-width: 0px;
                margin: 0px 0px 1px;
                font-size: 0.875rem;
                font-weight: 600;
                color: var(--discordEmbedTitle);

                .name {
                    white-space: pre-wrap;
                    overflow-wrap: break-word;
                    line-height: 1.375;
                }
            }

            .value-wrapper {
                min-width: 0px;

                .value {
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    color: var(--discordEmbedDescription);
                    white-space: pre-line;
                }
            }
        }
    }

    img.image {
        min-width: 0px;
        max-width: 400px;
        max-height: 300px;
        margin: 16px 0px 0px;
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
    }

    .footer-wrapper {
        min-width: 0px;
        margin: 8px 0px 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        grid-area: auto / 1 / auto / 3;

        img.icon {
            height: 20px;
            width: 20px;
            margin: 0px 8px 0px 0px;
            object-fit: contain;
            border-radius: 50%;
        }

        .text {
            font-size: 0.75rem;
            font-weight: 500;
            color: var(--discordEmbedDescription);
            line-height: 1rem;
            white-space: break-spaces;

            .divider {
                display: inline-block;
                margin: 0px 4px;
            }
        }
    }

    .thumbnail-wrapper {
        margin: 8px 0px 0px 16px;
        grid-area: 1 / 2 / 8 / 3;
        justify-self: end;
        cursor: pointer;

        img.thumbnail {
            max-width: 80px;
            max-height: 80px;
            border-radius: 4px;
        }
    }
}

.discord-button-component {
    font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    background: none;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding: 2px 16px;
    cursor: pointer;
    margin: 4px 8px 4px 0;
    width: fit-content;
    height: 32px;
    min-width: 60px;
    min-height: 32px;
    color: white;
    transition: $transition02;

    &.for-color {
        min-width: 32px !important;
        width: 32px !important;
    }

    &.primary {
        background-color: var(--discordButtonPrimary);
        &:hover {
            background-color: var(--discordButtonPrimaryHover);
        }
    }
    &.link,
    &.secondary {
        background-color: var(--discordButtonSecondary);
        &:hover {
            background-color: var(--discordButtonSecondaryHover);
        }
    }
    &.success {
        background-color: var(--discordButtonSuccess);
        &:hover {
            background-color: var(--discordButtonSuccessHover);
        }
    }
    &.danger {
        background-color: var(--discordButtonDanger);
        &:hover {
            background-color: var(--discordButtonDangerHover);
        }
    }

    .contents {
        margin: 0 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin: auto;
        min-width: 32px;

        .emoji {
            img {
                text-indent: -9999px;
                flex-shrink: 0;
                margin-right: 4px;
                margin-bottom: 1.5px;
                object-fit: contain;
                width: 1.375em !important;
                height: 1.375em !important;
                vertical-align: bottom;
            }
        }
        .label {
            overflow: hidden;
            text-overflow: ellipsis;
            flex-shrink: 1;
        }
        .link-icon {
            flex-shrink: 0;
            width: 16;
            height: 16;
            overflow: hidden;
            margin-left: 8px;
        }
    }
}

.discord-select-menu-component {
    font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    &::-webkit-scrollbar {
        width: 2px !important;
    }
    .component-option {
        .option {
            display: flex !important;
            align-items: center !important;
            overflow: hidden !important;
            .label-container {
                line-height: 18px !important;
                overflow: hidden !important;
                .label {
                    display: block !important;
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    margin-bottom: 4px !important;
                }
            }
        }
    }
}

.dnd-trash {
    position: absolute;
    right: -25%;
    top: 50px;
    z-index: 100;
    display: grid;
    place-items: center;
    background-color: $colorDanger;
    padding: 0.5rem 2rem;
    border-radius: 8px 0 0 8px;
    transition: $transition01;

    &.active {
        right: 0;
    }
}

.discord-markdown {
    &.mention {
        cursor: pointer;
        color: var(--discordMentionText);
        font-weight: 500;
        border-radius: 3px;
        padding: 0px 2px;
        background: var(--discordMentionBackground);
        transition: background-color 50ms ease-out 0s, color;
        &:hover {
            background: var(--discordMentionBackgroundHover);
            color: var(--discordMentionTextHover);
        }
    }

    &.inline-code {
        font-family: "Courier New", monospace;
        padding: 0.2em;
        margin: -0.2em 0px;
        border-radius: 3px;
        background: var(--discordEmbedBackground);
        font-size: 0.85em;
        line-height: 1.125rem;
        white-space: pre-wrap;
    }
    &.code-block {
        font-family: "Courier New", monospace;
        max-width: 90%;
        margin: 6px 0px 0px;
        padding: 0.5em;
        background: var(--discordEmbedBackground);
        border: 1px solid var(--discordEmbedSidebarBackground);
        border-radius: 4px;
        color: var(--discordTextCode);
        font-size: 0.875rem;
        line-height: 1.125rem;
        white-space: pre-wrap;
    }
    &.blockquote-bar {
        min-width: 4px;
        max-width: 4px;
        border-radius: 4px;
        background: var(--discordBlockquoteBar);
    }
    &.blockquote {
        max-width: 90%;
        padding: 0px 8px 0px 12px;
        margin: 0px;
        text-indent: 0px;
    }
    &.hypertext {
        color: var(--discordEmbedLink);
        text-decoration: none;
        font-weight: 400;
        &:hover {
            text-decoration: underline;
        }
    }
}

blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}

.cyclicquestions-question {
    position: relative;
    &:hover {
        .buttons {
            display: flex;
        }
    }
    .buttons {
        display: none;
        position: absolute;
        top: -10px;
        right: 10px;
        gap: 10px;
        .delete,
        .edit {
            color: white;
            padding: 2px;
            font-size: 22px;
            border-radius: 4px;
            cursor: pointer;
        }
        .edit {
            background-color: $buttonColorBlurple;
            &:hover {
                background-color: darken($buttonColorBlurple, 5%);
            }
        }
        .delete {
            background-color: $buttonColorDanger;
            &:hover {
                background-color: darken($buttonColorDanger, 15%);
            }
        }
    }
}
