&.lock-scroll {
    height: 100%;
    overflow: hidden;
}

.container-default {
    padding: 1rem 1.5rem;
    @media screen and (max-width: 620px) {
        padding: 1rem 5px;
    }
}

.dashboard-section {
    .container {
        padding: 1rem !important;
        max-width: 1720px !important;
        width: 100% !important;
    }

    @media screen and (max-width: 620px) {
        .container {
            padding: 1rem 5px !important;
        }
    }
}

.module-disabled {
    display: flex;
    flex-direction: column;
    height: 250px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background-color: var(--backgroundColor02);

    .icon {
        font-size: 5rem;
    }
}

.module-title {
    display: flex;
    align-items: center;
}

.flex-space {
    display: flex;
    justify-content: space-between;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ma {
    &.t1 {
        margin-top: 1rem !important;
    }
    &.t2 {
        margin-top: 2rem !important;
    }
    &.t3 {
        margin-top: 2rem !important;
    }
    &.t4 {
        margin-top: 4rem !important;
    }
    &.t5 {
        margin-top: 5rem !important;
    }
}

.sep {
    height: 5px;
    background-color: $colorPrimary;
    border-radius: 20px;

    &.r1 {
        width: 1rem;
    }
    &.r3 {
        width: 3rem;
    }
    &.r6 {
        width: 6rem;
    }
    &.r12 {
        width: 12rem;
    }
    &.r24 {
        width: 24rem;
    }
    &.r48 {
        width: 48rem;
    }
    &.r96 {
        width: 96rem;
    }

    &.p10 {
        width: 10%;
    }
    &.p25 {
        width: 25%;
    }
    &.p50 {
        width: 50%;
    }
    &.p75 {
        width: 75%;
    }
    &.p100 {
        width: 100%;
    }
}

.shake {
    animation: shake $transition01 ease-in-out;
}

@keyframes shake {
    0% {
        transform: translateX(0.2rem) translateY(-0.2rem);
    }
    25% {
        transform: translateX(0) translateY(0.2rem);
    }
    50% {
        transform: translateX(0.2rem) translateY(0);
    }
    70% {
        transform: translateX(0) translateY(-0.2rem);
    }
    100% {
        transform: translateX(-0.2rem) translateY(0.2rem);
    }
}

.float-0-1 {
    animation: float-0 10s ease-in-out infinite;
}
.float-0-2 {
    animation: float-0 20s ease-in-out infinite;
}
.float-0-3 {
    animation: float-0 30s ease-in-out infinite;
}
.float-1-1 {
    animation: float-1 10s ease-in-out infinite;
}
.float-1-2 {
    animation: float-1 20s ease-in-out infinite;
}
.float-1-3 {
    animation: float-1 30s ease-in-out infinite;
}
.float-2-1 {
    animation: float-2 10s ease-in-out infinite;
}
.float-2-2 {
    animation: float-2 20s ease-in-out infinite;
}
.float-2-3 {
    animation: float-2 30s ease-in-out infinite;
}

.full-divider {
    height: 200px;
    background-color: $backgroundColor02;
    box-shadow: 0 0 30px transparentize($colorBlack, 0.6);
    z-index: 10;
}

.premium-tables {
    display: flex;
    padding: 0 2rem;
    @media screen and (max-width: 1650px) {
        flex-direction: column;
    }

    @media screen and (max-width: 580px) {
        padding: 0;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@keyframes float-0 {
    0% {
        transform: translateY(-5rem);
    }
    50% {
        transform: translateY(5rem);
    }
    100% {
        transform: translateY(-5rem);
    }
}

@keyframes float-1 {
    0% {
        transform: translateY(5rem);
    }
    50% {
        transform: translateY(-5rem);
    }
    100% {
        transform: translateY(5rem);
    }
}

@keyframes float-2 {
    0% {
        transform: translateY(-0.5rem);
    }
    50% {
        transform: translateY(0.5rem);
    }
    100% {
        transform: translateY(-0.5rem);
    }
}

.background-01 {
    background-color: var(--backgroundColor01) !important;
}
.background-02 {
    background-color: var(--backgroundColor02) !important;
}
.background-03 {
    background-color: var(--backgroundColor03) !important;
}
.background-04 {
    background-color: var(--backgroundColor04) !important;
}
.background-05 {
    background-color: var(--backgroundColor05) !important;
}
.background-06 {
    background-color: var(--backgroundColor06) !important;
}

.background-dark-01 {
    background-color: $backgroundColor01 !important;
}
.background-dark-02 {
    background-color: $backgroundColor02 !important;
}
.background-dark-03 {
    background-color: $backgroundColor03 !important;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.emoji-select {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 40px !important;
    width: 35px !important;
    .emoji-mart-emoji {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        height: 40px !important;
        width: 35px !important;

        img {
            display: block !important;
            top: 2.5px !important;
            left: 0 !important;
            height: 35px !important;
            width: 35px !important;
        }
    }
    &.bg-1 {
        background-color: var(--backgroundColor01) !important;
    }
    &.bg-2 {
        background-color: var(--backgroundColor02) !important;
    }
    &.bg-3 {
        background-color: var(--backgroundColor03) !important;
    }
}

.selfrole-emoji-picker-selected-role {
    position: relative !important;
    margin-right: 44px !important;
    .emoji-mart-emoji {
        position: absolute !important;
        top: -11px !important;
        left: 0 !important;
        width: 44px !important;
        img {
            min-height: 44px !important;
            min-width: 44px !important;
            border-radius: 4px !important;
        }
    }
}

.list-item {
    list-style-type: "— ";
}

.premium-bg {
    background-image: linear-gradient(45deg, #ffd04f, #ec458d, #474ed7);
    background-size: 300%;
    background-position: left;
    animation: premium-gradient 10s infinite alternate;
}

.premium-text {
    background-image: linear-gradient(45deg, #ffd04f, #ec458d);
    background-size: 300%;
    background-position: left;
    background-clip: text;
    color: transparent !important;
    animation: premium-gradient 5s infinite alternate;
}

.primary-text {
    color: $colorPrimary !important;
}
.pink-text {
    color: #ec458d !important;
}

@keyframes premium-gradient {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

.hide-mobile-01 {
    @media screen and (max-width: 760px) {
        display: none !important;
    }
}

.z-index-01 {
    z-index: 1 !important;
}
.z-index-02 {
    z-index: 2 !important;
}

.discord-bg {
    background-color: var(--discordBackground);
}

.btn-danger {
    background: $buttonColorDanger;
    cursor: pointer;
    transition: $transition01;
    &:hover {
        background: darken($buttonColorDanger, 15%);
    }
}

.flex-small-screen {
    @media screen and (max-width: 960px) {
        flex-direction: column;
    }
}

.box-shadow-01 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.box-shadow-02 {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.box-shadow-03 {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
