.grid-card-menu {
    display: grid;
    grid-template-columns: 350px;
    gap: 25px;
    row-gap: 80px;
    justify-content: center;
    @media (min-width: 50em) {
        grid-template-columns: repeat(auto-fit, 350px);
    }
    @media screen and (max-width: 920px) {
        row-gap: 160px !important;
        .card-menu {
            height: 350px !important;
            .image-box {
                width: 195px !important;
                height: 195px !important;
                border-radius: 20px !important;
            }

            .content .details {
                transform: translateY(25px) !important;
            }
        }
    }
    .card-menu-size {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }
    .card-menu {
        position: relative;
        width: 350px;
        height: 160px;
        border-radius: 20px;
        box-shadow: 5px 5px 20px transparentize($colorBlack, 0.7);
        transition: $transition03;
        background-color: var(--backgroundColor02);

        .image-box {
            position: absolute;
            left: 50%;
            top: -50px;
            transform: translateX(-50%);
            width: 150px;
            height: 150px;
            border-radius: 25%;
            box-shadow: 0px 8px 30px transparentize($colorBlack, 0.6);
            overflow: hidden;
            transition: $transition03;
            background-color: var(--guildCardIconBackground);
            pointer-events: none;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            overflow: hidden;

            .details {
                padding: 40px;
                text-align: center;
                width: 100%;
                transition: $transition03;
                transform: translateY(155px);
                color: var(--textColor01);

                .data {
                    display: flex;
                    justify-content: space-between;
                    margin: 20px 0;

                    h1 {
                        font-size: 1.2rem;
                        line-height: 1.2rem;
                        font-weight: 600;

                        span {
                            font-size: 0.9rem;
                            font-weight: 400;
                            opacity: 0.9;
                        }
                    }
                }
            }
        }

        &:hover {
            height: 350px;
            box-shadow: 10px 15px 15px transparentize($colorBlack, 0.7);

            .image-box {
                width: 195px;
                height: 195px;
                border-radius: 20px;
            }

            .content .details {
                transform: translateY(25px);
            }
        }
    }
    .card-menu-add-bot {
        position: relative;
        width: 350px;
        height: 205px;
        border-radius: 20px;
        box-shadow: 5px 5px 20px transparentize($colorBlack, 0.7);
        background-color: var(--backgroundColor02);
        transition: $transition03;

        .image-box {
            position: absolute;
            left: 50%;
            top: -50px;
            transform: translateX(-50%);
            width: 150px;
            height: 150px;
            border-radius: 25%;
            box-shadow: 0px 8px 30px transparentize($colorBlack, 0.6);
            overflow: hidden;
            background-color: var(--guildCardIconBackground);
            transition: $transition03;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: grayscale(100%);
                transition: $transition03;
            }
        }

        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            overflow: hidden;

            .details {
                padding: 40px;
                text-align: center;
                width: 100%;
                transition: $transition03;
                transform: translateY(25px);
                color: var(--textColor01);
            }
        }

        &:hover {
            transform: translateY(-10px);
            box-shadow: 10px 15px 15px transparentize($colorBlack, 0.7);
            .image-box img {
                filter: grayscale(0%);
            }
        }
    }
    .guild-name {
        margin-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.4rem;

        span {
            font-weight: 400;
            font-size: 1.2rem;
            opacity: 0.8;
        }
    }
}

.guild-stats-card-grid {
    display: grid;
    gap: 2rem;
    margin: 2rem 1rem;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 1620px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 920px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .stat-card {
        background-color: var(--backgroundColor03);
        border-radius: 10px;
        box-shadow: 5px 10px 15px transparentize($colorBlack, 0.6);
        transition: $transition02;
        padding: 1rem;

        .title {
            font-size: 23px;
            font-family: "Archivo Black", sans-serif;
            margin-bottom: 15px;
        }

        .value {
            font-size: 45px;
            font-family: "Bungee", sans-serif;
            line-height: 3rem;
            margin-bottom: 20px;
        }

        .today {
            font-size: 18px;
            font-weight: 300;
        }

        .graph {
            width: 100%;
            height: 285px;
            border-radius: 10px;
        }

        &:hover {
            transform: translateY(-5px);
            box-shadow: 10px 20px 35px transparentize($colorBlack, 0.6);
        }
    }
}

.guild-stats-graph-card-grid {
    display: grid;
    gap: 2rem;
    margin: 0 1rem;
    margin-bottom: 3rem;

    @media (min-width: 1020px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 820px) {
        .grid-col-span-2 {
            grid-column: span 2;
        }
    }

    .stat-card {
        background-color: var(--backgroundColor03);
        height: 350px;
        border-radius: 10px;
        box-shadow: 5px 10px 15px transparentize($colorBlack, 0.6);
        transition: $transition02;
        padding: 1rem;

        .title {
            font-size: 23px;
            font-family: "Archivo Black", sans-serif;
            margin-bottom: 10px;
        }

        .graph {
            width: 100%;
            height: 285px;
            border-radius: 10px;
        }

        &:hover {
            transform: translateY(-5px);
            box-shadow: 10px 20px 35px transparentize($colorBlack, 0.6);
        }
    }
}

.guild-setting-grid {
    .grid01 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        margin: auto;

        @media screen and (max-width: 1320px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .grid02 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: min-content;
        gap: 20px;
    }
    .card {
        min-height: 0 !important;
        background-color: var(--backgroundColor02);
        border-radius: 10px;
        padding: 1rem;

        .change-card {
            display: flex;
            flex-direction: column;
            margin: 1rem 0;
            .main {
                position: relative;
                padding: 15px;
                background-color: var(--backgroundColor01);
                border-radius: 10px;
                transition: $transition02;
                order: 0;

                .message {
                    display: flex;
                    justify-content: space-between;
                    font-size: 20px;
                    border-radius: 10px;
                    overflow: hidden;
                    background-color: var(--backgroundColor02);

                    .message-text {
                        display: flex;
                        align-items: center;
                        padding: 0 10px;

                        .time {
                            font-size: 15px;
                            height: 30px;
                            background-color: var(--backgroundColor05);
                        }

                        .message-changes {
                            padding: 10px 10px;
                        }
                    }

                    .icon {
                        width: 40px;
                        padding: 10px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: $transition03;
                    }
                }

                .id,
                .time,
                .username {
                    background-color: var(--backgroundColor02);
                    padding: 5px 10px;
                    border-radius: 8px;
                }

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 3px 5px 15px transparentize($colorBlack, 0.6);
                    cursor: pointer;
                }
            }
            .changes {
                background-color: var(--backgroundColor01);
                border-radius: 10px;
                transition: all $transition03;
                overflow-x: hidden;
                margin-top: 0;
                max-height: 0;
                opacity: 0;
                padding: 0 15px;
                overflow-y: scroll;
                order: 1;

                &.active {
                    margin-top: 10px;
                    padding: 10px 15px;
                    max-height: 500px;
                    opacity: 1;

                    ~ .main .message .icon {
                        transform: rotate(180deg);
                    }
                }

                .change {
                    background-color: var(--backgroundColor02);
                    padding: 7px 10px;
                    margin: 5px 0;
                    font-size: 17px;
                    border-radius: 8px;

                    .change-index {
                        line-height: 0;
                        font-size: 15px;
                        font-family: "Bungee", sans-serif;
                        color: $colorOrange01;
                    }

                    .change-name {
                        line-height: 0;
                        font-size: 15px;
                        font-family: "Bungee", sans-serif;
                        color: $colorPrimary;
                    }

                    .change-value {
                        font-family: "Archivo Black", sans-serif;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.grid-commands {
    display: grid;
    gap: 1rem;

    .command-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--backgroundColor03);
        color: var(--textColor01);
        min-height: 70px;
        padding: 15px 20px;
        border-radius: 10px;
        cursor: pointer;
        transition: $transition02;

        .name {
            margin-bottom: 5px;
            font-family: "Archivo Black", sans-serif;
            font-size: 20px;
            transition: $transition02;
        }

        .description {
            font-weight: 400;
            font-size: 18px;
        }

        &:hover {
            transform: translateY(-10px);
            box-shadow: 5px 15px 25px transparentize($colorBlack, 0.6);

            .name {
                color: $colorPrimary;
            }
        }
    }
}

.module-list-grid {
    display: grid;
    grid-template-columns: 350px;
    gap: 25px;
    margin: 1rem 0.5rem;
    margin-bottom: 2.5rem;
    @media (min-width: 50em) {
        grid-template-columns: repeat(auto-fit, 350px);
    }

    .module {
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        background-color: var(--backgroundColor03);
        box-shadow: 5px 10px 15px transparentize($colorBlack, 0.6);
        transition: $transition02;
        width: 350px;
        min-height: 130px;
        padding: 0.5rem 1rem;
        position: relative;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 10px 20px 35px transparentize($colorBlack, 0.6);
        }

        .details {
            cursor: pointer;
            font-size: 14.5px !important;
            margin-bottom: 10px;
        }

        .switch {
            position: absolute;
            width: 74px;
            top: 2px;
            right: 10px;
        }
    }
}

.guild-setting-command-grid {
    display: grid;
    grid-template-columns: 350px;
    gap: 25px;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    @media (min-width: 50em) {
        grid-template-columns: repeat(auto-fit, 350px);
    }

    .guild-setting-command-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        background-color: var(--backgroundColor02);

        transition: $transition02;
        width: 350px;
        padding: 0.2rem 0.8rem;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &.break {
            grid-column-end: span 2;
        }

        &:hover {
            transform: translateY(-5px);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .click-area {
            z-index: 1;
        }
        .switch {
            z-index: 2;
        }
    }
}

.self-roles-role-grid {
    display: grid;
    gap: 10px;

    .card {
        display: flex;
        background-color: var(--backgroundColor01);
        padding: 10px;
        border-radius: 10px;
        justify-content: space-between;
        .card-detail {
            align-items: center;
        }
        .button {
            display: flex;
            justify-content: end;
        }
    }
}

.confirm_payment {
    .card-container {
        padding: 15px;
        background-color: var(--backgroundColor02);
        border-radius: 10px;
    }
    .product {
        padding: 10px 20px !important;
        .product-name {
            font-family: "Archivo Black", sans-serif;
            font-size: 1.2rem;
            line-height: 30px;
        }
        .product-price {
            font-size: 18px;
            opacity: 0.7;
        }
    }
}

.payment_list {
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;

    @media screen and (max-width: 610px) {
        .card {
            flex-direction: column;
            gap: 10px;

            &.add-button,
            &.add-button-billing {
                flex-direction: row;
            }
        }
    }

    @media screen and (max-width: 450px) {
        .card {
            padding: 20px 10px;
            .primary {
                flex-direction: column;
                gap: 0 !important;
            }
        }
    }

    .card {
        padding: 15px;
        background-color: var(--backgroundColor01);
        border-radius: 5px;

        &.add-button,
        &.add-button-billing {
            padding: 3px;
            background-color: $buttonColorSuccess;
            transition: $transition02;
            cursor: pointer;
            h1 {
                color: $colorWhite;
                transition: $transition02;
            }
            &:hover {
                outline: 3px solid;
                background-color: transparent;
                outline-color: $buttonColorSuccess;
                h1 {
                    color: var(--textColor01);
                }
            }
        }

        &.add-button-billing {
            width: fit-content;
            justify-self: right;
            padding: 3px 10px;
            h1 {
                font-size: 1.1rem !important;
            }
        }

        .main {
            display: flex;
            flex-direction: column;

            .primary {
                display: flex;
                gap: 20px;
                align-items: center;

                .brand {
                    font-family: "Bungee", sans-serif;
                    font-size: 1.3rem;
                    line-height: 30px;
                }

                .card-end {
                    font-family: "Archivo Black", sans-serif;
                    font-size: 1.1rem;
                    line-height: 30px;
                }
            }

            .secondary {
                .name {
                    opacity: 0.5;
                }
            }
        }
    }
}

.pricing-table-container {
    color: var(--textColor01);

    .pricing-header {
        display: grid;
        place-items: center;
        .plans-switch-container {
            position: relative;
            display: inline-block;

            span {
                position: absolute;
                pointer-events: none;
                top: 5px;
                width: 110px;
                height: 35px;
                font-family: "Archivo Black", sans-serif;
                line-height: 35px;
                text-align: center;
                transition: $transition02;
            }

            .plans-switch {
                position: relative;
                width: 250px;
                height: 45px;
                background-color: var(--backgroundColor02);
                appearance: none;
                border-radius: 10px;
                outline: none;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    height: 35px;
                    width: 110px;
                    background-color: $buttonColorPrimary;
                    border-radius: 8px;
                    top: 50%;
                    left: 5px;
                    transform: translateY(-50%);
                    transition: $transition02;
                }

                &:checked {
                    &::before {
                        left: 135px;
                    }

                    ~ .monthly {
                        color: var(--textColor01);
                    }
                    ~ .yearly {
                        color: $colorWhite;
                    }
                }
            }

            .monthly {
                left: 5px;
                color: $colorWhite;
            }

            .yearly {
                right: 5px;
            }
        }
    }

    .pricing-table {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .table {
            position: relative;
            background-color: var(--backgroundColor02);
            width: 300px;
            padding: 30px;
            border-radius: 8px;
            box-shadow: 0px 0px 35px transparentize($colorBlack, 0.7);

            &.right {
                border-radius: 8px 0 0 8px;
            }

            .save {
                position: absolute;
                top: -15px;
                left: -15px;
                right: 0;
                text-align: center;
                padding: 4px 10px;
                color: $colorWhite;
                width: fit-content;
                z-index: 1;
                background-color: $colorSuccess;
                border-radius: 25px;
                transform: rotateZ(-5deg);
                font-family: "Archivo Black", sans-serif;
            }

            h3 {
                font-size: 24px;
                font-family: "Bungee", sans-serif;
            }

            .price-container {
                .price {
                    font-size: 40px;
                    font-family: "Bungee", sans-serif;
                    padding-right: 10px;
                }
                .plan-duration {
                    opacity: 0.7;
                }
            }

            .description {
                font-size: 14px;
                margin-bottom: 1rem;
            }

            .features {
                list-style: none;
                padding: 0;
                margin-bottom: 2rem;

                li {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin: 12px 0;

                    .icon {
                        font-size: 1.3rem;
                        color: $colorSuccess;
                    }
                }
            }
        }

        .best-value {
            border-radius: 16px;
            width: 330px;
            background-color: $backgroundColor01;
            color: $colorWhite;

            .value {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: center;
                padding: 8px;
                border-radius: 16px 16px 0 0;
                background-color: $colorPrimary;
                font-family: "Archivo Black", sans-serif;
            }

            .content {
                padding-top: 42px;

                .price-container {
                    .price {
                        font-size: 45px;
                        color: $colorYellow01;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 720px) {
        .pricing-table {
            flex-direction: column;
            gap: 25px;

            .table {
                &.right {
                    border-radius: 8px;
                }
            }
        }
    }
}

.background-card {
    background-color: var(--backgroundColor03);
}

.home-info-panel {
    margin: 3rem 0;
    img.hover {
        animation-play-state: running;
        &:hover {
            animation-play-state: paused;
        }
    }

    .images {
        z-index: 1 !important;
    }

    .about {
        z-index: 2 !important;
        .title {
            color: var(--textColor01);
            font-family: "Archivo Black", sans-serif;
            font-size: 4rem;
        }
        .description {
            max-width: 600px;
            color: white;
            background-color: $backgroundColor02;
            padding: 10px 15px;
            border-radius: 8px;
            font-size: 1.2rem;
        }
    }
    @media screen and (max-width: 1300px) {
        height: 400px;
    }

    @media screen and (max-width: 1140px) {
        height: fit-content;
        .icons {
            display: flex;
            margin-top: 10px;

            img {
                position: sticky;
                left: auto;
                right: auto;
                bottom: auto;
                top: auto;
            }
        }
        &.main,
        &.auto-role,
        &.self-role,
        &.leveling,
        &.greeter,
        &.auto-publish {
            .about {
                position: unset;
                .title {
                    text-align: center !important;
                }

                .description {
                    width: 100% !important;
                    max-width: 100vw !important;
                }
            }
        }
        &.self-role .images {
            img {
                left: 0;
            }

            .img-01 {
                top: 20px;
            }
            .img-02 {
                top: 180px;
                z-index: 1;
                left: auto;
                right: 0;
            }
            .img-03 {
                top: 300px;
            }
        }

        &.greeter .images {
            img {
                right: 0;
            }

            .img-01 {
                top: 20px;
            }
            .img-02 {
                top: 200px;
                left: 0;
            }
        }
        &.auto-role,
        &.self-role,
        &.leveling {
            margin-top: 10rem;
        }
    }
    @media screen and (max-width: 760px) {
        .icon {
            display: block;
        }
        &.main {
            .title {
                font-size: 40px;
            }
        }
        &.self-role {
            height: auto;
            margin-top: 0;
            .images {
                img {
                    position: unset;
                    left: 0;
                    border-radius: 0;

                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 20px;
                }
                .img-02 {
                    display: none;
                }
            }
        }
        &.greeter {
            height: auto;
            .images {
                img {
                    position: unset;
                    left: 0;
                    border-radius: 0;

                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 20px;
                }
            }
        }
        &.leveling {
            margin-top: 0;
            .images {
                img {
                    position: unset;
                    left: 0;
                    border-radius: 0;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 20px;
                }
            }
        }
    }
}

.greeter-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    .greeter-grid-in {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }
    @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr;
    }
}

.greeter-variables {
    z-index: 103;
    position: fixed;
    bottom: 70px;
    right: 0;
    width: 100%;
    padding: 1rem 2rem;
    transition: $transition03;

    .bar {
        display: flex;
        transition: $transition03;
        height: 60px;
        width: 100%;
        border-radius: 10px;
        border: 2px solid var(--backgroundColor01);
        background-color: transparentize($backgroundColor02, 0.1);
        gap: 6px;
        align-items: center;
        padding: 0 12px;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            height: 5px;
        }

        button {
            background-color: black;
            color: white;
            padding: 5px 10px;
            border-radius: 6px;
            transition: 0.2s;
            white-space: nowrap;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &.closed {
        bottom: -170px;

        button {
            pointer-events: none;
        }
    }
}

.core-message-list {
    display: grid;
    list-style: none;
    padding-left: 0;
    li {
        padding: 0.5em;
        margin: 1em;
    }
    .message {
        padding: 1rem 2rem;
    }
    .message-new {
        padding: 1rem;
    }
    .core-message-embed-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .embed {
            position: relative;
            width: 250px;
            min-height: 120px;
            border-radius: 5px;

            &:hover {
                .delete-button {
                    display: inherit;
                }
            }
        }
    }
    .core-message-component-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .component {
            position: relative;
            width: 250px;
            height: 60px;
            border-radius: 5px;

            .button-name {
                z-index: 1;
            }

            button {
                z-index: 2;
            }

            &:hover {
                .delete-button {
                    display: inherit;
                }
            }
        }
    }
    .delete-button {
        display: none;
        position: absolute;
        bottom: -8px;
        right: -8px;
        background-color: $colorDanger;
        font-size: 25px;
        border-radius: 10px;
        padding: 1px;
        color: white;
    }
}

.logging-grid {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }
}

.grid-col-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }
}
.grid-col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &.gap-select-no-label,
    &.gap-select {
        gap: 0.5rem;
        margin-top: 0.5rem;
        column-gap: 1rem;
    }
    &.mt-0 {
        margin-top: 0rem !important;
    }
    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }
}
.grid-col-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    &.gap-select-no-label,
    &.gap-select {
        gap: 0.5rem;
        margin-top: 0.5rem;
        column-gap: 1rem;
    }
    &.mt-0 {
        margin-top: 0rem !important;
    }
    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
}
