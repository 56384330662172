.button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    button {
        display: inline-flex;
        border-radius: 5px;
        border: none;
        outline: none;
        font-size: 1rem;
        font-weight: 500;
        transition: $transition02;
        cursor: pointer;
        overflow: hidden;
        color: white;
        width: fit-content;
        border: 3px solid transparent;

        &.color-theme {
            color: var(--textColor01) !important;
        }

        &.shadow {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
        }

        &.lock-black {
            color: black;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.full {
            width: 100%;
            justify-content: center;
        }

        &.color-link {
            padding: 0;
            .button-text,
            .button-icon {
                padding: 10px 5px;
            }
        }

        &.color-primary {
            background-color: $buttonColorPrimary;
        }
        &.color-secondary {
            background-color: $buttonColorSecondary;
        }
        &.color-success {
            background-color: $buttonColorSuccess;
        }
        &.color-danger {
            background-color: $buttonColorDanger;
        }
        &.color-blurple {
            background-color: $buttonColorBlurple;
        }
        &.color-gold {
            background-color: $buttonColorGold;
        }

        .button-text,
        .button-icon {
            display: inline-flex;
            align-items: center;
            padding: 7px 13px;
            height: 100%;
            &.both {
                padding-right: 0;
            }
            &.both-1 {
                padding-left: 0;
            }
        }
        .button-icon {
            font-size: 1.5rem;
            &.small {
                font-size: 1.3rem;
            }
        }

        &.hover03 {
            color: var(--textColor01);
            background-color: transparent;

            &.color-primary {
                border-color: $buttonColorPrimary;
            }
            &.color-secondary {
                border-color: $buttonColorSecondary;
            }
            &.color-success {
                border-color: $buttonColorSuccess;
            }
            &.color-danger {
                border-color: $buttonColorDanger;
            }
            &.color-blurple {
                border-color: $buttonColorBlurple;
            }
            &.color-gold {
                border-color: $buttonColorGold;
            }
        }

        &:hover {
            &.color-link {
                border-bottom: 1px solid $colorWhite;
                border-radius: 0;
            }
            &.hover01 {
                color: var(--textColor01);
                background-color: transparent;
                &.full {
                    width: calc(100% - 5px) !important;
                    height: calc(100% - 3px) !important;
                }

                &.color-primary {
                    border-color: $buttonColorPrimary;
                }
                &.color-secondary {
                    border-color: $buttonColorSecondary;
                }
                &.color-success {
                    border-color: $buttonColorSuccess;
                }
                &.color-danger {
                    border-color: $buttonColorDanger;
                }
                &.color-blurple {
                    border-color: $buttonColorBlurple;
                }
                &.color-gold {
                    border-color: $buttonColorGold;
                }
            }

            &.hover02 {
                &.color-primary {
                    background-color: darken($buttonColorPrimary, 5%);
                }
                &.color-secondary {
                    background-color: darken($buttonColorSecondary, 10%);
                }
                &.color-success {
                    background-color: darken($buttonColorSuccess, 10%);
                }
                &.color-danger {
                    background-color: darken($buttonColorDanger, 15%);
                }
                &.color-blurple {
                    background-color: darken($buttonColorBlurple, 5%);
                }
                &.color-gold {
                    background-color: darken($buttonColorGold, 5%);
                }
            }

            &.hover03 {
                color: white;
                &.color-primary {
                    background-color: $buttonColorPrimary;
                }
                &.color-secondary {
                    background-color: $buttonColorSecondary;
                }
                &.color-success {
                    background-color: $buttonColorSuccess;
                }
                &.color-danger {
                    background-color: $buttonColorDanger;
                }
                &.color-blurple {
                    background-color: $buttonColorBlurple;
                }
                &.color-gold {
                    background-color: $buttonColorGold;
                }
            }
        }
    }
}

.toggle-button-cover {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: table-cell;
    position: relative;
    height: 50px;
    width: 74px;
    box-sizing: border-box;

    &.no-m {
        height: 36px;
    }

    .button-cover {
        &:before {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #d7e3e3;
            font-size: 12px;
            line-height: 1;
            padding: 5px;
        }

        .toggle-button {
            position: relative;
            top: 50%;
            width: 74px;
            height: 36px;
            margin: -17.8px auto 0 auto;
            overflow: hidden;

            &.r,
            &.r .toggle-layer {
                border-radius: 100px;
            }

            &.b2 {
                border-radius: 5px;
            }

            .checkbox {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 3;
            }

            .toggle-knobs {
                z-index: 2;
            }

            .toggle-layer {
                width: 100%;
                background-color: $backgroundColor01;
                transition: 0.3s ease all;
                z-index: 1;
            }
        }

        /* Button 0 */
        #toggle-button-0 {
            pointer-events: none;
            .toggle-knobs:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 4px;
                width: 66px;
                height: 28px;
                padding: 9px 4px;
                border-radius: 5px;
                background-color: $buttonColorDanger;
            }

            .toggle-knobs:after {
                content: "O";
                position: absolute;
                top: 4px;
                left: 4px;
                width: 66px;
                height: 28px;
                color: $textColor01;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                padding: 9px 4px;
                transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
            }

            .checkbox {
                &:checked {
                    + .toggle-knobs:before {
                        background-color: $buttonColorSuccess;
                    }

                    + .toggle-knobs:after {
                        content: "I";
                    }

                    ~ .toggle-layer {
                        background-color: $backgroundColor01;
                    }
                }
            }
        }
        /* Button 1 */
        #toggle-button-1 {
            &:hover {
                .toggle-knobs:before {
                    width: 35px;
                }
            }

            .toggle-knobs:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 4px;
                width: 30px;
                height: 28px;
                padding: 9px 4px;
                border-radius: 5px;
                background-color: $buttonColorDanger;
                transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
            }

            .toggle-knobs:after {
                content: "O";
                position: absolute;
                top: 4px;
                left: 38px;
                width: 30px;
                height: 28px;
                color: $textColor01;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                padding: 9px 4px;
                transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
            }

            &:hover .checkbox {
                &:checked + .toggle-knobs:before {
                    margin-left: -5px;
                }
            }

            .checkbox {
                &:active + .toggle-knobs:before {
                    width: 46px;
                }
                &:active + .toggle-knobs:after {
                    opacity: 0;
                }

                &:checked {
                    &:active + .toggle-knobs:before {
                        margin-left: -16px;
                    }

                    + .toggle-knobs:before {
                        left: 40px;
                        background-color: $buttonColorSuccess;
                    }

                    + .toggle-knobs:after {
                        content: "I";
                        left: 6px;
                    }

                    ~ .toggle-layer {
                        background-color: $backgroundColor01;
                    }
                }
            }
        }
    }

    &.toggle-lg {
        width: 104px;
        .button-cover {
            &:before {
                font-size: 12px;
            }

            .toggle-button {
                height: 50px;
                width: 104px;
                margin: -25px auto 0 auto;
            }
            /* Button 1 */
            #toggle-button-1 {
                &:hover {
                    .toggle-knobs:before {
                        width: 51px;
                    }
                }

                .toggle-knobs:before {
                    width: 46px;
                    height: 42px;
                }

                .toggle-knobs:after {
                    top: 8px;
                    left: 53px;
                    width: 46px;
                    height: 42px;
                    font-size: 15px;
                }

                .checkbox {
                    &:active + .toggle-knobs:before {
                        width: 72px;
                    }

                    &:checked {
                        &:active + .toggle-knobs:before {
                            margin-left: -26px;
                        }
                        + .toggle-knobs:before {
                            left: 54px;
                        }
                        + .toggle-knobs:after {
                            left: 4px;
                        }
                    }
                }
            }
        }
    }

    .button-cover,
    .toggle-knobs,
    .toggle-layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.toggle-checkbox {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    // margin: 0 10px;

    input {
        display: none;

        &:checked + .slider {
            background-color: $colorSuccess;
            .icon {
                opacity: 1;
            }
        }
    }

    .slider {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background-color: var(--backgroundColor05);
        transition: 0.2s;
        border-radius: 3px;
        .icon {
            transition: 0.4s;
            opacity: 0;
            color: white;
            font-size: 1.2rem;
        }
    }
}

.range {
    width: 100%;
    border-radius: 8px;
    padding: 20px 20px 30px 20px;

    .current {
        color: $colorBlue01;
    }

    .field {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .value {
            position: absolute;
            font-size: 15px;
            font-weight: 600;
            color: $colorBlue01;
            bottom: -28px;
            text-align: center !important;

            &.gold {
                color: $colorOrange01;
            }
        }

        input {
            -webkit-appearance: none;
            height: 10px;
            width: 100%;
            border-radius: 5px;
            outline: none;
            border: none;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 25px;
                background-color: $colorBlue01;
                border-radius: 10px;
            }
        }
    }
}

.tag-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    button {
        background-color: black;
        color: white;
        padding: 5px 10px;
        border-radius: 6px;
        transition: 0.2s;

        &:hover {
            transform: scale(1.1);
        }
    }
}
