@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Maven+Pro");
@import url("https://fonts.googleapis.com/css2?family=Bungee");
@import url("https://fonts.googleapis.com/css2?family=Odibee+Sans");
@import url("https://fonts.googleapis.com/css2?family=Play");
@import url("https://fonts.googleapis.com/css2?family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import "./fonts/style.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
$backgroundColor01: #111112;
$backgroundColor02: #121218;
$backgroundColor03: #0f0f11;
--backgroundColor01: #15151b;
--backgroundColor02: #111112;
--backgroundColor03: #0f0f11;
*/
body {
  --discordButtonPrimary: #5865f2;
  --discordButtonSecondary: #4f545c;
  --discordButtonSuccess: #43b581;
  --discordButtonDanger: #f04747;
  --discordButtonPrimaryHover: #4752c4;
  --discordButtonSecondaryHover: #686d73;
  --discordButtonSuccessHover: #318861;
  --discordButtonDangerHover: #a12d2f;
}

body.dark-theme {
  --backgroundColor01: #1d232f;
  --backgroundColor02: #171c26;
  --backgroundColor03: #141820;
  --backgroundColor04: #dadada;
  --backgroundColor05: #364157;
  --backgroundColor06: #1418207c;
  --tableBackgroundColor01: #141820;
  --textColor01: #ffffff;
  --textColorFaded01: #ffffff88;
  --textColor02: #000000;
  --textCodeBackground: #0c0e13;
  --textError01: #ffb3b3;
  --textColorRed01: #ff6060;
  --guildCardIconBackground: transparent;
  --discordBackground: #36393f;
  --discordBackgroundHover: rgb(50, 53, 59);
  --discordEmbedBackground: rgb(47, 49, 54);
  --discordEmbedSidebarBackground: rgb(32, 34, 37);
  --discordEmbedLink: rgb(0, 176, 244);
  --discordEmbedTitle: rgb(255, 255, 255);
  --discordEmbedDescription: rgb(220, 221, 222);
  --discordTextMuted: rgb(163, 164, 162);
  --discordTextCode: rgb(185, 187, 190);
  --discordBlockquoteBar: rgb(79, 84, 92);
  --discordSelectMenuBackground: #202225;
  --discordSelectMenuBorder: #040405;
  --discordSelectMenuHover: #3c3f45;
  --discordMentionText: rgb(222, 224, 252);
  --discordMentionBackground: rgba(88, 101, 242, 0.3);
  --discordMentionTextHover: rgb(255, 255, 255);
  --discordMentionBackgroundHover: rgb(88, 101, 242);
  --linkColor: rgb(152, 140, 255);
}

body.light-theme {
  --backgroundColor01: #dadada;
  --backgroundColor02: #f1f1f1;
  --backgroundColor03: #eeeeee;
  --backgroundColor04: #1d232f;
  --backgroundColor05: #bdbdbd;
  --backgroundColor06: #eeeeee79;
  --tableBackgroundColor01: #e4e4e4;
  --textColor01: #000000;
  --textColorFaded01: #00000081;
  --textColor02: #ffffff;
  --textCodeBackground: #bdbdbd;
  --textError01: #ff7a7a;
  --textColorRed01: #ff4646;
  --guildCardIconBackground: #171c26;
  --discordBackground: rgb(255, 255, 255);
  --discordBackgroundHover: rgb(250, 250, 250);
  --discordEmbedBackground: rgb(242, 243, 245);
  --discordEmbedSidebarBackground: rgb(227, 229, 232);
  --discordEmbedLink: rgb(0, 103, 224);
  --discordEmbedTitle: rgb(6, 6, 7);
  --discordEmbedDescription: rgb(46, 51, 56);
  --discordTextMuted: #5e6772;
  --discordTextCode: rgb(79, 86, 96);
  --discordBlockquoteBar: rgb(199, 204, 209);
  --discordSelectMenuBackground: #e3e5e8;
  --discordSelectMenuBorder: #b9bbbe;
  --discordSelectMenuHover: #dcdfe3;
  --discordMentionText: rgb(88, 101, 242);
  --discordMentionBackground: rgba(88, 101, 242, 0.15);
  --discordMentionTextHover: rgb(255, 255, 255);
  --discordMentionBackgroundHover: rgb(88, 101, 242);
  --linkColor: rgb(98, 81, 253);
  --discordButtonSecondary: #747f8d;
}

:root {
  --toastify-color-light: #f1f1f1 !important;
  --toastify-color-dark: #141820 !important;
}

em-emoji-picker {
  --border-radius: 8px;
  --category-icon-size: 24px;
  --color-border-over: rgba(0, 0, 0, 0.1);
  --color-border: rgba(0, 0, 0, 0.05);
  --font-family: "Archivo Black", sans-serif;
  --font-size: 16px;
  --rgb-accent: 140, 130, 206;
  --rgb-background: 23, 28, 38;
  --rgb-color: 255, 255, 255;
  --rgb-input: 20, 24, 32;
  --shadow: 5px 5px 15px -8px black;
  min-height: 400px;
  max-height: 800px;
  position: absolute;
  right: -35px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 2px !important;
  background-color: #171c26;
}
*::-webkit-scrollbar-thumb {
  background-color: #8c82ce;
  border-radius: 8px;
}

html {
  height: 100%;
}

body {
  overflow-y: auto;
  width: 100vw;
  min-height: 100vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--backgroundColor01) !important;
  transition: 0.5s;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  overflow-x: hidden !important;
}
body.lock-scroll {
  overflow-y: hidden;
}
body #root {
  z-index: 1;
}
body #portal {
  z-index: 2;
  overflow-x: hidden !important;
}
body #loading-root {
  position: fixed;
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: 0.3s;
  overflow-x: hidden !important;
}
body #loading-root img {
  height: 150px;
  width: 150px;
}
body.load {
  overflow: hidden;
}
body.load #root {
  pointer-events: none !important;
}
body.load #portal {
  pointer-events: none !important;
}
body.load #loading-root {
  pointer-events: none !important;
  opacity: 1;
  z-index: 999;
}
body.load #loading-root img {
  visibility: visible !important;
}
body.lock-scroll {
  height: 100%;
  overflow: hidden;
}
body .container-default {
  padding: 1rem 1.5rem;
}
@media screen and (max-width: 620px) {
  body .container-default {
    padding: 1rem 5px;
  }
}
body .dashboard-section .container {
  padding: 1rem !important;
  max-width: 1720px !important;
  width: 100% !important;
}
@media screen and (max-width: 620px) {
  body .dashboard-section .container {
    padding: 1rem 5px !important;
  }
}
body .module-disabled {
  display: flex;
  flex-direction: column;
  height: 250px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundColor02);
}
body .module-disabled .icon {
  font-size: 5rem;
}
body .module-title {
  display: flex;
  align-items: center;
}
body .flex-space {
  display: flex;
  justify-content: space-between;
}
body .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
body .ma.t1 {
  margin-top: 1rem !important;
}
body .ma.t2 {
  margin-top: 2rem !important;
}
body .ma.t3 {
  margin-top: 2rem !important;
}
body .ma.t4 {
  margin-top: 4rem !important;
}
body .ma.t5 {
  margin-top: 5rem !important;
}
body .sep {
  height: 5px;
  background-color: #8c82ce;
  border-radius: 20px;
}
body .sep.r1 {
  width: 1rem;
}
body .sep.r3 {
  width: 3rem;
}
body .sep.r6 {
  width: 6rem;
}
body .sep.r12 {
  width: 12rem;
}
body .sep.r24 {
  width: 24rem;
}
body .sep.r48 {
  width: 48rem;
}
body .sep.r96 {
  width: 96rem;
}
body .sep.p10 {
  width: 10%;
}
body .sep.p25 {
  width: 25%;
}
body .sep.p50 {
  width: 50%;
}
body .sep.p75 {
  width: 75%;
}
body .sep.p100 {
  width: 100%;
}
body .shake {
  animation: shake 0.1s ease-in-out;
}
@keyframes shake {
  0% {
    transform: translateX(0.2rem) translateY(-0.2rem);
  }
  25% {
    transform: translateX(0) translateY(0.2rem);
  }
  50% {
    transform: translateX(0.2rem) translateY(0);
  }
  70% {
    transform: translateX(0) translateY(-0.2rem);
  }
  100% {
    transform: translateX(-0.2rem) translateY(0.2rem);
  }
}
body .float-0-1 {
  animation: float-0 10s ease-in-out infinite;
}
body .float-0-2 {
  animation: float-0 20s ease-in-out infinite;
}
body .float-0-3 {
  animation: float-0 30s ease-in-out infinite;
}
body .float-1-1 {
  animation: float-1 10s ease-in-out infinite;
}
body .float-1-2 {
  animation: float-1 20s ease-in-out infinite;
}
body .float-1-3 {
  animation: float-1 30s ease-in-out infinite;
}
body .float-2-1 {
  animation: float-2 10s ease-in-out infinite;
}
body .float-2-2 {
  animation: float-2 20s ease-in-out infinite;
}
body .float-2-3 {
  animation: float-2 30s ease-in-out infinite;
}
body .full-divider {
  height: 200px;
  background-color: #141820;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  z-index: 10;
}
body .premium-tables {
  display: flex;
  padding: 0 2rem;
}
@media screen and (max-width: 1650px) {
  body .premium-tables {
    flex-direction: column;
  }
}
@media screen and (max-width: 580px) {
  body .premium-tables {
    padding: 0;
  }
}
body input[type=number]::-webkit-inner-spin-button,
body input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@keyframes float-0 {
  0% {
    transform: translateY(-5rem);
  }
  50% {
    transform: translateY(5rem);
  }
  100% {
    transform: translateY(-5rem);
  }
}
@keyframes float-1 {
  0% {
    transform: translateY(5rem);
  }
  50% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(5rem);
  }
}
@keyframes float-2 {
  0% {
    transform: translateY(-0.5rem);
  }
  50% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(-0.5rem);
  }
}
body .background-01 {
  background-color: var(--backgroundColor01) !important;
}
body .background-02 {
  background-color: var(--backgroundColor02) !important;
}
body .background-03 {
  background-color: var(--backgroundColor03) !important;
}
body .background-04 {
  background-color: var(--backgroundColor04) !important;
}
body .background-05 {
  background-color: var(--backgroundColor05) !important;
}
body .background-06 {
  background-color: var(--backgroundColor06) !important;
}
body .background-dark-01 {
  background-color: #171c26 !important;
}
body .background-dark-02 {
  background-color: #141820 !important;
}
body .background-dark-03 {
  background-color: #1a202b !important;
}
body .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
body .emoji-select {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 40px !important;
  width: 35px !important;
}
body .emoji-select .emoji-mart-emoji {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 40px !important;
  width: 35px !important;
}
body .emoji-select .emoji-mart-emoji img {
  display: block !important;
  top: 2.5px !important;
  left: 0 !important;
  height: 35px !important;
  width: 35px !important;
}
body .emoji-select.bg-1 {
  background-color: var(--backgroundColor01) !important;
}
body .emoji-select.bg-2 {
  background-color: var(--backgroundColor02) !important;
}
body .emoji-select.bg-3 {
  background-color: var(--backgroundColor03) !important;
}
body .selfrole-emoji-picker-selected-role {
  position: relative !important;
  margin-right: 44px !important;
}
body .selfrole-emoji-picker-selected-role .emoji-mart-emoji {
  position: absolute !important;
  top: -11px !important;
  left: 0 !important;
  width: 44px !important;
}
body .selfrole-emoji-picker-selected-role .emoji-mart-emoji img {
  min-height: 44px !important;
  min-width: 44px !important;
  border-radius: 4px !important;
}
body .list-item {
  list-style-type: "— ";
}
body .premium-bg {
  background-image: linear-gradient(45deg, #ffd04f, #ec458d, #474ed7);
  background-size: 300%;
  background-position: left;
  animation: premium-gradient 10s infinite alternate;
}
body .premium-text {
  background-image: linear-gradient(45deg, #ffd04f, #ec458d);
  background-size: 300%;
  background-position: left;
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent !important;
  animation: premium-gradient 5s infinite alternate;
}
body .primary-text {
  color: #8c82ce !important;
}
body .pink-text {
  color: #ec458d !important;
}
@keyframes premium-gradient {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
@media screen and (max-width: 760px) {
  body .hide-mobile-01 {
    display: none !important;
  }
}
body .z-index-01 {
  z-index: 1 !important;
}
body .z-index-02 {
  z-index: 2 !important;
}
body .discord-bg {
  background-color: var(--discordBackground);
}
body .btn-danger {
  background: #f04747;
  cursor: pointer;
  transition: 0.1s;
}
body .btn-danger:hover {
  background: #d91212;
}
@media screen and (max-width: 960px) {
  body .flex-small-screen {
    flex-direction: column;
  }
}
body .box-shadow-01 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
body .box-shadow-02 {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
body .box-shadow-03 {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
body .text {
  color: var(--textColor01);
}
body .text.color-02 {
  color: #8c92e0;
}
body .text.white-lock {
  color: white !important;
}
body .text.error {
  color: var(--textError01);
}
body .text.success {
  color: #43b581;
}
body .text.blurple {
  color: #5865f2;
}
body .text.danger {
  color: #f04747;
}
body .text.title-404 {
  font-size: 7rem;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
  line-height: 100px;
}
body .text.bot-title-1 {
  font-size: 5rem;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}
body .text.bot-subtitle-1 {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
body .text.bot-subtitle-1.bg-fade {
  background-color: var(--backgroundColor06);
  padding: 10px;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
}
body .text.title-1 {
  font-size: 7rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px 0;
}
body .text.title-2 {
  font-size: 6rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px 0;
}
body .text.title-3 {
  font-size: 5rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px 0;
}
body .text.title-4 {
  font-size: 4rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px 0;
}
body .text.title-5 {
  font-size: 3rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px 0;
}
body .text.title-6 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px 0;
}
body .text.title-6-5 {
  font-size: 1.65rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px 0;
}
body .text.title-7 {
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px 0;
}
body .text.title-8 {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px 0;
}
body .text.font-1 {
  font-family: "Bungee", sans-serif;
}
body .text.font-2 {
  font-family: "Archivo Black", sans-serif;
}
body .text.font-3 {
  font-family: "Roboto", sans-serif;
}
body .text.font-3 {
  font-family: "Arial", sans-serif;
}
body .text.font-discord {
  font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-seri;
}
body .text.subtitle-1 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 5px 0;
}
body .text.subtitle-2 {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 5px 0;
}
body .text.subtitle-3 {
  font-size: 1.15rem;
  font-weight: 400;
  margin: 5px 0;
}
body .text.subtitle-4 {
  font-size: 1rem;
  font-weight: 400;
  margin: 5px 0;
}
body .text.text-glitch::before, body .text.text-glitch::after {
  position: absolute;
  content: attr(data-text);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
body .text.text-glitch::before {
  left: 3px;
  text-shadow: -3px 0 red;
  animation: glitch-1 2s linear infinite reverse;
}
body .text.text-glitch::after {
  left: -3px;
  text-shadow: 3px 0 blue;
  animation: glitch-2 3s linear infinite reverse;
}
@keyframes glitch-1 {
  0% {
    clip: rect(132px, auto, 101px, 30px);
  }
  5% {
    clip: rect(17px, auto, 94px, 30px);
  }
  10% {
    clip: rect(40px, auto, 66px, 30px);
  }
  15% {
    clip: rect(87px, auto, 82px, 30px);
  }
  20% {
    clip: rect(137px, auto, 61px, 30px);
  }
  25% {
    clip: rect(34px, auto, 14px, 30px);
  }
  30% {
    clip: rect(133px, auto, 74px, 30px);
  }
  35% {
    clip: rect(76px, auto, 107px, 30px);
  }
  40% {
    clip: rect(59px, auto, 130px, 30px);
  }
  45% {
    clip: rect(29px, auto, 84px, 30px);
  }
  50% {
    clip: rect(22px, auto, 67px, 30px);
  }
  55% {
    clip: rect(67px, auto, 62px, 30px);
  }
  60% {
    clip: rect(10px, auto, 105px, 30px);
  }
  65% {
    clip: rect(78px, auto, 115px, 30px);
  }
  70% {
    clip: rect(105px, auto, 13px, 30px);
  }
  75% {
    clip: rect(15px, auto, 75px, 30px);
  }
  80% {
    clip: rect(66px, auto, 39px, 30px);
  }
  85% {
    clip: rect(133px, auto, 73px, 30px);
  }
  90% {
    clip: rect(36px, auto, 128px, 30px);
  }
  95% {
    clip: rect(68px, auto, 103px, 30px);
  }
  100% {
    clip: rect(14px, auto, 100px, 30px);
  }
}
@keyframes glitch-2 {
  0% {
    clip: rect(129px, auto, 36px, 30px);
  }
  5% {
    clip: rect(36px, auto, 4px, 30px);
  }
  10% {
    clip: rect(85px, auto, 66px, 30px);
  }
  15% {
    clip: rect(91px, auto, 91px, 30px);
  }
  20% {
    clip: rect(148px, auto, 138px, 30px);
  }
  25% {
    clip: rect(38px, auto, 122px, 30px);
  }
  30% {
    clip: rect(69px, auto, 54px, 30px);
  }
  35% {
    clip: rect(98px, auto, 71px, 30px);
  }
  40% {
    clip: rect(146px, auto, 34px, 30px);
  }
  45% {
    clip: rect(134px, auto, 43px, 30px);
  }
  50% {
    clip: rect(102px, auto, 80px, 30px);
  }
  55% {
    clip: rect(119px, auto, 44px, 30px);
  }
  60% {
    clip: rect(106px, auto, 99px, 30px);
  }
  65% {
    clip: rect(141px, auto, 74px, 30px);
  }
  70% {
    clip: rect(20px, auto, 78px, 30px);
  }
  75% {
    clip: rect(133px, auto, 79px, 30px);
  }
  80% {
    clip: rect(78px, auto, 52px, 30px);
  }
  85% {
    clip: rect(35px, auto, 39px, 30px);
  }
  90% {
    clip: rect(67px, auto, 70px, 30px);
  }
  95% {
    clip: rect(71px, auto, 103px, 30px);
  }
  100% {
    clip: rect(83px, auto, 40px, 30px);
  }
}
@media screen and (max-width: 580px) {
  body .text.bot-title-1 {
    font-size: 3rem;
  }
  body .text.bot-subtitle-1 {
    font-size: 1.1rem;
  }
}
body a {
  text-decoration: none;
  color: var(--linkColor);
}
body a:hover {
  text-decoration: underline;
}
body code {
  font-size: 16px;
  padding: 3px 10px;
  background-color: rgba(0, 0, 0, 0.3019607843);
  border-radius: 4px;
}
body .right-text {
  text-align: right;
}
body .left-text {
  text-align: left;
}
body .center-text {
  text-align: center;
}
body .module-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .info-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
body .info-icon .icon {
  display: flex;
  align-items: center;
}
body .premium-icon {
  color: #fa8e31;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  animation: premium-icon 2s infinite alternate;
}
@keyframes premium-icon {
  0% {
    color: #fa8e31;
  }
  100% {
    color: #ffd04f;
  }
}
body .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
body .button button {
  display: inline-flex;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  border: 3px solid transparent;
}
body .button button.color-theme {
  color: var(--textColor01) !important;
}
body .button button.shadow {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
}
body .button button.lock-black {
  color: black;
}
body .button button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
body .button button.full {
  width: 100%;
  justify-content: center;
}
body .button button.color-link {
  padding: 0;
}
body .button button.color-link .button-text,
body .button button.color-link .button-icon {
  padding: 10px 5px;
}
body .button button.color-primary {
  background-color: #776aca;
}
body .button button.color-secondary {
  background-color: #4f545c;
}
body .button button.color-success {
  background-color: #3ba55d;
}
body .button button.color-danger {
  background-color: #f04747;
}
body .button button.color-blurple {
  background-color: #5865f2;
}
body .button button.color-gold {
  background-color: #df9d23;
}
body .button button .button-text,
body .button button .button-icon {
  display: inline-flex;
  align-items: center;
  padding: 7px 13px;
  height: 100%;
}
body .button button .button-text.both,
body .button button .button-icon.both {
  padding-right: 0;
}
body .button button .button-text.both-1,
body .button button .button-icon.both-1 {
  padding-left: 0;
}
body .button button .button-icon {
  font-size: 1.5rem;
}
body .button button .button-icon.small {
  font-size: 1.3rem;
}
body .button button.hover03 {
  color: var(--textColor01);
  background-color: transparent;
}
body .button button.hover03.color-primary {
  border-color: #776aca;
}
body .button button.hover03.color-secondary {
  border-color: #4f545c;
}
body .button button.hover03.color-success {
  border-color: #3ba55d;
}
body .button button.hover03.color-danger {
  border-color: #f04747;
}
body .button button.hover03.color-blurple {
  border-color: #5865f2;
}
body .button button.hover03.color-gold {
  border-color: #df9d23;
}
body .button button:hover.color-link {
  border-bottom: 1px solid #ffffff;
  border-radius: 0;
}
body .button button:hover.hover01 {
  color: var(--textColor01);
  background-color: transparent;
}
body .button button:hover.hover01.full {
  width: calc(100% - 5px) !important;
  height: calc(100% - 3px) !important;
}
body .button button:hover.hover01.color-primary {
  border-color: #776aca;
}
body .button button:hover.hover01.color-secondary {
  border-color: #4f545c;
}
body .button button:hover.hover01.color-success {
  border-color: #3ba55d;
}
body .button button:hover.hover01.color-danger {
  border-color: #f04747;
}
body .button button:hover.hover01.color-blurple {
  border-color: #5865f2;
}
body .button button:hover.hover01.color-gold {
  border-color: #df9d23;
}
body .button button:hover.hover02.color-primary {
  background-color: #6657c3;
}
body .button button:hover.hover02.color-secondary {
  background-color: #373b41;
}
body .button button:hover.hover02.color-success {
  background-color: #2e7f48;
}
body .button button:hover.hover02.color-danger {
  background-color: #d91212;
}
body .button button:hover.hover02.color-blurple {
  background-color: #404ff0;
}
body .button button:hover.hover02.color-gold {
  background-color: #cb8e1e;
}
body .button button:hover.hover03 {
  color: white;
}
body .button button:hover.hover03.color-primary {
  background-color: #776aca;
}
body .button button:hover.hover03.color-secondary {
  background-color: #4f545c;
}
body .button button:hover.hover03.color-success {
  background-color: #3ba55d;
}
body .button button:hover.hover03.color-danger {
  background-color: #f04747;
}
body .button button:hover.hover03.color-blurple {
  background-color: #5865f2;
}
body .button button:hover.hover03.color-gold {
  background-color: #df9d23;
}
body .toggle-button-cover {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: table-cell;
  position: relative;
  height: 50px;
  width: 74px;
  box-sizing: border-box;
}
body .toggle-button-cover.no-m {
  height: 36px;
}
body .toggle-button-cover .button-cover {
  /* Button 0 */
  /* Button 1 */
}
body .toggle-button-cover .button-cover:before {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}
body .toggle-button-cover .button-cover .toggle-button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -17.8px auto 0 auto;
  overflow: hidden;
}
body .toggle-button-cover .button-cover .toggle-button.r, body .toggle-button-cover .button-cover .toggle-button.r .toggle-layer {
  border-radius: 100px;
}
body .toggle-button-cover .button-cover .toggle-button.b2 {
  border-radius: 5px;
}
body .toggle-button-cover .button-cover .toggle-button .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
body .toggle-button-cover .button-cover .toggle-button .toggle-knobs {
  z-index: 2;
}
body .toggle-button-cover .button-cover .toggle-button .toggle-layer {
  width: 100%;
  background-color: #171c26;
  transition: 0.3s ease all;
  z-index: 1;
}
body .toggle-button-cover .button-cover #toggle-button-0 {
  pointer-events: none;
}
body .toggle-button-cover .button-cover #toggle-button-0 .toggle-knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 66px;
  height: 28px;
  padding: 9px 4px;
  border-radius: 5px;
  background-color: #f04747;
}
body .toggle-button-cover .button-cover #toggle-button-0 .toggle-knobs:after {
  content: "O";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 66px;
  height: 28px;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}
body .toggle-button-cover .button-cover #toggle-button-0 .checkbox:checked + .toggle-knobs:before {
  background-color: #3ba55d;
}
body .toggle-button-cover .button-cover #toggle-button-0 .checkbox:checked + .toggle-knobs:after {
  content: "I";
}
body .toggle-button-cover .button-cover #toggle-button-0 .checkbox:checked ~ .toggle-layer {
  background-color: #171c26;
}
body .toggle-button-cover .button-cover #toggle-button-1:hover .toggle-knobs:before {
  width: 35px;
}
body .toggle-button-cover .button-cover #toggle-button-1 .toggle-knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 28px;
  padding: 9px 4px;
  border-radius: 5px;
  background-color: #f04747;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}
body .toggle-button-cover .button-cover #toggle-button-1 .toggle-knobs:after {
  content: "O";
  position: absolute;
  top: 4px;
  left: 38px;
  width: 30px;
  height: 28px;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}
body .toggle-button-cover .button-cover #toggle-button-1:hover .checkbox:checked + .toggle-knobs:before {
  margin-left: -5px;
}
body .toggle-button-cover .button-cover #toggle-button-1 .checkbox:active + .toggle-knobs:before {
  width: 46px;
}
body .toggle-button-cover .button-cover #toggle-button-1 .checkbox:active + .toggle-knobs:after {
  opacity: 0;
}
body .toggle-button-cover .button-cover #toggle-button-1 .checkbox:checked:active + .toggle-knobs:before {
  margin-left: -16px;
}
body .toggle-button-cover .button-cover #toggle-button-1 .checkbox:checked + .toggle-knobs:before {
  left: 40px;
  background-color: #3ba55d;
}
body .toggle-button-cover .button-cover #toggle-button-1 .checkbox:checked + .toggle-knobs:after {
  content: "I";
  left: 6px;
}
body .toggle-button-cover .button-cover #toggle-button-1 .checkbox:checked ~ .toggle-layer {
  background-color: #171c26;
}
body .toggle-button-cover.toggle-lg {
  width: 104px;
}
body .toggle-button-cover.toggle-lg .button-cover {
  /* Button 1 */
}
body .toggle-button-cover.toggle-lg .button-cover:before {
  font-size: 12px;
}
body .toggle-button-cover.toggle-lg .button-cover .toggle-button {
  height: 50px;
  width: 104px;
  margin: -25px auto 0 auto;
}
body .toggle-button-cover.toggle-lg .button-cover #toggle-button-1:hover .toggle-knobs:before {
  width: 51px;
}
body .toggle-button-cover.toggle-lg .button-cover #toggle-button-1 .toggle-knobs:before {
  width: 46px;
  height: 42px;
}
body .toggle-button-cover.toggle-lg .button-cover #toggle-button-1 .toggle-knobs:after {
  top: 8px;
  left: 53px;
  width: 46px;
  height: 42px;
  font-size: 15px;
}
body .toggle-button-cover.toggle-lg .button-cover #toggle-button-1 .checkbox:active + .toggle-knobs:before {
  width: 72px;
}
body .toggle-button-cover.toggle-lg .button-cover #toggle-button-1 .checkbox:checked:active + .toggle-knobs:before {
  margin-left: -26px;
}
body .toggle-button-cover.toggle-lg .button-cover #toggle-button-1 .checkbox:checked + .toggle-knobs:before {
  left: 54px;
}
body .toggle-button-cover.toggle-lg .button-cover #toggle-button-1 .checkbox:checked + .toggle-knobs:after {
  left: 4px;
}
body .toggle-button-cover .button-cover,
body .toggle-button-cover .toggle-knobs,
body .toggle-button-cover .toggle-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
body .toggle-checkbox {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}
body .toggle-checkbox input {
  display: none;
}
body .toggle-checkbox input:checked + .slider {
  background-color: #43b581;
}
body .toggle-checkbox input:checked + .slider .icon {
  opacity: 1;
}
body .toggle-checkbox .slider {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: var(--backgroundColor05);
  transition: 0.2s;
  border-radius: 3px;
}
body .toggle-checkbox .slider .icon {
  transition: 0.4s;
  opacity: 0;
  color: white;
  font-size: 1.2rem;
}
body .range {
  width: 100%;
  border-radius: 8px;
  padding: 20px 20px 30px 20px;
}
body .range .current {
  color: #8c92e0;
}
body .range .field {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
body .range .field .value {
  position: absolute;
  font-size: 15px;
  font-weight: 600;
  color: #8c92e0;
  bottom: -28px;
  text-align: center !important;
}
body .range .field .value.gold {
  color: #fa8e31;
}
body .range .field input {
  -webkit-appearance: none;
  height: 10px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: none;
}
body .range .field input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 25px;
  background-color: #8c92e0;
  border-radius: 10px;
}
body .tag-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
body .tag-buttons button {
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  transition: 0.2s;
}
body .tag-buttons button:hover {
  transform: scale(1.1);
}
body .form .form-inputs, body .form.form-inputs {
  display: grid;
  gap: 0.5rem;
}
body .form .form-input.disabled {
  opacity: 0.35;
  cursor: not-allowed;
}
body .form .form-input.disabled .label,
body .form .form-input.disabled .input,
body .form .form-input.disabled .error {
  pointer-events: none;
}
body .form .form-input.text-input, body .form .form-input.number-input, body .form .form-input.textarea-input, body .form .form-input.select-input, body .form .form-input.combo-select-input, body .form .form-input.button-select-input, body .form .form-input.toggle-switch-input, body .form .form-input.toggle-checkbox-input {
  display: grid;
  gap: 3px;
}
body .form .form-input.text-input .label, body .form .form-input.number-input .label, body .form .form-input.textarea-input .label, body .form .form-input.select-input .label, body .form .form-input.combo-select-input .label, body .form .form-input.button-select-input .label, body .form .form-input.toggle-switch-input .label, body .form .form-input.toggle-checkbox-input .label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: var(--textColor01);
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 3px;
}
body .form .form-input.text-input .label .tooltip-icon, body .form .form-input.number-input .label .tooltip-icon, body .form .form-input.textarea-input .label .tooltip-icon, body .form .form-input.select-input .label .tooltip-icon, body .form .form-input.combo-select-input .label .tooltip-icon, body .form .form-input.button-select-input .label .tooltip-icon, body .form .form-input.toggle-switch-input .label .tooltip-icon, body .form .form-input.toggle-checkbox-input .label .tooltip-icon {
  height: -moz-fit-content;
  height: fit-content;
  font-size: 15px;
}
body .form .form-input.text-input .error, body .form .form-input.number-input .error, body .form .form-input.textarea-input .error, body .form .form-input.select-input .error, body .form .form-input.combo-select-input .error, body .form .form-input.button-select-input .error, body .form .form-input.toggle-switch-input .error, body .form .form-input.toggle-checkbox-input .error {
  color: #f04747;
  font-size: 14px;
  display: none;
}
body .form .form-input.text-input.error .input, body .form .form-input.number-input.error .input, body .form .form-input.textarea-input.error .input, body .form .form-input.select-input.error .input, body .form .form-input.combo-select-input.error .input, body .form .form-input.button-select-input.error .input, body .form .form-input.toggle-switch-input.error .input, body .form .form-input.toggle-checkbox-input.error .input {
  border-color: #f04747;
  opacity: 1;
}
body .form .form-input.text-input.error .error, body .form .form-input.number-input.error .error, body .form .form-input.textarea-input.error .error, body .form .form-input.select-input.error .error, body .form .form-input.combo-select-input.error .error, body .form .form-input.button-select-input.error .error, body .form .form-input.toggle-switch-input.error .error, body .form .form-input.toggle-checkbox-input.error .error {
  display: block;
}
body .form .form-input.text-input .icon, body .form .form-input.number-input .icon, body .form .form-input.textarea-input .icon, body .form .form-input.select-input .icon, body .form .form-input.combo-select-input .icon, body .form .form-input.button-select-input .icon, body .form .form-input.toggle-switch-input .icon, body .form .form-input.toggle-checkbox-input .icon {
  color: var(--textColor01);
  height: 35px !important;
  font-size: 35px;
}
body .form .form-input.text-input .icon .emoji-mart-emoji, body .form .form-input.number-input .icon .emoji-mart-emoji, body .form .form-input.textarea-input .icon .emoji-mart-emoji, body .form .form-input.select-input .icon .emoji-mart-emoji, body .form .form-input.combo-select-input .icon .emoji-mart-emoji, body .form .form-input.button-select-input .icon .emoji-mart-emoji, body .form .form-input.toggle-switch-input .icon .emoji-mart-emoji, body .form .form-input.toggle-checkbox-input .icon .emoji-mart-emoji {
  height: 35px !important;
  display: flex;
  align-items: center;
}
body .form .form-input.text-input .icon .emoji-mart-emoji img, body .form .form-input.number-input .icon .emoji-mart-emoji img, body .form .form-input.textarea-input .icon .emoji-mart-emoji img, body .form .form-input.select-input .icon .emoji-mart-emoji img, body .form .form-input.combo-select-input .icon .emoji-mart-emoji img, body .form .form-input.button-select-input .icon .emoji-mart-emoji img, body .form .form-input.toggle-switch-input .icon .emoji-mart-emoji img, body .form .form-input.toggle-checkbox-input .icon .emoji-mart-emoji img {
  padding: 3px;
  height: auto !important;
}
body .form .form-input.text-input .icon-right, body .form .form-input.number-input .icon-right, body .form .form-input.textarea-input .icon-right, body .form .form-input.select-input .icon-right, body .form .form-input.combo-select-input .icon-right, body .form .form-input.button-select-input .icon-right, body .form .form-input.toggle-switch-input .icon-right, body .form .form-input.toggle-checkbox-input .icon-right {
  position: absolute;
  z-index: 10 !important;
  top: 30px;
  right: 7px;
}
body .form .form-input.text-input .icon-right ~ .input, body .form .form-input.number-input .icon-right ~ .input, body .form .form-input.textarea-input .icon-right ~ .input, body .form .form-input.select-input .icon-right ~ .input, body .form .form-input.combo-select-input .icon-right ~ .input, body .form .form-input.button-select-input .icon-right ~ .input, body .form .form-input.toggle-switch-input .icon-right ~ .input, body .form .form-input.toggle-checkbox-input .icon-right ~ .input {
  padding-right: 45px;
}
body .form .form-input.text-input .icon-left, body .form .form-input.number-input .icon-left, body .form .form-input.textarea-input .icon-left, body .form .form-input.select-input .icon-left, body .form .form-input.combo-select-input .icon-left, body .form .form-input.button-select-input .icon-left, body .form .form-input.toggle-switch-input .icon-left, body .form .form-input.toggle-checkbox-input .icon-left {
  position: absolute;
  z-index: 10 !important;
  top: 7px;
  left: 7px;
}
body .form .form-input.text-input .icon-left ~ .label, body .form .form-input.number-input .icon-left ~ .label, body .form .form-input.textarea-input .icon-left ~ .label, body .form .form-input.select-input .icon-left ~ .label, body .form .form-input.combo-select-input .icon-left ~ .label, body .form .form-input.button-select-input .icon-left ~ .label, body .form .form-input.toggle-switch-input .icon-left ~ .label, body .form .form-input.toggle-checkbox-input .icon-left ~ .label {
  top: 30px;
}
body .form .form-input.text-input .icon-left ~ .input, body .form .form-input.number-input .icon-left ~ .input, body .form .form-input.textarea-input .icon-left ~ .input, body .form .form-input.select-input .icon-left ~ .input, body .form .form-input.combo-select-input .icon-left ~ .input, body .form .form-input.button-select-input .icon-left ~ .input, body .form .form-input.toggle-switch-input .icon-left ~ .input, body .form .form-input.toggle-checkbox-input .icon-left ~ .input {
  padding-left: 45px;
}
body .form .form-input.text-input .input, body .form .form-input.number-input .input, body .form .form-input.textarea-input .input {
  padding: 10px 13px;
  border-radius: 7px;
  background-color: transparent;
  border: 2px solid var(--textColor01);
  color: var(--textColor01);
  opacity: 0.6;
  outline: none;
  transition: 0.2s;
  z-index: 1 !important;
}
body .form .form-input.text-input .input:hover, body .form .form-input.number-input .input:hover, body .form .form-input.textarea-input .input:hover {
  opacity: 0.8;
}
body .form .form-input.text-input .input:focus, body .form .form-input.number-input .input:focus, body .form .form-input.textarea-input .input:focus {
  opacity: 1;
  border-color: #8c82ce;
}
body .form .form-input.text-input.dark .input, body .form .form-input.number-input.dark .input, body .form .form-input.textarea-input.dark .input {
  border-color: white;
  color: white;
}
body .form .form-input.text-input.dark .input:focus, body .form .form-input.number-input.dark .input:focus, body .form .form-input.textarea-input.dark .input:focus {
  border-color: #8c82ce;
}
body .form .form-input.text-input.dark .icon, body .form .form-input.number-input.dark .icon, body .form .form-input.textarea-input.dark .icon {
  color: white;
}
body .form .form-input.toggle-checkbox-input {
  margin-top: 3px;
}
body .form .form-input.number-input.disabled .arrows {
  pointer-events: none;
}
body .form .form-input.number-input .input {
  width: 100%;
}
body .form .form-input.number-input .arrows {
  color: var(--textColor01);
  font-size: 35px;
  cursor: pointer;
  display: flex;
  position: absolute;
  z-index: 10 !important;
  top: 30px;
  right: 7px;
  justify-content: center;
  align-items: center;
}
body .form .form-input.number-input .arrows ~ .input {
  padding-right: 80px;
}
body .form .form-input.number-input .arrows .left-arrow,
body .form .form-input.number-input .arrows .right-arrow {
  opacity: 0.5;
  transition: 0.5s;
}
body .form .form-input.number-input .arrows .left-arrow:hover,
body .form .form-input.number-input .arrows .right-arrow:hover {
  color: #8c82ce;
  opacity: 1;
  transform: rotate(180deg);
}
body .form .form-input.textarea-input .input {
  min-height: 100px;
  resize: vertical;
}
body .form .form-input.textarea-input .input::-webkit-scrollbar-corner, body .form .form-input.textarea-input .input::-webkit-resizer {
  height: 20px;
  width: 20px;
  border-radius: 5px 0px 2px 0px;
  background-color: var(--textColor01);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
body .form .form-input.textarea-input .input::-webkit-scrollbar {
  width: 10px !important;
}
body .form .form-input.textarea-input .input:focus::-webkit-resizer {
  background-color: #8c82ce;
}
body .form .form-input.textarea-input.small .input {
  min-height: 48px;
  height: 48px;
}
body .form .form-input.textarea-input.error .input::-webkit-resizer {
  background-color: #f04747;
}
body .form .form-input.button-select-input .input {
  display: flex;
  flex-wrap: wrap;
}
body .form .form-input.button-select-input .input button .button-icon,
body .form .form-input.button-select-input .input button .button-text {
  padding: 1px 7px;
}
body .form .form-input.button-select-input .input button .right {
  padding-left: 0 !important;
}
body .form .form-input.button-select-input .input button .left {
  padding-right: 0 !important;
}
body .form .form-input.color-picker .main {
  width: 100%;
  background-color: var(--backgroundColor01);
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}
body .form .form-input.color-picker .main .color-box {
  border-radius: 7px;
}
body .form .form-input.color-picker .main .color-hex {
  border-radius: 7px;
  padding: 5px 20px;
  margin-right: 3px;
  background-color: var(--backgroundColor02);
}
body .form .form-input.premium .input {
  opacity: 1;
  animation: premium-border 2s infinite alternate;
}
@keyframes premium-border {
  0% {
    color: #fa8e31;
    border-color: #fa8e31;
  }
  100% {
    color: #ffd04f;
    border-color: #ffd04f;
  }
}
body .text-form {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: relative;
  height: 40px;
}
body .text-form.shake .form-input {
  border-color: #ed4245 !important;
}
body .text-form .icon {
  color: var(--textColor01);
  height: 40px;
  font-size: 35px;
}
body .text-form .icon-left ~ .form-input {
  left: 40px;
  width: calc(100% - 40px);
}
body .text-form .icon-left ~ .form-label {
  left: calc(1rem + 40px);
}
body .text-form .form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-family: inherit;
  font-size: 18px;
  background: none;
  border: 2px solid var(--backgroundColor04);
  padding: 10px;
  outline: none;
  color: var(--textColor01);
  transition: 0.3s;
}
body .text-form .form-input.premium {
  border-color: #df9d23 !important;
}
body .text-form .form-input.plane {
  color: #ffffff;
  border: none;
  background-color: #1a202b;
  border-radius: 4px;
}
body .text-form .form-input.plane:focus {
  box-shadow: none;
}
body .text-form .form-input.lock {
  pointer-events: none;
  opacity: 0.7;
}
body .text-form .form-input:hover {
  border-color: #8c82ce;
}
body .text-form .form-input:focus {
  border-color: rgb(255, 244, 142);
  box-shadow: 0px 0px 3px rgb(255, 241, 115);
}
body .text-form .form-input:not(:-moz-placeholder-shown).form-input:not(:focus) ~ .form-label {
  top: -0.75rem;
  font-size: 14px;
  background-color: var(--backgroundColor03);
  padding: 0 10px;
  opacity: 1;
  line-height: 14px;
}
body .text-form .form-input:focus ~ .form-label, body .text-form .form-input:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
  top: -0.75rem;
  font-size: 14px;
  background-color: var(--backgroundColor03);
  padding: 0 10px;
  opacity: 1;
  line-height: 14px;
}
body .text-form .form-label {
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  color: var(--textColor01);
  cursor: text;
  transition: 0.3s;
  opacity: 0.5;
}
body .text-form.bg-2 .form-label {
  background-color: var(--backgroundColor02) !important;
}
body .text-form.disabled {
  pointer-events: none;
  opacity: 0.5;
}
body .textarea-form {
  height: auto;
}
body .textarea-form .form-input {
  position: initial;
  min-height: 100px;
  padding: 4px 10px;
}
body .textarea-form.small .form-input {
  height: 40px;
  min-height: 40px;
}
body .select-box-form {
  width: 100%;
}
body .select-menu-form {
  position: relative;
  width: 100%;
}
body .select-menu-form.disabled {
  pointer-events: none;
  opacity: 0.5;
}
body .select-menu-form.bg-2 .form-label {
  background-color: var(--backgroundColor02) !important;
}
body .select-menu-form .form-label {
  position: absolute;
  left: 1rem;
  color: var(--textColor01);
  top: -0.75rem;
  font-size: 14px;
  background-color: var(--backgroundColor03);
  padding: 0 10px;
  opacity: 1;
  line-height: 14px;
}
body .react-datetime-picker {
  width: 100%;
  transition: 0.3s;
}
body .react-datetime-picker .react-date-picker__calendar--open,
body .react-datetime-picker .react-date-picker__calendar--closed {
  position: absolute !important;
}
body .react-datetime-picker .react-datetime-picker__calendar-button,
body .react-datetime-picker .react-datetime-picker__clear-button {
  transition: 0.1s;
}
body .react-datetime-picker .react-datetime-picker__calendar-button:hover {
  color: #8c82ce;
}
body .react-datetime-picker .react-datetime-picker__clear-button:hover {
  color: var(--textColorRed01);
}
body .react-datetime-picker .react-datetime-picker__wrapper {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  min-height: 48px;
  border-radius: 7px;
  font-family: inherit;
  font-size: 18px;
  background: none;
  border: 2px solid var(--backgroundColor04);
  color: var(--textColor01);
  transition: 0.3s;
  opacity: 0.6;
}
body .react-datetime-picker .react-datetime-picker__wrapper:hover {
  border-color: var(--textColor01);
  opacity: 0.8;
}
body .react-datetime-picker .react-datetime-picker__wrapper .react-datetime-picker__inputGroup {
  margin-left: 10px;
  align-items: center;
  display: flex;
}
body .react-datetime-picker .react-datetime-picker__wrapper .react-datetime-picker__inputGroup .react-datetime-picker__inputGroup__input {
  border-radius: 8px;
}
body .react-datetime-picker .react-datetime-picker__wrapper .react-datetime-picker__inputGroup .react-datetime-picker__inputGroup__amPm,
body .react-datetime-picker .react-datetime-picker__wrapper .react-datetime-picker__inputGroup .react-datetime-picker__inputGroup__month {
  width: -moz-fit-content;
  width: fit-content;
  -webkit-appearance: none;
  border: 1px solid rgba(170, 170, 170, 0);
}
body .react-datetime-picker .react-datetime-picker__wrapper .react-datetime-picker__inputGroup .react-datetime-picker__inputGroup__amPm option,
body .react-datetime-picker .react-datetime-picker__wrapper .react-datetime-picker__inputGroup .react-datetime-picker__inputGroup__month option {
  background-color: var(--backgroundColor03);
}
body .react-datetime-picker .react-calendar {
  background-color: var(--backgroundColor02) !important;
  border: 2px solid var(--backgroundColor04);
  border-radius: 8px;
  margin: 0.5rem;
  color: var(--textColor01);
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
body .react-datetime-picker .react-calendar .react-calendar__navigation {
  margin: 5px;
}
body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__label,
body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
  border-radius: 8px;
  margin: 0 2px;
  padding: 3px;
  font-family: "Archivo Black", sans-serif;
}
body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__label:disabled, body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__label:focus, body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover,
body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:disabled,
body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:focus,
body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:hover {
  background-color: var(--backgroundColor01) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
body .react-datetime-picker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
  font-size: 25px;
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer {
  margin: 5px;
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  font-family: "Archivo Black", sans-serif;
  text-decoration: none;
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__year-view__months .react-calendar__year-view__months__month,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__decade-view__years .react-calendar__decade-view__years__year,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__century-view__decades .react-calendar__century-view__decades__decade,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__month-view__days__day {
  border-radius: 8px;
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__year-view__months .react-calendar__year-view__months__month:focus, body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__year-view__months .react-calendar__year-view__months__month:hover,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__decade-view__years .react-calendar__decade-view__years__year:focus,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__decade-view__years .react-calendar__decade-view__years__year:hover,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__century-view__decades .react-calendar__century-view__decades__decade:focus,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__century-view__decades .react-calendar__century-view__decades__decade:hover,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__month-view__days__day:focus,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__month-view__days__day:hover {
  background-color: var(--backgroundColor01) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__month-view__days__day--neighboringMonth {
  color: var(--backgroundColor05);
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__month-view__days__day--weekend {
  color: var(--textColorRed01);
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__tile--now {
  background-color: #43b581 !important;
}
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__tile--hasActive,
body .react-datetime-picker .react-calendar .react-calendar__viewContainer .react-calendar__tile--active {
  background-color: #5865f2 !important;
}
body .grid-card-menu {
  display: grid;
  grid-template-columns: 350px;
  gap: 25px;
  row-gap: 80px;
  justify-content: center;
}
@media (min-width: 50em) {
  body .grid-card-menu {
    grid-template-columns: repeat(auto-fit, 350px);
  }
}
@media screen and (max-width: 920px) {
  body .grid-card-menu {
    row-gap: 160px !important;
  }
  body .grid-card-menu .card-menu {
    height: 350px !important;
  }
  body .grid-card-menu .card-menu .image-box {
    width: 195px !important;
    height: 195px !important;
    border-radius: 20px !important;
  }
  body .grid-card-menu .card-menu .content .details {
    transform: translateY(25px) !important;
  }
}
body .grid-card-menu .card-menu-size {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
body .grid-card-menu .card-menu {
  position: relative;
  width: 350px;
  height: 160px;
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  background-color: var(--backgroundColor02);
}
body .grid-card-menu .card-menu .image-box {
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  border-radius: 25%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  transition: 0.5s;
  background-color: var(--guildCardIconBackground);
  pointer-events: none;
}
body .grid-card-menu .card-menu .image-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
body .grid-card-menu .card-menu .content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}
body .grid-card-menu .card-menu .content .details {
  padding: 40px;
  text-align: center;
  width: 100%;
  transition: 0.5s;
  transform: translateY(155px);
  color: var(--textColor01);
}
body .grid-card-menu .card-menu .content .details .data {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
body .grid-card-menu .card-menu .content .details .data h1 {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 600;
}
body .grid-card-menu .card-menu .content .details .data h1 span {
  font-size: 0.9rem;
  font-weight: 400;
  opacity: 0.9;
}
body .grid-card-menu .card-menu:hover {
  height: 350px;
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.3);
}
body .grid-card-menu .card-menu:hover .image-box {
  width: 195px;
  height: 195px;
  border-radius: 20px;
}
body .grid-card-menu .card-menu:hover .content .details {
  transform: translateY(25px);
}
body .grid-card-menu .card-menu-add-bot {
  position: relative;
  width: 350px;
  height: 205px;
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  background-color: var(--backgroundColor02);
  transition: 0.5s;
}
body .grid-card-menu .card-menu-add-bot .image-box {
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  border-radius: 25%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  background-color: var(--guildCardIconBackground);
  transition: 0.5s;
}
body .grid-card-menu .card-menu-add-bot .image-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: grayscale(100%);
  transition: 0.5s;
}
body .grid-card-menu .card-menu-add-bot .content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}
body .grid-card-menu .card-menu-add-bot .content .details {
  padding: 40px;
  text-align: center;
  width: 100%;
  transition: 0.5s;
  transform: translateY(25px);
  color: var(--textColor01);
}
body .grid-card-menu .card-menu-add-bot:hover {
  transform: translateY(-10px);
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.3);
}
body .grid-card-menu .card-menu-add-bot:hover .image-box img {
  filter: grayscale(0%);
}
body .grid-card-menu .guild-name {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4rem;
}
body .grid-card-menu .guild-name span {
  font-weight: 400;
  font-size: 1.2rem;
  opacity: 0.8;
}
body .guild-stats-card-grid {
  display: grid;
  gap: 2rem;
  margin: 2rem 1rem;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 1620px) {
  body .guild-stats-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 920px) {
  body .guild-stats-card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
body .guild-stats-card-grid .stat-card {
  background-color: var(--backgroundColor03);
  border-radius: 10px;
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  padding: 1rem;
}
body .guild-stats-card-grid .stat-card .title {
  font-size: 23px;
  font-family: "Archivo Black", sans-serif;
  margin-bottom: 15px;
}
body .guild-stats-card-grid .stat-card .value {
  font-size: 45px;
  font-family: "Bungee", sans-serif;
  line-height: 3rem;
  margin-bottom: 20px;
}
body .guild-stats-card-grid .stat-card .today {
  font-size: 18px;
  font-weight: 300;
}
body .guild-stats-card-grid .stat-card .graph {
  width: 100%;
  height: 285px;
  border-radius: 10px;
}
body .guild-stats-card-grid .stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 10px 20px 35px rgba(0, 0, 0, 0.4);
}
body .guild-stats-graph-card-grid {
  display: grid;
  gap: 2rem;
  margin: 0 1rem;
  margin-bottom: 3rem;
}
@media (min-width: 1020px) {
  body .guild-stats-graph-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 820px) {
  body .guild-stats-graph-card-grid .grid-col-span-2 {
    grid-column: span 2;
  }
}
body .guild-stats-graph-card-grid .stat-card {
  background-color: var(--backgroundColor03);
  height: 350px;
  border-radius: 10px;
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  padding: 1rem;
}
body .guild-stats-graph-card-grid .stat-card .title {
  font-size: 23px;
  font-family: "Archivo Black", sans-serif;
  margin-bottom: 10px;
}
body .guild-stats-graph-card-grid .stat-card .graph {
  width: 100%;
  height: 285px;
  border-radius: 10px;
}
body .guild-stats-graph-card-grid .stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 10px 20px 35px rgba(0, 0, 0, 0.4);
}
body .guild-setting-grid .grid01 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin: auto;
}
@media screen and (max-width: 1320px) {
  body .guild-setting-grid .grid01 {
    grid-template-columns: repeat(1, 1fr);
  }
}
body .guild-setting-grid .grid02 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: min-content;
  gap: 20px;
}
body .guild-setting-grid .card {
  min-height: 0 !important;
  background-color: var(--backgroundColor02);
  border-radius: 10px;
  padding: 1rem;
}
body .guild-setting-grid .card .change-card {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
body .guild-setting-grid .card .change-card .main {
  position: relative;
  padding: 15px;
  background-color: var(--backgroundColor01);
  border-radius: 10px;
  transition: 0.3s;
  order: 0;
}
body .guild-setting-grid .card .change-card .main .message {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--backgroundColor02);
}
body .guild-setting-grid .card .change-card .main .message .message-text {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
body .guild-setting-grid .card .change-card .main .message .message-text .time {
  font-size: 15px;
  height: 30px;
  background-color: var(--backgroundColor05);
}
body .guild-setting-grid .card .change-card .main .message .message-text .message-changes {
  padding: 10px 10px;
}
body .guild-setting-grid .card .change-card .main .message .icon {
  width: 40px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
body .guild-setting-grid .card .change-card .main .id,
body .guild-setting-grid .card .change-card .main .time,
body .guild-setting-grid .card .change-card .main .username {
  background-color: var(--backgroundColor02);
  padding: 5px 10px;
  border-radius: 8px;
}
body .guild-setting-grid .card .change-card .main:hover {
  transform: translateY(-5px);
  box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
body .guild-setting-grid .card .change-card .changes {
  background-color: var(--backgroundColor01);
  border-radius: 10px;
  transition: all 0.5s;
  overflow-x: hidden;
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  padding: 0 15px;
  overflow-y: scroll;
  order: 1;
}
body .guild-setting-grid .card .change-card .changes.active {
  margin-top: 10px;
  padding: 10px 15px;
  max-height: 500px;
  opacity: 1;
}
body .guild-setting-grid .card .change-card .changes.active ~ .main .message .icon {
  transform: rotate(180deg);
}
body .guild-setting-grid .card .change-card .changes .change {
  background-color: var(--backgroundColor02);
  padding: 7px 10px;
  margin: 5px 0;
  font-size: 17px;
  border-radius: 8px;
}
body .guild-setting-grid .card .change-card .changes .change .change-index {
  line-height: 0;
  font-size: 15px;
  font-family: "Bungee", sans-serif;
  color: #fa8e31;
}
body .guild-setting-grid .card .change-card .changes .change .change-name {
  line-height: 0;
  font-size: 15px;
  font-family: "Bungee", sans-serif;
  color: #8c82ce;
}
body .guild-setting-grid .card .change-card .changes .change .change-value {
  font-family: "Archivo Black", sans-serif;
  font-size: 15px;
}
body .grid-commands {
  display: grid;
  gap: 1rem;
}
body .grid-commands .command-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--backgroundColor03);
  color: var(--textColor01);
  min-height: 70px;
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
body .grid-commands .command-card .name {
  margin-bottom: 5px;
  font-family: "Archivo Black", sans-serif;
  font-size: 20px;
  transition: 0.3s;
}
body .grid-commands .command-card .description {
  font-weight: 400;
  font-size: 18px;
}
body .grid-commands .command-card:hover {
  transform: translateY(-10px);
  box-shadow: 5px 15px 25px rgba(0, 0, 0, 0.4);
}
body .grid-commands .command-card:hover .name {
  color: #8c82ce;
}
body .module-list-grid {
  display: grid;
  grid-template-columns: 350px;
  gap: 25px;
  margin: 1rem 0.5rem;
  margin-bottom: 2.5rem;
}
@media (min-width: 50em) {
  body .module-list-grid {
    grid-template-columns: repeat(auto-fit, 350px);
  }
}
body .module-list-grid .module {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: var(--backgroundColor03);
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  width: 350px;
  min-height: 130px;
  padding: 0.5rem 1rem;
  position: relative;
}
body .module-list-grid .module:hover {
  transform: translateY(-5px);
  box-shadow: 10px 20px 35px rgba(0, 0, 0, 0.4);
}
body .module-list-grid .module .details {
  cursor: pointer;
  font-size: 14.5px !important;
  margin-bottom: 10px;
}
body .module-list-grid .module .switch {
  position: absolute;
  width: 74px;
  top: 2px;
  right: 10px;
}
body .guild-setting-command-grid {
  display: grid;
  grid-template-columns: 350px;
  gap: 25px;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
@media (min-width: 50em) {
  body .guild-setting-command-grid {
    grid-template-columns: repeat(auto-fit, 350px);
  }
}
body .guild-setting-command-grid .guild-setting-command-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: var(--backgroundColor02);
  transition: 0.3s;
  width: 350px;
  padding: 0.2rem 0.8rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
body .guild-setting-command-grid .guild-setting-command-card.break {
  grid-column-end: span 2;
}
body .guild-setting-command-grid .guild-setting-command-card:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
body .guild-setting-command-grid .guild-setting-command-card .click-area {
  z-index: 1;
}
body .guild-setting-command-grid .guild-setting-command-card .switch {
  z-index: 2;
}
body .self-roles-role-grid {
  display: grid;
  gap: 10px;
}
body .self-roles-role-grid .card {
  display: flex;
  background-color: var(--backgroundColor01);
  padding: 10px;
  border-radius: 10px;
  justify-content: space-between;
}
body .self-roles-role-grid .card .card-detail {
  align-items: center;
}
body .self-roles-role-grid .card .button {
  display: flex;
  justify-content: end;
}
body .confirm_payment .card-container {
  padding: 15px;
  background-color: var(--backgroundColor02);
  border-radius: 10px;
}
body .confirm_payment .product {
  padding: 10px 20px !important;
}
body .confirm_payment .product .product-name {
  font-family: "Archivo Black", sans-serif;
  font-size: 1.2rem;
  line-height: 30px;
}
body .confirm_payment .product .product-price {
  font-size: 18px;
  opacity: 0.7;
}
body .payment_list {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
}
@media screen and (max-width: 610px) {
  body .payment_list .card {
    flex-direction: column;
    gap: 10px;
  }
  body .payment_list .card.add-button, body .payment_list .card.add-button-billing {
    flex-direction: row;
  }
}
@media screen and (max-width: 450px) {
  body .payment_list .card {
    padding: 20px 10px;
  }
  body .payment_list .card .primary {
    flex-direction: column;
    gap: 0 !important;
  }
}
body .payment_list .card {
  padding: 15px;
  background-color: var(--backgroundColor01);
  border-radius: 5px;
}
body .payment_list .card.add-button, body .payment_list .card.add-button-billing {
  padding: 3px;
  background-color: #3ba55d;
  transition: 0.3s;
  cursor: pointer;
}
body .payment_list .card.add-button h1, body .payment_list .card.add-button-billing h1 {
  color: #ffffff;
  transition: 0.3s;
}
body .payment_list .card.add-button:hover, body .payment_list .card.add-button-billing:hover {
  outline: 3px solid;
  background-color: transparent;
  outline-color: #3ba55d;
}
body .payment_list .card.add-button:hover h1, body .payment_list .card.add-button-billing:hover h1 {
  color: var(--textColor01);
}
body .payment_list .card.add-button-billing {
  width: -moz-fit-content;
  width: fit-content;
  justify-self: right;
  padding: 3px 10px;
}
body .payment_list .card.add-button-billing h1 {
  font-size: 1.1rem !important;
}
body .payment_list .card .main {
  display: flex;
  flex-direction: column;
}
body .payment_list .card .main .primary {
  display: flex;
  gap: 20px;
  align-items: center;
}
body .payment_list .card .main .primary .brand {
  font-family: "Bungee", sans-serif;
  font-size: 1.3rem;
  line-height: 30px;
}
body .payment_list .card .main .primary .card-end {
  font-family: "Archivo Black", sans-serif;
  font-size: 1.1rem;
  line-height: 30px;
}
body .payment_list .card .main .secondary .name {
  opacity: 0.5;
}
body .pricing-table-container {
  color: var(--textColor01);
}
body .pricing-table-container .pricing-header {
  display: grid;
  place-items: center;
}
body .pricing-table-container .pricing-header .plans-switch-container {
  position: relative;
  display: inline-block;
}
body .pricing-table-container .pricing-header .plans-switch-container span {
  position: absolute;
  pointer-events: none;
  top: 5px;
  width: 110px;
  height: 35px;
  font-family: "Archivo Black", sans-serif;
  line-height: 35px;
  text-align: center;
  transition: 0.3s;
}
body .pricing-table-container .pricing-header .plans-switch-container .plans-switch {
  position: relative;
  width: 250px;
  height: 45px;
  background-color: var(--backgroundColor02);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}
body .pricing-table-container .pricing-header .plans-switch-container .plans-switch::before {
  content: "";
  position: absolute;
  height: 35px;
  width: 110px;
  background-color: #776aca;
  border-radius: 8px;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.3s;
}
body .pricing-table-container .pricing-header .plans-switch-container .plans-switch:checked::before {
  left: 135px;
}
body .pricing-table-container .pricing-header .plans-switch-container .plans-switch:checked ~ .monthly {
  color: var(--textColor01);
}
body .pricing-table-container .pricing-header .plans-switch-container .plans-switch:checked ~ .yearly {
  color: #ffffff;
}
body .pricing-table-container .pricing-header .plans-switch-container .monthly {
  left: 5px;
  color: #ffffff;
}
body .pricing-table-container .pricing-header .plans-switch-container .yearly {
  right: 5px;
}
body .pricing-table-container .pricing-table {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
body .pricing-table-container .pricing-table .table {
  position: relative;
  background-color: var(--backgroundColor02);
  width: 300px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.3);
}
body .pricing-table-container .pricing-table .table.right {
  border-radius: 8px 0 0 8px;
}
body .pricing-table-container .pricing-table .table .save {
  position: absolute;
  top: -15px;
  left: -15px;
  right: 0;
  text-align: center;
  padding: 4px 10px;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
  background-color: #43b581;
  border-radius: 25px;
  transform: rotateZ(-5deg);
  font-family: "Archivo Black", sans-serif;
}
body .pricing-table-container .pricing-table .table h3 {
  font-size: 24px;
  font-family: "Bungee", sans-serif;
}
body .pricing-table-container .pricing-table .table .price-container .price {
  font-size: 40px;
  font-family: "Bungee", sans-serif;
  padding-right: 10px;
}
body .pricing-table-container .pricing-table .table .price-container .plan-duration {
  opacity: 0.7;
}
body .pricing-table-container .pricing-table .table .description {
  font-size: 14px;
  margin-bottom: 1rem;
}
body .pricing-table-container .pricing-table .table .features {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}
body .pricing-table-container .pricing-table .table .features li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 12px 0;
}
body .pricing-table-container .pricing-table .table .features li .icon {
  font-size: 1.3rem;
  color: #43b581;
}
body .pricing-table-container .pricing-table .best-value {
  border-radius: 16px;
  width: 330px;
  background-color: #171c26;
  color: #ffffff;
}
body .pricing-table-container .pricing-table .best-value .value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 8px;
  border-radius: 16px 16px 0 0;
  background-color: #8c82ce;
  font-family: "Archivo Black", sans-serif;
}
body .pricing-table-container .pricing-table .best-value .content {
  padding-top: 42px;
}
body .pricing-table-container .pricing-table .best-value .content .price-container .price {
  font-size: 45px;
  color: #ffec81;
}
@media screen and (max-width: 720px) {
  body .pricing-table-container .pricing-table {
    flex-direction: column;
    gap: 25px;
  }
  body .pricing-table-container .pricing-table .table.right {
    border-radius: 8px;
  }
}
body .background-card {
  background-color: var(--backgroundColor03);
}
body .home-info-panel {
  margin: 3rem 0;
}
body .home-info-panel img.hover {
  animation-play-state: running;
}
body .home-info-panel img.hover:hover {
  animation-play-state: paused;
}
body .home-info-panel .images {
  z-index: 1 !important;
}
body .home-info-panel .about {
  z-index: 2 !important;
}
body .home-info-panel .about .title {
  color: var(--textColor01);
  font-family: "Archivo Black", sans-serif;
  font-size: 4rem;
}
body .home-info-panel .about .description {
  max-width: 600px;
  color: white;
  background-color: #141820;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 1.2rem;
}
@media screen and (max-width: 1300px) {
  body .home-info-panel {
    height: 400px;
  }
}
@media screen and (max-width: 1140px) {
  body .home-info-panel {
    height: -moz-fit-content;
    height: fit-content;
  }
  body .home-info-panel .icons {
    display: flex;
    margin-top: 10px;
  }
  body .home-info-panel .icons img {
    position: sticky;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
  }
  body .home-info-panel.main .about, body .home-info-panel.auto-role .about, body .home-info-panel.self-role .about, body .home-info-panel.leveling .about, body .home-info-panel.greeter .about, body .home-info-panel.auto-publish .about {
    position: unset;
  }
  body .home-info-panel.main .about .title, body .home-info-panel.auto-role .about .title, body .home-info-panel.self-role .about .title, body .home-info-panel.leveling .about .title, body .home-info-panel.greeter .about .title, body .home-info-panel.auto-publish .about .title {
    text-align: center !important;
  }
  body .home-info-panel.main .about .description, body .home-info-panel.auto-role .about .description, body .home-info-panel.self-role .about .description, body .home-info-panel.leveling .about .description, body .home-info-panel.greeter .about .description, body .home-info-panel.auto-publish .about .description {
    width: 100% !important;
    max-width: 100vw !important;
  }
  body .home-info-panel.self-role .images img {
    left: 0;
  }
  body .home-info-panel.self-role .images .img-01 {
    top: 20px;
  }
  body .home-info-panel.self-role .images .img-02 {
    top: 180px;
    z-index: 1;
    left: auto;
    right: 0;
  }
  body .home-info-panel.self-role .images .img-03 {
    top: 300px;
  }
  body .home-info-panel.greeter .images img {
    right: 0;
  }
  body .home-info-panel.greeter .images .img-01 {
    top: 20px;
  }
  body .home-info-panel.greeter .images .img-02 {
    top: 200px;
    left: 0;
  }
  body .home-info-panel.auto-role, body .home-info-panel.self-role, body .home-info-panel.leveling {
    margin-top: 10rem;
  }
}
@media screen and (max-width: 760px) {
  body .home-info-panel .icon {
    display: block;
  }
  body .home-info-panel.main .title {
    font-size: 40px;
  }
  body .home-info-panel.self-role {
    height: auto;
    margin-top: 0;
  }
  body .home-info-panel.self-role .images img {
    position: unset;
    left: 0;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  body .home-info-panel.self-role .images .img-02 {
    display: none;
  }
  body .home-info-panel.greeter {
    height: auto;
  }
  body .home-info-panel.greeter .images img {
    position: unset;
    left: 0;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  body .home-info-panel.leveling {
    margin-top: 0;
  }
  body .home-info-panel.leveling .images img {
    position: unset;
    left: 0;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}
body .greeter-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
}
body .greeter-grid .greeter-grid-in {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
@media screen and (max-width: 1400px) {
  body .greeter-grid {
    grid-template-columns: 1fr;
  }
}
body .greeter-variables {
  z-index: 103;
  position: fixed;
  bottom: 70px;
  right: 0;
  width: 100%;
  padding: 1rem 2rem;
  transition: 0.5s;
}
body .greeter-variables .bar {
  display: flex;
  transition: 0.5s;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--backgroundColor01);
  background-color: rgba(20, 24, 32, 0.9);
  gap: 6px;
  align-items: center;
  padding: 0 12px;
  overflow-x: auto;
  overflow-y: hidden;
}
body .greeter-variables .bar::-webkit-scrollbar {
  height: 5px;
}
body .greeter-variables .bar button {
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  transition: 0.2s;
  white-space: nowrap;
}
body .greeter-variables .bar button:hover {
  transform: scale(1.1);
}
body .greeter-variables.closed {
  bottom: -170px;
}
body .greeter-variables.closed button {
  pointer-events: none;
}
body .core-message-list {
  display: grid;
  list-style: none;
  padding-left: 0;
}
body .core-message-list li {
  padding: 0.5em;
  margin: 1em;
}
body .core-message-list .message {
  padding: 1rem 2rem;
}
body .core-message-list .message-new {
  padding: 1rem;
}
body .core-message-list .core-message-embed-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
body .core-message-list .core-message-embed-list .embed {
  position: relative;
  width: 250px;
  min-height: 120px;
  border-radius: 5px;
}
body .core-message-list .core-message-embed-list .embed:hover .delete-button {
  display: inherit;
}
body .core-message-list .core-message-component-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
body .core-message-list .core-message-component-list .component {
  position: relative;
  width: 250px;
  height: 60px;
  border-radius: 5px;
}
body .core-message-list .core-message-component-list .component .button-name {
  z-index: 1;
}
body .core-message-list .core-message-component-list .component button {
  z-index: 2;
}
body .core-message-list .core-message-component-list .component:hover .delete-button {
  display: inherit;
}
body .core-message-list .delete-button {
  display: none;
  position: absolute;
  bottom: -8px;
  right: -8px;
  background-color: #f04747;
  font-size: 25px;
  border-radius: 10px;
  padding: 1px;
  color: white;
}
body .logging-grid {
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 1400px) {
  body .logging-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 900px) {
  body .logging-grid {
    grid-template-columns: 1fr;
  }
}
body .grid-col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 1400px) {
  body .grid-col-3 {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 900px) {
  body .grid-col-3 {
    grid-template-columns: 1fr;
  }
}
body .grid-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
body .grid-col-2.gap-select-no-label, body .grid-col-2.gap-select {
  gap: 0.5rem;
  margin-top: 0.5rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
body .grid-col-2.mt-0 {
  margin-top: 0rem !important;
}
@media screen and (max-width: 900px) {
  body .grid-col-2 {
    grid-template-columns: 1fr;
  }
}
body .grid-col-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
body .grid-col-4.gap-select-no-label, body .grid-col-4.gap-select {
  gap: 0.5rem;
  margin-top: 0.5rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
body .grid-col-4.mt-0 {
  margin-top: 0rem !important;
}
@media screen and (max-width: 900px) {
  body .grid-col-4 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 700px) {
  body .grid-col-4 {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 500px) {
  body .grid-col-4 {
    grid-template-columns: 1fr;
  }
}
body .page-body {
  min-height: 100vh;
  display: grid;
  grid: auto/1fr;
  overflow-x: hidden !important;
}
body .content-area {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
body .sidebar {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  transition: 0.5s;
  background-color: #171c26;
}
body .sidebar ~ .content-area {
  left: 300px;
  top: 60px;
  width: calc(100% - 300px);
}
body .sidebar ~ .navbar .logo {
  opacity: 0;
}
body .sidebar ~ .save-bar,
body .sidebar ~ .content-area .greeter-variables {
  width: calc(100% - 300px);
}
body .sidebar ~ .footer {
  left: 300px;
  width: calc(100% - 300px);
  border-radius: 0 20px 0 0;
}
body .sidebar.closed {
  width: 88px;
}
body .sidebar.closed .right-header {
  left: 88px;
  width: calc(100vw - 88px);
}
body .sidebar.closed .logo01 {
  display: none !important;
}
body .sidebar.closed .logo02 {
  display: block !important;
}
body .sidebar.closed .sidebar-text {
  opacity: 0;
}
body .sidebar.closed header .sidebar-toggle {
  transform: translateY(-50%);
}
body .sidebar.closed .menu .menu-text {
  pointer-events: none;
}
body .sidebar.closed .menu.closed .menu-links {
  max-height: 100%;
}
body .sidebar.closed ~ .content-area {
  left: 88px;
  width: calc(100% - 88px);
}
body .sidebar.closed ~ .save-bar,
body .sidebar.closed ~ .content-area .greeter-variables {
  width: calc(100% - 88px);
}
body .sidebar.closed ~ .footer {
  left: 88px;
  width: calc(100% - 88px);
}
body .sidebar .sidebar-text {
  font-weight: 500;
  transition: 0.1s;
  white-space: nowrap;
}
body .sidebar .image-text {
  padding: 5px 0;
}
body .sidebar .image-text img {
  height: 40px;
}
body .sidebar .image-text .logo01 {
  display: block;
}
body .sidebar .image-text .logo02 {
  display: none;
}
body .sidebar .right-header {
  position: absolute;
  left: 300px;
  width: calc(100vw - 300px);
  height: 60px;
  background-color: #171c26;
  transition: 0.5s;
}
body .sidebar .right-header.dashboard {
  display: flex;
  justify-content: right;
  padding-right: 120px;
}
body .sidebar .left-header {
  position: relative;
  padding: 5px 14px;
}
body .sidebar .left-header .image {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  overflow-x: hidden;
}
body .sidebar .left-header .sidebar-toggle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: -25px;
  height: 25px;
  width: 25px;
  transform: translateY(-50%) rotate(180deg);
  background-color: #7468c4;
  color: #ffffff;
  border-radius: 25%;
  padding: 5px;
  transition: 0.3s;
}
body .sidebar .left-header .sidebar-toggle:hover {
  cursor: pointer;
  background-color: #8c82ce;
  right: -30px;
  height: 35px;
  width: 35px;
}
body .sidebar .menu-links {
  overflow: hidden;
  transition: 0.1s;
}
body .sidebar .menu.closed .menu-text .nav-icon {
  transform: rotate(180deg) !important;
}
body .sidebar .menu.closed .menu-links {
  max-height: 0px;
}
body .sidebar .menu-bar {
  overflow-y: auto;
  max-height: calc(100vh - 70px);
  padding: 5px 14px;
}
body .sidebar .menu-bar::-webkit-scrollbar {
  display: none;
}
body .sidebar .menu-text {
  margin-top: 10px;
  margin-bottom: -5px;
  margin-left: 1.5rem;
  color: #ffffff;
  font-size: 0.9rem;
  font-family: "Bungee", sans-serif;
  justify-content: space-between;
  overflow: hidden;
}
body .sidebar .menu-text:hover {
  cursor: pointer;
  color: #ffec81;
}
body .sidebar .menu-text:hover .nav-icon {
  color: #ffec81;
}
body .sidebar li {
  height: 40px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
}
body .sidebar li .nav-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.3s;
}
body .sidebar li .nav-button span {
  pointer-events: none;
}
body .sidebar li .nav-button.active {
  background-color: #6a5dc0;
  pointer-events: none;
}
body .sidebar li .nav-button:hover {
  background-color: rgba(106, 93, 192, 0.5);
  cursor: pointer;
}
body .sidebar li .nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
  transition: 0.3s;
}
body .sidebar li .tags {
  position: absolute;
  right: 0;
  display: flex;
}
body .sidebar li .tags .tag {
  display: none;
  margin-left: 0;
  padding: 3px 5px;
  border-radius: 5px;
}
body .sidebar li .tags .active {
  background-color: #43b581;
}
body .sidebar li .tags .updated {
  display: inherit;
  background-color: #8c82ce;
}
body .sidebar li .tags .new {
  display: inherit;
  text-align: center;
  font-family: "Bungee", sans-serif;
  background-color: #8c82ce;
}
body .sidebar li.nav-link.all-active .nav-icon, body .sidebar li.nav-link.active .nav-icon {
  color: #78cda6;
}
body .sidebar li.nav-link.all-active .tags .active, body .sidebar li.nav-link.active .tags .active {
  display: block;
}
body .sidebar li.nav-link .nav-text {
  font-size: 0.9rem;
}
body .sidebar li .nav-text,
body .sidebar li .nav-icon {
  color: #ffffff;
}
@media screen and (max-width: 1020px) {
  body .sidebar {
    width: 60%;
  }
  body .sidebar ~ .save-bar,
  body .sidebar ~ .content-area .greeter-variables {
    width: calc(100% - 88px);
    display: none;
  }
  body .sidebar ~ .content-area {
    left: 88px;
    top: 60px;
    width: calc(100% - 88px);
  }
  body .sidebar ~ .footer {
    left: 88px;
    width: calc(100% - 88px);
  }
  body .sidebar .cover-button {
    position: absolute;
    top: 0;
    left: 60vw;
    width: 40vw;
    height: 100vh;
  }
  body .sidebar .right-header.dashboard {
    display: none;
  }
  body .sidebar.closed .cover-button {
    display: none;
  }
  body .sidebar.closed .right-header.dashboard {
    display: flex;
  }
  body .sidebar.closed ~ .save-bar,
  body .sidebar.closed ~ .content-area .greeter-variables {
    display: block;
  }
}
@media screen and (max-width: 720px) {
  body .sidebar .right-header.dashboard {
    padding-right: 80px;
  }
}
@media screen and (max-width: 620px) {
  body .sidebar {
    width: 100%;
  }
  body .sidebar ~ .save-bar,
  body .sidebar ~ .content-area .greeter-variables {
    width: 100%;
    padding: 1rem 0.5rem;
  }
  body .sidebar ~ .content-area {
    left: 0;
    top: 60px;
    width: 100%;
  }
  body .sidebar ~ .footer {
    left: 0 !important;
    width: 100% !important;
    border-radius: 20px 20px 0 0;
  }
  body .sidebar .sidebar-toggle {
    width: 40px !important;
    height: 40px !important;
    transform: translateX(-250%) translateY(-50%) rotate(180deg) !important;
  }
  body .sidebar .right-header.dashboard {
    justify-content: center;
    padding: 0 60px 0 50px;
  }
  body .sidebar .right-header.dashboard .search {
    display: none;
  }
  body .sidebar.closed {
    left: -88px;
  }
  body .sidebar.closed ~ .save-bar,
  body .sidebar.closed ~ .content-area .greeter-variables {
    width: 100%;
  }
  body .sidebar.closed ~ .content-area {
    left: 0;
    top: 60px;
    width: 100%;
  }
  body .sidebar.closed .right-header {
    left: 88px;
    width: 100vw;
  }
  body .sidebar.closed .sidebar-toggle {
    transform: translateX(50%) translateY(-50%) !important;
  }
}
body .global-navbar {
  position: relative;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 5px 10%;
  background-color: #171c26;
}
body .global-navbar ~ .content-area {
  top: 60px;
  height: calc(100% - 60px);
  margin-bottom: 60px;
}
body .global-navbar li,
body .global-navbar a,
body .global-navbar button {
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
}
body .global-navbar .logo {
  width: auto;
  height: 40px;
  cursor: pointer;
}
body .global-navbar .logo.full {
  display: block;
}
body .global-navbar .logo.icon {
  display: none;
}
body .global-navbar .nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s;
}
body .global-navbar .nav-links li {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 4px;
  border: 2px solid #171c26;
  cursor: pointer;
  transition: 0.3s;
}
body .global-navbar .nav-links li.disabled {
  opacity: 0.5;
  pointer-events: none;
}
body .global-navbar .nav-links li.premium {
  box-shadow: 0 0 10px rgba(255, 236, 129, 0.5);
  border-color: #ffec81;
  color: #ffec81;
}
body .global-navbar .nav-links li.premium:hover {
  border-color: #ffec81;
  box-shadow: 0 0 10px rgba(255, 236, 129, 0.9);
}
body .global-navbar .nav-links li:hover {
  border-color: #ceb9ff;
}
body .global-navbar .nav-links li.active {
  pointer-events: none;
  border-color: #43b581;
}
body .global-navbar .nav-links li.active.premium {
  box-shadow: 0 0 10px rgba(67, 181, 129, 0.5);
  color: #ffffff;
}
body .global-navbar .nav-close {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  position: absolute;
  top: 100%;
  left: 0;
  height: calc(100vh - 100%);
  width: 40vw;
  display: none;
  transition: 0.3s;
}
body .global-navbar .menu-button {
  position: absolute;
  left: 30px;
  color: #ffffff;
  font-size: 2.5rem;
  display: none;
}
body .global-navbar .menu-button .opened {
  display: none;
}
@media screen and (max-width: 1140px) {
  body .global-navbar {
    justify-content: left;
  }
  body .global-navbar .menu-button {
    display: block;
  }
  body .global-navbar .menu-button.opened ~ nav .nav-links {
    right: 0;
  }
  body .global-navbar .menu-button.opened ~ nav .nav-close {
    display: block;
  }
  body .global-navbar .menu-button.opened .closed {
    display: none;
  }
  body .global-navbar .menu-button.opened .opened {
    display: block;
  }
  body .global-navbar .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  body .global-navbar .nav-links {
    position: absolute;
    background-color: #171c26;
    top: 100%;
    right: -60vw;
    height: calc(100vh - 100%);
    width: 60vw;
    flex-direction: column;
    padding: 30px;
  }
  body .global-navbar .nav-links li {
    width: 100%;
    border-color: #2a3346;
  }
  body .global-navbar .nav-close {
    display: none;
  }
}
@media screen and (max-width: 580px) {
  body .global-navbar {
    justify-content: left;
  }
  body .global-navbar .logo.full {
    display: none;
  }
  body .global-navbar .logo.icon {
    display: block;
  }
  body .global-navbar .nav-links {
    right: -100vw;
    width: 100vw;
  }
  body .global-navbar .nav-close {
    display: none !important;
  }
}
body .footer {
  position: relative;
  width: 100%;
  background-color: #171c26;
  box-shadow: 0 15px 70px -8px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0 0;
  transition: 0.5s;
  margin-top: auto;
}
body .footer .footer-container {
  max-width: 1540px;
  padding: 30px 40px;
  margin: auto;
}
body .footer .content-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
body .footer .content-footer .profil .logo-area {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
body .footer .content-footer .profil .logo-area img {
  width: 40px;
  height: 40px;
}
body .footer .content-footer .profil .logo-area .logo-name {
  font-size: 1.35rem;
  font-family: "Bungee", sans-serif;
  margin: 0 1rem;
  color: #ffffff;
}
body .footer .content-footer .profil .logo-area .theme-toggle {
  opacity: 0;
  position: absolute;
}
body .footer .content-footer .profil .logo-area .theme-toggle:checked + .theme-toggle-label .ball {
  transform: translateX(24px);
}
body .footer .content-footer .profil .logo-area .theme-toggle-label {
  position: relative;
  display: flex;
  width: 50px;
  height: 26px;
  background-color: #000000;
  border-radius: 25px;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label .moon {
  color: #ffec81;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label .sun {
  color: #fa8e31;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label .ball {
  position: absolute;
  background-color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 25px;
  top: 2px;
  left: 2px;
  transition: 0.3s;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label:hover {
  cursor: pointer;
}
body .footer .content-footer .profil .description-area {
  max-width: 320px;
  margin-bottom: 1.25rem;
}
body .footer .content-footer .profil .description-area p {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  line-height: 2rem;
}
body .footer .content-footer .profil .social-media a {
  color: rgba(255, 255, 255, 0.75);
  margin-right: 22px;
  font-size: 24px;
  transition: 0.3s;
}
body .footer .content-footer .profil .social-media a:hover {
  color: #ceb9ff;
}
body .footer .content-footer .service-area {
  display: flex;
}
body .footer .content-footer .service-area .service-header {
  margin-right: 40px;
  list-style: none;
}
body .footer .content-footer .service-area .service-header .service-name {
  color: #ffffff;
  font-family: "Archivo Black", sans-serif;
}
body .footer .content-footer .service-area .service-header li {
  margin-bottom: 15px;
}
body .footer .content-footer .service-area .service-header li.disabled {
  opacity: 0.5;
  pointer-events: none;
}
body .footer .content-footer .service-area .service-header li a {
  color: rgba(255, 255, 255, 0.75);
  padding-bottom: 1px;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 400;
}
body .footer .content-footer .service-area .service-header li a:hover {
  color: #ceb9ff;
}
body .footer hr {
  height: 1px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 2rem;
  margin-bottom: 1rem;
}
body .footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}
body .footer .footer-bottom .copyright {
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
}
body .footer .footer-bottom .copyright span {
  margin-left: 8px;
  letter-spacing: 0.1rem;
}
body .footer .footer-bottom .tou a {
  color: rgba(255, 255, 255, 0.75);
  margin-left: 1.5rem;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 400;
}
body .footer .footer-bottom .tou a:hover {
  color: #ceb9ff;
}
@media screen and (max-width: 768px) {
  body .footer .content-footer {
    flex-wrap: wrap;
    justify-content: left;
  }
  body .footer .service-area {
    flex-wrap: wrap;
    justify-content: left;
  }
  body .footer .service-header {
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 40px;
  }
  body .footer .social-media {
    margin-bottom: 1rem;
  }
  body .footer .footer-bottom {
    flex-wrap: wrap;
    justify-content: center;
  }
  body .footer .footer-bottom .copyright {
    margin-bottom: 15px;
  }
}
body .save-bar {
  z-index: 102;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 1rem 2rem;
  transition: 0.5s;
}
body .save-bar .bar {
  display: flex;
  transition: 0.5s;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--backgroundColor01);
  background-color: rgba(20, 24, 32, 0.9);
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
body .save-bar .bar .save-bar-text {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 400;
}
body .save-bar .bar button {
  position: relative;
}
body .save-bar .bar button img {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: none;
}
body .save-bar .bar button.loading-button {
  pointer-events: none;
  background-color: #4f545c !important;
}
body .save-bar .bar button.loading-button img {
  display: block;
}
body .save-bar .bar button.loading-button span {
  opacity: 0;
}
@media screen and (max-width: 570px) {
  body .save-bar .bar {
    justify-content: center;
  }
  body .save-bar .bar .save-bar-text {
    display: none;
  }
}
body .save-bar.closed {
  bottom: -100px;
}
body .save-bar.closed ~ .content-area .greeter-variables {
  bottom: 0;
}
body .save-bar.closed ~ .content-area .greeter-variables.closed {
  bottom: -100px;
}
body .save-bar.closed button {
  pointer-events: none;
}
body .loading {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundColor01);
}
body .loading.active {
  display: flex;
}
body .loading.dim {
  background-color: rgba(0, 0, 0, 0.25);
}
body .loading img {
  width: 150px;
  height: auto;
  animation: float 6s ease-in-out infinite;
}
@keyframes float {
  0% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(30px);
  }
}
body .scroll-section.full-center {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
body .popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  height: 100vh;
  width: 100vw;
  z-index: 105;
}
body .popup .close-full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body .popup .close {
  position: fixed;
  display: flex;
  top: 5px;
  right: 13px;
  font-size: 2rem;
  width: auto;
  height: auto;
  background-color: #f04747;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 3px;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  z-index: 1;
}
body .popup .close:hover {
  transform: scale(115%) rotateZ(90deg);
}
body .popup .popup-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 15px;
  animation: fade_in 0.3s ease-in;
  overflow-y: auto;
}
body .popup .popup-container .popup-element {
  position: relative;
  max-width: 800px;
  width: 100%;
}
body .popup .popup-container .popup-element .popup-data {
  position: relative;
  padding: 32px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.7);
}
body .screen-cover {
  z-index: 105;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  animation: fade_out 0.5s ease-in;
  pointer-events: none;
  background-color: var(--backgroundColor01);
}
body .pagination {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
}
body .pagination ul {
  display: flex;
  background-color: var(--backgroundColor01);
  color: var(--textColor01);
  padding: 5px;
  border-radius: 15px;
}
body .pagination li {
  list-style: none;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Bungee", sans-serif;
}
body .pagination li.numb {
  height: 33px;
  width: 33px;
  border-radius: 10px;
  margin: 0 3px;
}
body .pagination li.dots {
  font-size: 20px;
  cursor: default;
}
body .pagination li.button {
  padding: 0 20px;
  display: inline-flex;
  margin: 0 3px;
}
body .pagination li.button .button-text,
body .pagination li.button .button-icon {
  display: inline-flex;
  align-items: center;
}
body .pagination li.prev {
  border-radius: 10px 5px 5px 10px;
}
body .pagination li.next {
  border-radius: 5px 10px 10px 5px;
}
body .pagination li.active, body .pagination li.button:hover, body .pagination li.numb:hover {
  background-color: #8c82ce;
}
body .tooltip {
  color: #ffffff;
  background-color: black;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 0.95em;
  max-width: 450px;
  margin: 0.5rem 2rem;
}
body .tooltip code {
  font-size: 1.1em;
  color: #ceb9ff;
  background-color: transparent !important;
}
body .tab-bar .slider-input {
  display: none;
}
body .tab-bar nav {
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--backgroundColor03);
  border-radius: 10px;
}
body .tab-bar nav label {
  display: inline-flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  color: var(--textColor01);
  font-size: 17px;
  border-radius: 10px;
  transition: 0.3s;
}
body .tab-bar nav label .button-text {
  margin-right: 1.5rem;
  z-index: 1;
}
body .tab-bar nav label .button-icon {
  display: inline-flex;
  padding: 12px 10px;
  font-size: 1.5rem;
  z-index: 1;
}
body .tab-bar nav label:hover {
  background-color: #7b70c7;
  color: #ffffff;
}
body .tab-bar nav .slider {
  z-index: 0;
  position: absolute;
  height: 100%;
  width: 11.1111111%;
  background-color: #8c82ce;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}
@media screen and (max-width: 1325px) {
  body .tab-bar nav label .button-text {
    margin-right: 0;
  }
  body .tab-bar nav label .button-icon {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  body .tab-bar nav label .button-text {
    display: none;
  }
  body .tab-bar nav label .button-icon {
    display: inline-flex;
  }
}
body .tab-bar #command-actions:checked ~ nav label.command-actions {
  color: #ffffff;
}
body .tab-bar #command-fun:checked ~ nav label.command-fun {
  color: #ffffff;
}
body .tab-bar #command-music:checked ~ nav label.command-music {
  color: #ffffff;
}
body .tab-bar #command-moderation:checked ~ nav label.command-moderation {
  color: #ffffff;
}
body .tab-bar #command-profile:checked ~ nav label.command-profile {
  color: #ffffff;
}
body .tab-bar #command-economy:checked ~ nav label.command-economy {
  color: #ffffff;
}
body .tab-bar #command-utility:checked ~ nav label.command-utility {
  color: #ffffff;
}
body .tab-bar #command-config:checked ~ nav label.command-config {
  color: #ffffff;
}
body .tab-bar #command-bot:checked ~ nav label.command-bot {
  color: #ffffff;
}
body .tab-bar #command-actions:checked ~ nav .slider {
  left: 0%;
}
body .tab-bar #command-fun:checked ~ nav .slider {
  left: 11.1111111%;
}
body .tab-bar #command-music:checked ~ nav .slider {
  left: 22.2222222%;
}
body .tab-bar #command-moderation:checked ~ nav .slider {
  left: 33.3333333%;
}
body .tab-bar #command-profile:checked ~ nav .slider {
  left: 44.4444444%;
}
body .tab-bar #command-economy:checked ~ nav .slider {
  left: 55.5555555%;
}
body .tab-bar #command-utility:checked ~ nav .slider {
  left: 66.6666666%;
}
body .tab-bar #command-config:checked ~ nav .slider {
  left: 77.7777777%;
}
body .tab-bar #command-bot:checked ~ nav .slider {
  left: 88.8888888%;
}
body .command-counter {
  font-family: "Bungee", sans-serif;
  font-size: 2rem;
  line-height: 55px;
  width: 120px;
  margin: 0 auto;
  background-color: var(--backgroundColor03);
  border-radius: 10px;
  text-align: center;
  color: #8c82ce;
}
body .tag {
  margin: 5px;
  display: inline-flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 15px;
  background-color: var(--backgroundColor01);
  cursor: default;
}
body .tag .name {
  margin-bottom: 2px;
  margin-left: 5px;
  font-size: 1.15rem;
}
body .tag .icon {
  display: inline-flex;
  font-size: 1.6rem;
}
body .tag .icon .yes {
  border-radius: 10px;
  display: none;
  background-color: #43b581;
  color: #ffffff;
}
body .tag .icon .no {
  border-radius: 10px;
  background-color: #f04747;
  color: #ffffff;
}
body .tag.active .icon .yes {
  display: block;
}
body .tag.active .icon .no {
  display: none;
}
body .dashboard-multi-add-list .list-bar {
  display: flex;
  justify-content: right;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--backgroundColor02);
}
body .dashboard-multi-add-list .list-bar.between {
  justify-content: space-between;
}
body .dashboard-multi-add-list .list {
  display: grid;
  gap: 10px;
  margin-top: 30px;
}
body .dashboard-multi-add-list .list .card {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
body .dashboard-multi-add-list .list .card span {
  text-overflow: ellipsis;
}
body .dashboard-multi-add-list .list .card .main {
  padding: 0.8rem 1rem;
  border-radius: 8px;
  background-color: var(--backgroundColor02);
}
@media screen and (max-width: 720px) {
  body .dashboard-multi-add-list .list .card .main {
    flex-direction: column;
    align-items: baseline;
  }
}
body .profile-menu {
  z-index: 101;
  position: fixed;
  top: 25px;
  right: 30px;
}
body .profile-menu .action {
  display: block;
  width: 58px;
  height: 58px;
  background-color: #171c26;
  border: 3px solid #171c26;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
}
body .profile-menu .action img {
  width: 100%;
  height: 100%;
}
body .profile-menu .action:hover {
  border-radius: 10px;
}
body .profile-menu .menu {
  width: 310px;
  padding: 20px;
  background-color: #171c26;
  border-radius: 10px;
  position: absolute;
  top: 75px;
  right: 0;
  transition: 0.3s;
  transform: translateY(15px);
  opacity: 0;
  visibility: hidden;
}
body .profile-menu .menu::before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #171c26;
  border-top-left-radius: 3px;
  position: absolute;
  top: -8px;
  right: 19px;
  transform: rotate(45deg);
}
body .profile-menu .menu .profile {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
body .profile-menu .menu .profile img {
  width: 56px;
  height: 56px;
  border-radius: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
body .profile-menu .menu .profile .info {
  margin-left: 15px;
  overflow: hidden;
}
body .profile-menu .menu .profile .info h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  font-family: "Archivo Black", sans-serif;
}
body .profile-menu .menu .profile .info p {
  color: rgba(140, 146, 224, 0.3);
  font-size: 14px;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
  font-weight: 100;
}
body .profile-menu .menu .divider {
  background-color: rgba(140, 146, 224, 0.3);
}
body .profile-menu .menu ul {
  margin-top: 30px;
}
body .profile-menu .menu ul li {
  list-style: none;
  padding: 2.5px 0;
}
body .profile-menu .menu ul li button {
  display: flex;
  width: 100%;
  align-items: center;
  color: #ffffff;
  gap: 10px;
  padding: 10px;
  font-size: 15px;
  border-radius: 8px;
  transition: 0.3s;
}
body .profile-menu .menu ul li button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
body .profile-menu .menu ul li button.active {
  color: rgb(159, 142, 255);
  pointer-events: none;
}
body .profile-menu .menu ul li button .icon {
  font-size: 1.3rem;
}
body .profile-menu .menu ul li button:hover {
  background-color: rgba(106, 93, 192, 0.5);
}
body .profile-menu .menu ul li button:hover.logout {
  background-color: rgba(240, 71, 71, 0.1);
}
body .profile-menu.active > .menu {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 720px) {
  body .profile-menu {
    top: 0px;
    right: 0px;
  }
}
body .bg-object-container .bg-object {
  position: absolute;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.4);
}
body .card-input {
  padding: 10px;
}
body .card-input .card-number,
body .card-input .card-expiry,
body .card-input .card-cvc {
  align-items: center;
  padding: 10px;
  background-color: #1a202b;
  border-radius: 4px;
}
body .card-input .card-number .icon,
body .card-input .card-expiry .icon,
body .card-input .card-cvc .icon {
  color: #fff;
  font-size: 1.4rem;
}
body .base-table {
  border-collapse: collapse;
  text-align: left;
  overflow: hidden;
  background-color: var(--backgroundColor02);
  color: var(--textColor01);
  width: 100%;
  border-radius: 8px;
}
body .base-table thead {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
body .base-table thead th {
  padding: 1rem 2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  font-family: "Bungee", sans-serif;
}
body .base-table tbody td {
  padding: 1rem 2rem;
}
body .base-table tbody tr:nth-child(even) {
  background-color: var(--tableBackgroundColor01);
}
body .base-table .status {
  border-radius: 4px;
  padding: 0.3rem 1rem;
  text-align: center;
  color: #ffffff;
}
body .base-table .status-success {
  background-color: rgba(67, 181, 129, 0.8);
}
body .base-table .status-danger {
  background-color: rgba(240, 71, 71, 0.8);
}
body .base-table .status-pending {
  background-color: rgba(250, 142, 49, 0.8);
}
body .base-table.premium-table {
  max-width: calc(100vw - 2rem);
}
body .base-table.premium-table tr {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 1fr 1fr;
}
body .base-table.premium-table thead tr :nth-child(2) .icon {
  display: none;
}
body .base-table.premium-table thead tr :nth-child(3) .icon {
  display: none;
}
@media screen and (max-width: 580px) {
  body .base-table.premium-table thead tr :nth-child(2) .icon {
    display: inline;
  }
  body .base-table.premium-table thead tr :nth-child(2) .value {
    display: none;
  }
  body .base-table.premium-table thead tr :nth-child(3) .icon {
    display: inline;
  }
  body .base-table.premium-table thead tr :nth-child(3) .value {
    display: none;
  }
}
@media screen and (max-width: 580px) {
  body .base-table.premium-table th,
  body .base-table.premium-table td {
    padding: 1rem 10px;
  }
}
body .base-table .premium-status {
  font-size: 1.5rem;
}
body .base-table .premium-status .icon {
  width: 100%;
}
body .base-table .premium-status-danger {
  color: #f04747;
}
body .base-table .premium-status-success {
  color: #43b581;
}
body .incrementor {
  display: flex;
  align-items: center;
  color: var(--textColor01);
}
body .incrementor.md button {
  width: 40px;
  height: 40px;
}
body .incrementor.md input {
  width: 60px;
  height: 40px;
}
body .incrementor input {
  width: 40px;
  height: 30px;
  outline: none;
  background-color: var(--backgroundColor05);
}
body .incrementor button {
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  background-color: var(--backgroundColor02);
  font-size: 1.2rem;
  cursor: pointer;
}
body .incrementor button.left {
  border-radius: 8px 0 0 8px;
}
body .incrementor button.right {
  border-radius: 0 8px 8px 0;
}
body .incrementor button.disabled {
  pointer-events: none;
  opacity: 0.5;
}
body .incrementor button .icon {
  pointer-events: none;
}
body .incrementor.bg-1 button {
  background-color: var(--backgroundColor01);
}
body .incrementor.bg-2 button {
  background-color: var(--backgroundColor02);
}
body .incrementor.bg-3 button {
  background-color: var(--backgroundColor03);
}
body .time-picker {
  display: inline-flex;
  gap: 5px;
}
body .time-picker .box {
  width: 30px;
  background-color: var(--backgroundColor05);
  border-radius: 7px;
}
body .time-picker .box .top,
body .time-picker .box .bottom {
  display: flex;
  justify-content: center;
  color: var(--textColor01);
  font-size: 20px;
  background-color: var(--backgroundColor05);
  border-radius: 7px;
  transition: 0.3s;
  cursor: pointer;
}
body .time-picker .box .top:hover {
  transform: translateY(-3px);
}
body .time-picker .box .bottom:hover {
  transform: translateY(3px);
}
body .time-picker .box .middle {
  display: flex;
  justify-content: center;
  color: var(--textColor01);
}
body .time-picker .box .middle .input {
  width: 30px;
  height: 25px;
  border-radius: 4px;
  text-align: center;
  outline: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
}
body .time-picker .box .middle .input::-moz-placeholder {
  color: var(--textColor01);
  opacity: 0.5;
}
body .time-picker .box .middle .input::placeholder {
  color: var(--textColor01);
  opacity: 0.5;
}
@media screen and (max-width: 900px) {
  body .profile-premium-card .card .title {
    flex-direction: column;
    gap: 10px;
  }
  body .profile-premium-card .card .title .text {
    text-align: center !important;
  }
}
body .profile-premium-card .details {
  background-color: var(--backgroundColor02);
}
body .server-leaderboard .server-icon {
  width: 180px;
  height: 180px;
  border-radius: 10px;
}
body .server-leaderboard .server-name {
  font-size: 45px;
}
body .server-leaderboard .leaderboard-list {
  display: grid;
  grid-auto-flow: row;
  gap: 0.5rem;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
  border-radius: 8px;
}
body .server-leaderboard .leaderboard-list .leaderboard-list-item {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  border-radius: 8px;
}
body .server-leaderboard .leaderboard-list .leaderboard-list-item .right-info,
body .server-leaderboard .leaderboard-list .leaderboard-list-item .left-info {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
}
body .server-leaderboard .leaderboard-list .leaderboard-list-item .leaderboard-position,
body .server-leaderboard .leaderboard-list .leaderboard-list-item .leaderboard-level,
body .server-leaderboard .leaderboard-list .leaderboard-list-item .leaderboard-avatar {
  display: grid;
  place-items: center;
  width: 50px !important;
  height: 50px !important;
  border-radius: 8px;
}
body .server-leaderboard .leaderboard-list .leaderboard-list-item .leaderboard-name,
body .server-leaderboard .leaderboard-list .leaderboard-list-item .leaderboard-xp {
  font-size: 20px;
}
body .server-leaderboard .leaderboard-list .leaderboard-list-item .leaderboard-name.manager,
body .server-leaderboard .leaderboard-list .leaderboard-list-item .leaderboard-xp.manager {
  font-size: 15px;
}
@media screen and (max-width: 760px) {
  body .server-leaderboard .server-name {
    font-size: 35px;
  }
  body .server-leaderboard .leaderboard-list {
    width: auto;
  }
  body .server-leaderboard .leaderboard-name {
    display: none;
  }
  body .server-leaderboard.manager .leaderboard-name {
    display: block;
  }
}
@keyframes fade_out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body .embed-preview {
  background: #36393e;
  display: flex;
  box-orient: vertical;
  flex-direction: column;
}
body .embed-wrapper {
  display: flex;
  max-width: 520px;
}
body .embed-side-colored {
  width: 4px;
  border-radius: 3px 0 0 3px;
}
body .embed {
  border-radius: 0 3px 3px 0;
  background: rgba(46, 48, 54, 0.3);
  border-color: rgba(46, 48, 54, 0.6);
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
body .embed .card-block {
  padding: 0;
  display: flex;
  margin-bottom: 10px;
}
body .embed a {
  color: #0096cf;
}
body .embed img.embed-thumb {
  max-height: 80px;
  max-width: 80px;
  border-radius: 3px;
  flex-shrink: 0;
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  margin-left: 20px;
}
body .embed .embed-footer {
  font-size: 12px;
}
body .embed .embed-footer span {
  color: rgba(255, 255, 255, 0.6);
}
body .embed .embed-inner .embed-title {
  color: #fff;
}
body .embed .embed-inner .embed-author {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
body .embed .embed-inner img.embed-author-icon {
  margin-right: 9px;
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
body .embed .embed-inner .embed-author-name {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #fff !important;
}
body .embed .fields {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-lines: miltiple;
  margin-top: -10px;
}
body .embed .fields .field {
  flex: 0;
  box-flex: 1;
  padding-top: 10px;
  max-width: 506px;
  min-width: 100%;
}
body .embed .fields.inline {
  box-flex: 1;
  flex: 1;
  min-width: 150px;
  flex-basis: auto;
}
body .embed .fields .field-name {
  color: #fff;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 600;
}
body .embed .fields .field-value {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1em;
  white-space: pre-wrap;
  margin-top: 6px;
  word-wrap: break-word;
}
body .discord-message {
  padding: 0.125rem 16px 0.15rem 4.5rem;
  min-height: 3rem;
  font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 4px;
}
body .discord-message:hover {
  background-color: var(--discordBackgroundHover);
}
body .discord-message .header-wrapper {
  position: relative;
  margin-left: -4.5rem;
  padding-left: 4.5rem;
}
body .discord-message .header-wrapper img.avatar {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  left: 0px;
  top: 0.125rem;
  margin: 0px 1rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
body .discord-message .header-wrapper img.avatar:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 4px;
}
body .discord-message .header-wrapper .username {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  color: var(--discordEmbedTitle);
  display: inline;
  vertical-align: baseline;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
body .discord-message .header-wrapper .username:hover {
  text-decoration: underline;
}
body .discord-message .header-wrapper .bot-tag {
  height: 1rem;
  padding: 0 0.275rem;
  margin-top: 0.075em;
  border-radius: 0.1875rem;
  margin-left: 0.25rem;
  position: relative;
  top: 0.22rem;
  background: rgb(88, 101, 242);
  color: white;
  font-size: 0.625rem;
  text-transform: uppercase;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  text-indent: 0;
  outline: 0;
}
body .discord-message .header-wrapper .bot-tag .bot-tag-verified {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: -0.25rem;
  display: inline-block;
  overflow: hidden;
}
body .discord-message .header-wrapper .bot-tag .bot-text {
  line-height: 0.9375rem;
  position: relative;
  font-weight: 500;
  vertical-align: top;
}
body .discord-message .header-wrapper .time {
  color: rgb(129, 135, 145);
  font-size: 0.75rem;
  line-height: 1.375rem;
  vertical-align: baseline;
  margin-left: 0.25rem;
  display: inline-block;
  height: 1.25rem;
  cursor: default;
  pointer-events: none;
  font-weight: 500;
}
body .discord-message .message-content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 1.375;
  color: var(--discordEmbedDescription);
}
body .discord-message .components-wrapper {
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-self: start;
  justify-self: start;
  flex-direction: column;
}
body .discord-message .components-wrapper .components {
  display: flex;
  flex-wrap: wrap;
}
body .discord-message .img-attachment {
  display: block;
  max-width: 400px;
  max-height: 300px;
  border-radius: 3px;
  cursor: pointer;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
body .discord-message .lock-link {
  cursor: pointer;
  color: var(--discordEmbedLink);
}
body .discord-message .lock-link:hover {
  text-decoration: underline;
}
body .discord-embed {
  display: grid;
  unicode-bidi: plaintext;
  text-align: left;
  background: var(--discordEmbedBackground);
  border-radius: 4px;
  border-left: 4px solid var(--discordEmbedSidebarBackground);
  font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 0.25rem;
}
body .discord-embed a:hover {
  text-decoration: underline;
}
body .discord-embed .embed-wrapper {
  padding: 0.5rem 1rem 1rem 0.75rem;
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}
body .discord-embed .discord-markdown.inline-code, body .discord-embed .discord-markdown.code-block {
  background: var(--discordEmbedSidebarBackground);
}
body .discord-embed .author {
  min-width: 0px;
  display: flex;
  align-items: center;
  grid-column: 1/2;
  margin: 8px 0px 0px;
}
body .discord-embed .author img.icon {
  height: 24px;
  width: 24px;
  margin: 0px 8px 0px 0px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
body .discord-embed .author .name {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--discordEmbedTitle);
  white-space: pre-wrap;
  display: inline-block;
}
body .discord-embed a.title-wrapper {
  color: var(--discordEmbedLink);
}
body .discord-embed .title-wrapper {
  min-width: 0px;
  display: inline-block;
  margin: 8px 0px 0px;
  grid-column: 1/2;
  color: var(--discordEmbedTitle);
}
body .discord-embed .title-wrapper .title {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 1.375;
  font-size: 1rem;
  font-weight: 600 !important;
}
body .discord-embed .description-wrapper {
  min-width: 0px;
  margin: 8px 0px 0px;
  grid-column: 1/2;
}
body .discord-embed .description-wrapper .description {
  overflow-wrap: break-word;
  font-size: 0.875rem;
  color: var(--discordEmbedDescription);
  line-height: 1.125rem;
  white-space: pre-line;
}
body .discord-embed .fields {
  min-width: 0px;
  margin: 8px 0px 0px;
  display: grid;
  grid-column: 1/2;
  gap: 8px;
}
body .discord-embed .fields .field {
  min-width: 0px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  grid-column: 1/13;
}
body .discord-embed .fields .field.inline-01 {
  grid-column: 1/7;
}
body .discord-embed .fields .field.inline-02 {
  grid-column: 1/5;
}
body .discord-embed .fields .field .name-wrapper {
  min-width: 0px;
  margin: 0px 0px 1px;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--discordEmbedTitle);
}
body .discord-embed .fields .field .name-wrapper .name {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 1.375;
}
body .discord-embed .fields .field .value-wrapper {
  min-width: 0px;
}
body .discord-embed .fields .field .value-wrapper .value {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--discordEmbedDescription);
  white-space: pre-line;
}
body .discord-embed img.image {
  min-width: 0px;
  max-width: 400px;
  max-height: 300px;
  margin: 16px 0px 0px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}
body .discord-embed .footer-wrapper {
  min-width: 0px;
  margin: 8px 0px 0px;
  display: flex;
  align-items: center;
  grid-area: auto/1/auto/3;
}
body .discord-embed .footer-wrapper img.icon {
  height: 20px;
  width: 20px;
  margin: 0px 8px 0px 0px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
body .discord-embed .footer-wrapper .text {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--discordEmbedDescription);
  line-height: 1rem;
  white-space: break-spaces;
}
body .discord-embed .footer-wrapper .text .divider {
  display: inline-block;
  margin: 0px 4px;
}
body .discord-embed .thumbnail-wrapper {
  margin: 8px 0px 0px 16px;
  grid-area: 1/2/8/3;
  justify-self: end;
  cursor: pointer;
}
body .discord-embed .thumbnail-wrapper img.thumbnail {
  max-width: 80px;
  max-height: 80px;
  border-radius: 4px;
}
body .discord-button-component {
  font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: none;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 2px 16px;
  cursor: pointer;
  margin: 4px 8px 4px 0;
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  min-width: 60px;
  min-height: 32px;
  color: white;
  transition: 0.3s;
}
body .discord-button-component.for-color {
  min-width: 32px !important;
  width: 32px !important;
}
body .discord-button-component.primary {
  background-color: var(--discordButtonPrimary);
}
body .discord-button-component.primary:hover {
  background-color: var(--discordButtonPrimaryHover);
}
body .discord-button-component.link, body .discord-button-component.secondary {
  background-color: var(--discordButtonSecondary);
}
body .discord-button-component.link:hover, body .discord-button-component.secondary:hover {
  background-color: var(--discordButtonSecondaryHover);
}
body .discord-button-component.success {
  background-color: var(--discordButtonSuccess);
}
body .discord-button-component.success:hover {
  background-color: var(--discordButtonSuccessHover);
}
body .discord-button-component.danger {
  background-color: var(--discordButtonDanger);
}
body .discord-button-component.danger:hover {
  background-color: var(--discordButtonDangerHover);
}
body .discord-button-component .contents {
  margin: 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-width: 32px;
}
body .discord-button-component .contents .emoji img {
  text-indent: -9999px;
  flex-shrink: 0;
  margin-right: 4px;
  margin-bottom: 1.5px;
  -o-object-fit: contain;
     object-fit: contain;
  width: 1.375em !important;
  height: 1.375em !important;
  vertical-align: bottom;
}
body .discord-button-component .contents .label {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
}
body .discord-button-component .contents .link-icon {
  flex-shrink: 0;
  width: 16;
  height: 16;
  overflow: hidden;
  margin-left: 8px;
}
body .discord-select-menu-component {
  font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
body .discord-select-menu-component::-webkit-scrollbar {
  width: 2px !important;
}
body .discord-select-menu-component .component-option .option {
  display: flex !important;
  align-items: center !important;
  overflow: hidden !important;
}
body .discord-select-menu-component .component-option .option .label-container {
  line-height: 18px !important;
  overflow: hidden !important;
}
body .discord-select-menu-component .component-option .option .label-container .label {
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  margin-bottom: 4px !important;
}
body .dnd-trash {
  position: absolute;
  right: -25%;
  top: 50px;
  z-index: 100;
  display: grid;
  place-items: center;
  background-color: #f04747;
  padding: 0.5rem 2rem;
  border-radius: 8px 0 0 8px;
  transition: 0.1s;
}
body .dnd-trash.active {
  right: 0;
}
body .discord-markdown.mention {
  cursor: pointer;
  color: var(--discordMentionText);
  font-weight: 500;
  border-radius: 3px;
  padding: 0px 2px;
  background: var(--discordMentionBackground);
  transition: background-color 50ms ease-out 0s, color;
}
body .discord-markdown.mention:hover {
  background: var(--discordMentionBackgroundHover);
  color: var(--discordMentionTextHover);
}
body .discord-markdown.inline-code {
  font-family: "Courier New", monospace;
  padding: 0.2em;
  margin: -0.2em 0px;
  border-radius: 3px;
  background: var(--discordEmbedBackground);
  font-size: 0.85em;
  line-height: 1.125rem;
  white-space: pre-wrap;
}
body .discord-markdown.code-block {
  font-family: "Courier New", monospace;
  max-width: 90%;
  margin: 6px 0px 0px;
  padding: 0.5em;
  background: var(--discordEmbedBackground);
  border: 1px solid var(--discordEmbedSidebarBackground);
  border-radius: 4px;
  color: var(--discordTextCode);
  font-size: 0.875rem;
  line-height: 1.125rem;
  white-space: pre-wrap;
}
body .discord-markdown.blockquote-bar {
  min-width: 4px;
  max-width: 4px;
  border-radius: 4px;
  background: var(--discordBlockquoteBar);
}
body .discord-markdown.blockquote {
  max-width: 90%;
  padding: 0px 8px 0px 12px;
  margin: 0px;
  text-indent: 0px;
}
body .discord-markdown.hypertext {
  color: var(--discordEmbedLink);
  text-decoration: none;
  font-weight: 400;
}
body .discord-markdown.hypertext:hover {
  text-decoration: underline;
}
body blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}
body .cyclicquestions-question {
  position: relative;
}
body .cyclicquestions-question:hover .buttons {
  display: flex;
}
body .cyclicquestions-question .buttons {
  display: none;
  position: absolute;
  top: -10px;
  right: 10px;
  gap: 10px;
}
body .cyclicquestions-question .buttons .delete,
body .cyclicquestions-question .buttons .edit {
  color: white;
  padding: 2px;
  font-size: 22px;
  border-radius: 4px;
  cursor: pointer;
}
body .cyclicquestions-question .buttons .edit {
  background-color: #5865f2;
}
body .cyclicquestions-question .buttons .edit:hover {
  background-color: #404ff0;
}
body .cyclicquestions-question .buttons .delete {
  background-color: #f04747;
}
body .cyclicquestions-question .buttons .delete:hover {
  background-color: #d91212;
}/*# sourceMappingURL=index.style.css.map */