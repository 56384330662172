@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./exports/external.style";
@import "./exports/variable.style";
@import "./fonts/style.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 2px !important;
        background-color: $backgroundColor01;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $colorPrimary;
        border-radius: 8px;
    }
}

html {
    height: 100%;
}

body {
    overflow-y: auto;

    width: 100vw;
    min-height: 100vh;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--backgroundColor01) !important;
    transition: $transition03;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;

    &.lock-scroll {
        overflow-y: hidden;
    }

    #root {
        z-index: 1;
        // overflow-x: hidden !important;
    }
    #portal {
        z-index: 2;
        overflow-x: hidden !important;
    }
    #loading-root {
        position: fixed;
        display: grid;
        place-items: center;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
        transition: $transition02;

        img {
            height: 150px;
            width: 150px;
        }
        overflow-x: hidden !important;
    }
    &.load {
        overflow: hidden;
        #root {
            pointer-events: none !important;
        }
        #portal {
            pointer-events: none !important;
        }
        #loading-root {
            pointer-events: none !important;
            opacity: 1;
            z-index: 999;
            img {
                visibility: visible !important;
            }
        }
    }

    @import "./exports/utility.style";

    @import "./exports/text.style";
    @import "./exports/button.style";
    @import "./exports/form.style";
    @import "./exports/card.style";
    @import "./exports/component.style";

    overflow-x: hidden !important;
}
